import Currency from 'components/Currency/Currency';
import { PermissionService } from 'contexts/UserContext';
import tooSmallImage from 'images/screen-size-too-small.svg';
import { IEngagementSavingsSummaryDetails, Permission } from 'interfaces';
import { formatDate } from 'utils/formatDate';
import { numberOf } from 'utils/numberOf';

import classnames from 'classnames';
import { isSameDay } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  Tooltip
} from '@ryan/components';

import { TSavingsSummary } from '../../../components/SavingsSummary/SavingsSummaryButton/utils';

const SavingsSummaryDetail: React.FC<{
  isAppReadOnly?: boolean;
  permissionService: PermissionService;
  savingsSummary: TSavingsSummary;
  savingsSummaryDetails: IEngagementSavingsSummaryDetails | null;
  onCreateEntry: () => void;
  onEditLastEntry: () => void;
  onViewHistory: () => void;
}> = ({
  isAppReadOnly = false,
  permissionService: ps,
  savingsSummary,
  savingsSummaryDetails,
  onCreateEntry,
  onEditLastEntry,
  onViewHistory
}) => {
  const { t } = useTranslation();
  const canEdit =
    ps.isRyan() &&
    ps.hasPermission(Permission.SavingsSummaryEdit) &&
    !savingsSummary.isGhosted &&
    !savingsSummary.isUserGhosted;
  const hasEntries = savingsSummary.lastSavingsSummaryAsOfDate !== null;
  const isLastEntryToday =
    savingsSummary.lastSavingsSummaryAsOfDate !== null &&
    isSameDay(savingsSummary.lastSavingsSummaryAsOfDate, new Date());
  const isReadOnly = savingsSummary.isReadOnly || isAppReadOnly;

  // if engagement has NO savings entries
  if (!hasEntries) {
    return (
      <div className="savings-summary__no-entries">
        <h2 className="ry-h2">{savingsSummary.engagementDisplayNameShort}</h2>

        {/* if Ryan user, show this when < 1024 */}
        <p className={classnames({ 'd-lg-none': canEdit })}>
          {t('savings.overview.noSavings')}
        </p>

        {canEdit && (
          <>
            {/* show this when < 1024 */}
            <div className="savings-summary__no-entries-small d-lg-none">
              <img
                alt=""
                aria-hidden
                className="savings-summary__no-entries-small-image"
                role="presentation"
                src={tooSmallImage}
              />
              <div className="savings-summary__no-entries-small-content">
                <div className="savings-summary__no-entries-small-label">
                  {t('savings.overview.tooSmall.title')}
                </div>
                <p>{t('savings.overview.tooSmall.content')}</p>
              </div>
            </div>

            {/* show this when >= 1024 */}
            <div className="d-none d-lg-block">
              <p>{t('savings.overview.createSavings')}</p>

              <Tooltip
                content={t('savings.overview.createSavingsDisabledTooltip')}
                hidden={savingsSummary.isoCurrencyCode != null}
                placement="right"
                renderTarget={({ open, ref, ...props }) => (
                  <div
                    aria-expanded={open}
                    aria-haspopup="true"
                    ref={ref}
                    style={{ width: 'fit-content' }}
                    {...props}
                  >
                    <Button
                      disabled={
                        (isReadOnly ||
                          savingsSummary.isoCurrencyCode == null ||
                          !savingsSummary.isActive) &&
                        !(savingsSummary.isoCurrencyCode != null && ps.isRyan())
                      }
                      icon="plus"
                      onClick={onCreateEntry}
                      // HACK: Observed issue where Tooltip content would appear on initial hover and remain (See: Milestone.tsx for more details)
                      style={{
                        ...((isReadOnly ||
                          savingsSummary.isoCurrencyCode == null) && {
                          pointerEvents: 'none'
                        })
                      }}
                      text={t('Create')}
                      variant={EButtonVariant.PRIMARY}
                    />
                  </div>
                )}
              />
            </div>
          </>
        )}
      </div>
    );
  }

  // if engagement has savings entries
  return (
    <div className="savings-summary__details">
      {/* Header */}
      <div className="savings-summary__details-header">
        <div>
          <h2 className="ry-h2">{savingsSummary.engagementDisplayNameShort}</h2>
          {savingsSummary.lastSavingsSummaryAsOfDate
            ? t('savings.overview.totalSavingsAsOf', {
                date: formatDate(savingsSummary.lastSavingsSummaryAsOfDate)
              })
            : t('savings.totalSavings')}
        </div>
        <div>
          <div className="well">
            <ul className="labeled-list">
              <li>
                <label>{t('savings.overview.lastUpdatedBy')}</label>
                {savingsSummaryDetails === null ? (
                  <div className="ry-skeleton" />
                ) : ps.isUser(savingsSummaryDetails.updatedBy) ? (
                  t('You')
                ) : (
                  savingsSummaryDetails.updatedByName
                )}
              </li>
              <li>
                <label>{t('savings.overview.updatedOn')}</label>
                {savingsSummaryDetails === null ? (
                  <div className="ry-skeleton" />
                ) : (
                  savingsSummaryDetails.updateDate &&
                  formatDate(savingsSummaryDetails.updateDate)
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Notes */}
      {savingsSummaryDetails && savingsSummaryDetails.lastComments && (
        <div className="savings-summary__details-notes">
          <div className="savings-summary__details-notes-header">
            {t('savings.updateNotes')}
          </div>
          <p>{savingsSummaryDetails.lastComments}</p>
        </div>
      )}

      {/* Table, Actions */}
      <div className="savings-summary__details-table-wrapper">
        <table className="savings-summary__details-table">
          <thead>
            <tr>
              <th />
              <th>{t('savings.potential')}</th>
              <th>{t('savings.submitted')}</th>
              <th>{t('savings.approved')}</th>
              <th>{t('savings.received')}</th>
              <th>{t('Total')}</th>
            </tr>
          </thead>
          <tbody>
            {/* Skeleton */}
            {savingsSummaryDetails === null &&
              numberOf(3, i => (
                <tr key={i}>
                  {numberOf(6, i => (
                    <td key={i}>
                      <div className="ry-skeleton" />
                    </td>
                  ))}
                </tr>
              ))}

            {/* Categories */}
            {savingsSummaryDetails &&
              savingsSummaryDetails.lastSavingsSummaryCategories &&
              savingsSummaryDetails.lastSavingsSummaryCategories.map(
                (category, i) => (
                  <tr key={category.savingsSummaryEntryCategoryGuid}>
                    <td>{category.name}</td>
                    {[
                      category.potential,
                      category.submitted,
                      category.approved,
                      category.received,
                      category.summary
                    ].map((value, j) => (
                      <td key={j}>
                        <Currency
                          currencyCode={savingsSummary.isoCurrencyCode}
                          includeSymbol={i === 0}
                          value={value}
                        />
                      </td>
                    ))}
                  </tr>
                )
              )}

            {/* Totals */}
            {savingsSummaryDetails &&
              savingsSummaryDetails.lastSavingsSummarySavingsTotals && (
                <tr className="savings-summary__details-totals">
                  <td>{t('savings.totalSavings')}</td>
                  {[
                    savingsSummaryDetails.lastSavingsSummarySavingsTotals
                      .potential,
                    savingsSummaryDetails.lastSavingsSummarySavingsTotals
                      .submitted,
                    savingsSummaryDetails.lastSavingsSummarySavingsTotals
                      .approved,
                    savingsSummaryDetails.lastSavingsSummarySavingsTotals
                      .received,
                    savingsSummaryDetails.lastSavingsSummarySavingsTotals
                      .summary
                  ].map((value, i) => (
                    <td key={i}>
                      <Currency
                        currencyCode={savingsSummary.isoCurrencyCode}
                        value={value}
                      />
                    </td>
                  ))}
                </tr>
              )}
          </tbody>
        </table>

        <div className="savings-summary__details-actions">
          <Button
            icon="time"
            onClick={onViewHistory}
            size={EButtonSizes.SMALL}
            text={t('savings.savingsHistory')}
            variant={EButtonVariant.TEXT}
          />
          {canEdit && (
            <>
              <div className="savings-summary__details-actions-spacer" />
              <Button
                className="d-none d-lg-block"
                disabled={isLastEntryToday || isReadOnly}
                icon="plus"
                onClick={onCreateEntry}
                size={EButtonSizes.SMALL}
                text={t('savings.updateSavings')}
                variant={EButtonVariant.TEXT}
              />
              <Button
                className="d-none d-lg-block"
                disabled={
                  !hasEntries ||
                  isReadOnly ||
                  !savingsSummary.lastSavingsSummaryIsEditable
                }
                icon="pencil"
                onClick={onEditLastEntry}
                size={EButtonSizes.SMALL}
                text={t('savings.editLastEntry')}
                variant={EButtonVariant.TEXT}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SavingsSummaryDetail;
