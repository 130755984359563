import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup, EButtonVariant } from '@ryan/components';

import Modal from '../Modal';
import * as InformationModalInterfaces from './InformationModal.interfaces';

const InformationModal = (
  props: InformationModalInterfaces.TComponentProps
) => {
  const { t: getTextToDisplay } = useTranslation();

  return (
    <Modal
      className="information-modal"
      onClose={props.onClose}
      open
      title={getTextToDisplay('informationModal.title', {
        context: props.context
      })}
    >
      <p>
        {getTextToDisplay('informationModal.content', {
          context: props.context
        })}
      </p>

      <ButtonGroup>
        <Button onClick={props.onClose} variant={EButtonVariant.PRIMARY}>
          {getTextToDisplay('Close')}
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

export default InformationModal;
