import SearchInput from 'components/SearchInput';
import { WithUser, withUser } from 'contexts/UserContext';

import { TFunction } from 'i18next';
import React, { FunctionComponent } from 'react';

import {
  BreadcrumbButton,
  Breadcrumbs,
  Button,
  EButtonSizes,
  EButtonVariant,
  Icon
} from '@ryan/components';

import {
  ICustomViewSummary,
  IEngagement,
  IFolder,
  Permission,
  UserType
} from '../../../interfaces';

interface IFileDirectoryHeaderProps extends WithUser {
  engagement: IEngagement | null;
  folderPath: IFolder[];
  isTransferredFilesView: boolean;
  loading: boolean;
  onDataRequests: boolean;
  onFolderCreate: () => void;
  onNavigateToAccount: () => void;
  onNavigateToFolder: (folderGuid: string) => void;
  onNavigateToRoot: () => void;
  onSearch?: (searchQuery: string) => void;
  onUploadFile?: () => void;
  renderAction?: () => React.ReactNode;
  searchQuery?: string;
  shouldHideBreadcrumbs?: boolean;
  t: TFunction;
  title: string;
  view: ICustomViewSummary | null;
}

export const FileDirectoryHeader: FunctionComponent<
  IFileDirectoryHeaderProps
> = ({
  engagement,
  folderPath,
  isAppReadOnly,
  isTransferredFilesView,
  loading,
  onDataRequests,
  onFolderCreate,
  onNavigateToAccount,
  onNavigateToFolder,
  onNavigateToRoot,
  onSearch,
  onUploadFile,
  permissionService: ps,
  renderAction,
  searchQuery,
  shouldHideBreadcrumbs,
  t,
  title,
  user,
  view
}) => {
  const disabled = !engagement || engagement.isReadOnly || isAppReadOnly;

  const isCurrentFolderArchived =
    !!folderPath[folderPath.length - 1]?.archiveDate || false;
  const isThirdParty = (user.profile.userTypeId & UserType.ThirdParty) > 0;

  return (
    <>
      <div className="file-directory__header">
        <h2 className="ry-h2">{title}</h2>
        {engagement && renderAction && renderAction()}
        <div className="ry-table__header-spacer" />

        {engagement && onUploadFile && (
          <Button
            disabled={
              disabled ||
              isCurrentFolderArchived ||
              (!onDataRequests &&
                !ps.hasPermission(Permission.FilesContribute)) ||
              (onDataRequests &&
                !ps.hasPermission(Permission.DataRequestsContribute))
            }
            icon="upload"
            onClick={onUploadFile}
            size={EButtonSizes.SMALL}
            text={t('Upload Files')}
            variant={EButtonVariant.TEXT}
          />
        )}

        {engagement && !isTransferredFilesView && !isThirdParty && (
          <Button
            disabled={
              disabled ||
              isCurrentFolderArchived ||
              !ps.hasPermission(Permission.FilesContribute)
            }
            icon="folder-add"
            onClick={onFolderCreate}
            size={EButtonSizes.SMALL}
            text={t('New Folder')}
            variant={EButtonVariant.TEXT}
          />
        )}

        {searchQuery !== undefined && onSearch !== undefined && (
          <div className="ry-table__header-search">
            <SearchInput
              loading={loading}
              onChange={onSearch}
              placeholder={engagement ? t('Search all files') : t('Search')}
              value={searchQuery}
            />
          </div>
        )}
      </div>

      {!shouldHideBreadcrumbs && (
        <div className="file-directory__path">
          <Breadcrumbs>
            {view === null ? (
              <span className="ry-skeleton" />
            ) : (
              <BreadcrumbButton
                isActive={engagement === null}
                onClick={onNavigateToAccount}
              >
                <Icon name="folder-open" />
                {view.name}
              </BreadcrumbButton>
            )}

            {engagement && (
              <BreadcrumbButton
                isActive={folderPath.length === 0}
                onClick={onNavigateToRoot}
              >
                {engagement.engagementDisplayNameShort}
              </BreadcrumbButton>
            )}

            {folderPath.map((folder, i) => (
              <BreadcrumbButton
                isActive={i === folderPath.length - 1}
                key={folder.folderGuid}
                onClick={() => onNavigateToFolder(folder.folderGuid)}
              >
                {folder.folderName}
              </BreadcrumbButton>
            ))}
          </Breadcrumbs>
        </div>
      )}
    </>
  );
};

export default withUser(FileDirectoryHeader);
