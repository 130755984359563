import { FormikProps, WithFormikConfig, withFormik } from 'formik';
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  TFunction,
  Trans,
  WithTranslation,
  withTranslation
} from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  EMessageTypes,
  IPushToastProps,
  MentionsValue,
  Message,
  Textarea,
  pushToast
} from '@ryan/components';

import {
  EngagementAutocomplete,
  UserAutocomplete
} from '../../components/AutocompleteAjax';
import CommentInput from '../../components/Comments/CommentInput';
import DSSFileUpload from '../../components/DSSFileUpload/DSSFileUpload';
import SelectFolder from '../../components/SelectFolder/SelectFolder';
import { useEngagement } from '../../contexts/EngagementContext';
import { WithUser, useUser, withUser } from '../../contexts/UserContext';
import {
  Feature,
  FolderSelection,
  IEngagement,
  IFolderTree,
  IUserSummary,
  Permission,
  UserType
} from '../../interfaces';
import { IFileUpload } from '../../interfaces/IFileUpload';
import { IFileUploadLinkable } from '../../interfaces/IFileUploadLinkable';
import ApiService from '../../services/ApiService';
import { DSSManager } from '../../utils/DSS';
import { ApiErrorResponseEnums } from '../../utils/enums/ApiErrorResponseEnums';
import {
  formikAutocompleteAjaxProps,
  formikCommentProps,
  formikFieldProps,
  yup
} from '../../utils/forms';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import { RYAN_INTERNAL } from '../FileDirectory/utils/FileDirectoryEnums';
import Collapse from './Collapse';
import FilterToggleButton from './FilterToggleButton';
import Select, { SelectOption } from './Select';

const maxLengthComment = 250;
const maxLengthDescription = 250;

export enum FileUploadType {
  AdHocWithoutLink = '1',
  AdHocLinkToDataRequest = '2',
  AdHocLinkToTask = '3'
}

export enum LinkToFilterType {
  None = 'NO',
  Task = 'T',
  DataRequest = 'DR'
}

/*
 * Interfaces
 */

export interface FileUploadFormProps extends WithTranslation {
  customViewGuid?: string;
  defaultFolder: FolderSelection;
  setLinkToFilter: React.Dispatch<React.SetStateAction<LinkToFilterType>>;
  linkToFilter: LinkToFilterType;
  dss: DSSManager;
  engagementGuid?: string;
  isRyanUser: boolean;
  onCancel: () => void;
  onClose: () => void;
  onDefaultFolderChange?: (folder: FolderSelection) => void;
}

interface FileRequestFormValues {
  comment: MentionsValue | null;
  description: string;
  defaultFolder: FolderSelection;
  engagement: IEngagement | null;
  linkTo: string | null;
  linkToType: string;
  onBehalfOf: IUserSummary | null;
}

interface IFileUploadModified extends IFileUpload {
  onBehalfOfUserFullName: string | null;
}

const defaultFormValues: FileRequestFormValues = {
  comment: null,
  description: '',
  defaultFolder: null,
  engagement: null,
  linkTo: null,
  linkToType: FileUploadType.AdHocLinkToDataRequest,
  onBehalfOf: null
};

interface FileRequestInnerFormProps
  extends FileUploadFormProps,
    WithUser,
    FormikProps<FileRequestFormValues> {}

const FileRequestForm: FunctionComponent<FileRequestInnerFormProps> = ({
  t,
  customViewGuid,
  defaultFolder,
  linkToFilter,
  setLinkToFilter,
  dss,
  engagementGuid,
  isFeatureToggled,
  isRyanUser,
  onClose,
  onCancel,
  onDefaultFolderChange,
  ...formik
}) => {
  const { refreshUpdateDate } = useEngagement();
  const formRef = useRef<HTMLFormElement | null>(null);
  // State
  const [createNewFolderLoading, setCreateNewFolderLoading] =
    useState<Promise<any> | null>(null);
  const [dataRequestsTasks, setDataRequestsTasks] = useState<
    IFileUploadLinkable[]
  >([]);
  const [detailsComplete, setDetailsComplete] = useState(false);
  const [detailsCollapsed, setDetailsCollapsed] = useState(false);
  const [detailsError, setDetailsError] = useState<string | undefined>();
  const [fileSelectError, setFileSelectError] = useState<string | undefined>();
  const [fileSelectCollapsed, setFileSelectCollapsed] = useState(true);
  const [fileUploadComplete, setFileUploadComplete] = useState(false);
  const [folders, setFolders] = useState<IFolderTree[]>([]);
  const [isLinkingToDataRequest, setIsLinkingToDataRequest] = useState(false);
  const [onBehalfOfCollapsed, setOnBehalfOfCollapsed] = useState(true);
  const [onBehalfOfComplete, setOnBehalfOfComplete] = useState(false);
  const [selectFolderDisabled, setSelectFolderDisabled] = useState(false);
  const [selectFolderError, setSelectFolderError] = useState<string | null>(
    null
  );
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    null
  );
  const [uploadFilesLoading, setUploadFilesLoading] =
    useState<Promise<any> | null>(null);

  const { permissionService, user } = useUser();

  // Computed

  const hasDataRequests =
    dataRequestsTasks.filter(item => item.type === LinkToFilterType.DataRequest)
      .length !== 0;
  const hasTasks =
    dataRequestsTasks.filter(item => item.type === LinkToFilterType.Task)
      .length !== 0;

  // Methods

  interface DropdownOption {
    folderName: string | null;
    defaultFolderGuid: string | null;
    value: string;
    label: string;
    type: string;
    createdByName: string;
  }

  const buildDropdownOptions = useCallback(
    (type: LinkToFilterType): DropdownOption[] => {
      const dropdownOptions: DropdownOption[] = [];
      const filtered = dataRequestsTasks.filter(
        element => element.type === type
      );
      filtered.forEach((value, index) => {
        const {
          defaultFolderGuid,
          folderName,
          queueItemGuid,
          title,
          createdByName,
          type
        } = value;
        const toBePushed = {
          defaultFolderGuid: defaultFolderGuid,
          folderName: folderName,
          createdByName: createdByName,
          value: queueItemGuid,
          label: `${title}`,
          type: type
        };
        dropdownOptions.push(toBePushed);
      });
      return dropdownOptions;
    },
    [dataRequestsTasks]
  );

  useEffect(() => {
    const fetchFolderFor = async (engagementGuid: string) => {
      setFolders([]);
      const { data: folders } = await ApiService.getEngagementFolders(
        engagementGuid
      );
      setFolders(folders);
      return folders;
    };

    const fetchEngagementDataFor = async (engagementGuid: string) => {
      const { data } = await ApiService.getEngagement(engagementGuid);
      formik.setFieldValue('engagement', data);
    };

    const fetchDataRequestsAndTasksFor = async (engagementGuid: string) => {
      const { data } = await ApiService.getDataRequestsAndTasks(engagementGuid);
      setDataRequestsTasks(data);
    };

    const init = (engagementGuid: string) => {
      fetchFolderFor(engagementGuid);
      fetchEngagementDataFor(engagementGuid);
      fetchDataRequestsAndTasksFor(engagementGuid);
    };

    engagementGuid && init(engagementGuid);

    // disabling to prevent warnings requiring formik as a dependency (would lead to side-effect loop)
    // eslint-disable-next-line
  }, [engagementGuid]);

  useEffect(() => {
    if (dataRequestsTasks.length > 0) {
      buildDropdownOptions(linkToFilter);
    }
  }, [dataRequestsTasks, buildDropdownOptions, linkToFilter]);

  useEffect(() => {
    setDetailsComplete(false);
    setDetailsError(undefined);
  }, [linkToFilter]);

  useEffect(() => {
    if (onDefaultFolderChange) {
      onDefaultFolderChange(formik.values.defaultFolder);
    }
  }, [formik.values.defaultFolder]);

  const proceedToEnd = () => {
    setFileUploadComplete(true);
    setFileSelectCollapsed(true);
  };

  const proceedToFileUpload = () => {
    if (isRyanUser) {
      setOnBehalfOfComplete(true);
      setOnBehalfOfCollapsed(true);
    } else {
      setDetailsComplete(true);
      setDetailsCollapsed(true);
    }
    setFileSelectCollapsed(false);
  };

  const proceedToOnBehalfOf = () => {
    setDetailsComplete(true);
    setDetailsCollapsed(true);
    setOnBehalfOfCollapsed(false);
  };

  const resetAndDisableFolderSelection = () => {
    setSelectFolderDisabled(true);
    formik.setFieldValue('defaultFolder', defaultFolder);
  };

  const validateDescription = (proceedToNextStep = false): boolean => {
    formik.handleSubmit();
    if (formik.errors.description) {
      setDetailsCollapsed(false);
      return false;
    } else {
      setDetailsError(undefined);
      if (proceedToNextStep) {
        isRyanUser ? proceedToOnBehalfOf() : proceedToFileUpload();
      }
      return true;
    }
  };

  const validateLinkTo = (proceedToNextStep = false): boolean => {
    formik.handleSubmit();
    if (formik.errors.linkTo) {
      const errorMessage = t(
        `filesUpload.modal.fields.linkTo.required.${
          linkToFilter === LinkToFilterType.Task ? 'task' : 'dataRequest'
        }`
      );
      setDetailsError(errorMessage);
      setDetailsCollapsed(false);
      return false;
    } else if (formik.errors.comment) {
      setDetailsCollapsed(false);
      return false;
    } else {
      setDetailsError(undefined);
      if (proceedToNextStep) {
        isRyanUser ? proceedToOnBehalfOf() : proceedToFileUpload();
      }
      return true;
    }
  };

  const validateFileUpload = (): boolean => {
    const files = dss.getUploadedDocuments();
    if (files.length > 0) {
      fileSelectError && setFileSelectError(undefined);
      proceedToEnd();
      return true;
    } else {
      const errorMessage = t('filesUpload.modal.fields.fileUpload.required');
      setFileSelectError(errorMessage);
      setFileUploadComplete(false);
      setFileSelectCollapsed(false);
      return false;
    }
  };

  const toggleLinkToNone = () => {
    if (isLinkingToDataRequest) {
      formik.setFieldValue('defaultFolder', defaultFolder);
    }
    setIsLinkingToDataRequest(false);
    setSelectFolderDisabled(false);
    setSelectedOption(null);
    formik.setFieldValue('linkTo', null);
    setLinkToFilter(LinkToFilterType.None);
  };

  const toggleFilterToTasks = () => {
    if (isLinkingToDataRequest) {
      formik.setFieldValue('defaultFolder', defaultFolder);
    }
    setIsLinkingToDataRequest(false);
    setSelectFolderDisabled(false);
    setLinkToFilter(LinkToFilterType.Task);
    formik.setFieldValue('linkToType', FileUploadType.AdHocLinkToTask);
    setSelectedOption(null);
    formik.setFieldValue('linkTo', null);
  };

  const toggleFilterToDataRequests = () => {
    setLinkToFilter(LinkToFilterType.DataRequest);
    formik.setFieldValue('linkToType', FileUploadType.AdHocLinkToDataRequest);
    setSelectedOption(null);
    formik.setFieldValue('linkTo', null);
  };

  const handleSubmit = async () => {
    const detailsSectionComplete =
      linkToFilter === LinkToFilterType.None || validateLinkTo();
    const filesSectionComplete = validateFileUpload();
    const shouldSubmitUpload = detailsSectionComplete && filesSectionComplete;

    if (!shouldSubmitUpload) {
      return;
    }

    const files = dss.getUploadedDocuments();

    const {
      comment,
      description,
      defaultFolder: formikFolder,
      engagement,
      linkTo,
      linkToType,
      onBehalfOf
    } = formik.values;

    if (engagementGuid && files.length > 0) {
      const folder: FolderSelection = formikFolder;

      const fileDetails: UploadedFilesDetails = {
        numFiles: files.length,
        fileName: files[0].documentName,
        folderName:
          folder?.folderName || engagement?.engagementDisplayNameShort || ''
      };

      const request: IFileUploadModified = {
        title: '',
        description,
        comment:
          linkToFilter !== LinkToFilterType.None && comment
            ? comment.toJSON().text
            : null,
        defaultFolder: folder,
        engagementGuid: engagementGuid!,
        ipAddress: '',
        isExecutiveView: false,
        onBehalfOfUserFullName: onBehalfOf?.fullName || null,
        onBehalfOfUserGuid: onBehalfOf?.userGuid || null,
        fileType: linkTo ? linkToType : FileUploadType.AdHocWithoutLink,
        queueItemGuid: linkTo,
        attachments: {
          addUploaded: files,
          addExisting: [],
          deleteAttachments: []
        }
      };

      const isCreateNewFolder = folder && folder.folderGuid === null;
      if (isCreateNewFolder) {
        try {
          const createEngagementFolderPromise =
            ApiService.createEngagementFolder(engagementGuid, {
              folderName: folder.folderName,
              parentFolderGuid: folder?.parentFolderGuid || null
            });
          setCreateNewFolderLoading(createEngagementFolderPromise);

          const { data } = await createEngagementFolderPromise;

          request.defaultFolder = {
            folderGuid: data.folderGuid,
            folderName: data.folderName,
            folderVisibleToUserTypes: data.folderVisibleToUserTypes,
            parentFolderGuid: data.parentFolderGuid
          };
        } catch (error: any) {
          const { data, status } = error.response || {};

          let folderNameError: string | null = null;

          if (
            status === ApiErrorResponseEnums.BAD_REQUEST &&
            Array.isArray(data)
          ) {
            data.forEach(error => {
              if (error.propertyName === 'FolderName') {
                folderNameError = error.errorMessage;
              }
            });
          }

          if (folderNameError) {
            setSelectFolderError(folderNameError);
            setDetailsCollapsed(false);

            return;
          }

          pushServerErrorToast();
          return;
        } finally {
          setCreateNewFolderLoading(null);
        }
      }

      uploadFiles({
        engagementGuid,
        fileDetails,
        getTextToDisplay: t,
        onCancel,
        onClose,
        refreshUpdateDate,
        request,
        setUploadFilesLoading
      });
    }
  };

  const handleFetchUsersFilter = (users: IUserSummary[]) => {
    return users.filter(u => u.userGuid !== user.profile.userGuid);
  };

  const onOptionSelectHandler = (option: SelectOption | null) => {
    setDetailsComplete(false);
    if (option) {
      setDetailsError(undefined);
      if (option.type === LinkToFilterType.DataRequest) {
        if (option.defaultFolderGuid !== null) {
          const engagementDisplayNameShort =
            formik.values.engagement!.engagementDisplayNameShort;
          setIsLinkingToDataRequest(true);
          const { defaultFolderGuid, folderName } = option;
          const selectedFolder: FolderSelection = {
            folderGuid: defaultFolderGuid,
            folderName:
              folderName ||
              defaultFolder?.folderName ||
              engagementDisplayNameShort,
            parentFolderGuid: null,
            folderVisibleToUserTypes:
              defaultFolder?.folderVisibleToUserTypes || null
          };
          setSelectFolderDisabled(true);
          formik.setFieldValue('defaultFolder', selectedFolder);
        } else {
          resetAndDisableFolderSelection();
        }
      } else {
        setIsLinkingToDataRequest(false);
      }
      setSelectedOption(option);
      formik.setFieldValue('linkTo', option.value);
    } else {
      setSelectedOption(null);
      formik.setFieldValue('defaultFolder', null);
      setIsLinkingToDataRequest(false);
      formik.setFieldValue('linkTo', null);
      setSelectFolderDisabled(false);
    }
  };
  const isInternalFilesFunctionalityVisible = isFeatureToggled(
    Feature.InternalFiles
  );
  const foldersWithoutInternal = (folders || []).filter(
    folder => folder.folderName !== RYAN_INTERNAL
  );

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
        ref={formRef}
      >
        <Collapse
          collapsed={detailsCollapsed}
          isComplete={detailsComplete}
          onNext={() => {
            linkToFilter === LinkToFilterType.None
              ? validateDescription(true)
              : validateLinkTo(true);
          }}
          title={t('filesUpload.sections.fileDetails.label')}
        >
          <>
            <EngagementAutocomplete
              {...formikAutocompleteAjaxProps('engagement', formik)}
              customViewGuid={customViewGuid}
              disabled={true}
              label={t('filesUpload.modal.fields.engagement.label')}
            />
            <>
              <label className="ry-text-input__label">
                {t('filesUpload.modal.fields.linkTo.label')}
              </label>
              <FilterToggleButton
                buttonText={t('None')}
                isSelected={linkToFilter === LinkToFilterType.None}
                onClick={toggleLinkToNone}
              />
              {!permissionService ||
              permissionService.hasPermission(
                Permission.DataRequestsContribute
              ) ? (
                <FilterToggleButton
                  buttonText={t('Data Requests')}
                  disabled={!hasDataRequests}
                  isSelected={linkToFilter === LinkToFilterType.DataRequest}
                  onClick={toggleFilterToDataRequests}
                />
              ) : null}
              {!permissionService ||
              permissionService.hasPermission(Permission.TasksEdit) ? (
                <FilterToggleButton
                  buttonText={t('Tasks')}
                  disabled={!hasTasks}
                  isSelected={linkToFilter === LinkToFilterType.Task}
                  onClick={toggleFilterToTasks}
                />
              ) : null}
              {linkToFilter !== LinkToFilterType.None && (
                <Select
                  label={t('filesUpload.modal.fields.linkTo.label')}
                  onSelection={onOptionSelectHandler}
                  options={buildDropdownOptions(linkToFilter)}
                  resetSelectedOption={() => setSelectedOption(null)}
                  selectedOption={selectedOption}
                  selectFeedback={detailsError}
                />
              )}
            </>
            {linkToFilter === LinkToFilterType.None && (
              <>
                <Textarea
                  {...formikFieldProps('description', formik)}
                  label={t('dataRequest.modal.fields.description.label')}
                  maxLength={maxLengthDescription}
                  onChange={event => {
                    setDetailsComplete(false);
                    formik.setFieldValue('description', event.target.value);
                  }}
                />
              </>
            )}

            <SelectFolder
              {...((user.profile.userTypeId & UserType.ThirdParty) > 0 && {
                createMode: 'never'
              })}
              disabled={selectFolderDisabled}
              feedback={selectFolderError}
              folders={
                !isInternalFilesFunctionalityVisible
                  ? foldersWithoutInternal
                  : folders
              }
              invalid={selectFolderError !== null}
              label={
                isLinkingToDataRequest
                  ? t('dataRequest.modal.fields.defaultFolder.label')
                  : t('filesUpload.modal.fields.defaultFolder.label')
              }
              moveFileCount={2}
              onChange={(folder: FolderSelection) => {
                if (selectFolderError) {
                  setSelectFolderError(null);
                }
                setDetailsComplete(false);
                formik.setFieldValue('defaultFolder', folder);
              }}
              rootName={
                formik.values.engagement
                  ? formik.values.engagement.engagementDisplayNameShort
                  : ''
              }
              value={formik.values.defaultFolder}
            />
            <div
              style={{
                display:
                  linkToFilter === LinkToFilterType.None ? 'none' : 'block'
              }}
            >
              <CommentInput
                {...formikCommentProps('comment', formik)}
                boundingParentRef={formRef}
                disabled={formik.values.engagement === null}
                engagementGuid={
                  formik.values.engagement
                    ? formik.values.engagement.engagementGuid
                    : ''
                }
                label={t('filesUpload.modal.fields.comment.label')}
              />
            </div>
          </>
        </Collapse>
        {isRyanUser ? (
          <Collapse
            collapsed={onBehalfOfCollapsed}
            isComplete={onBehalfOfComplete}
            onNext={proceedToFileUpload}
            title={t('filesUpload.sections.onBehalfOf.label')}
          >
            <>
              <UserAutocomplete
                {...formikAutocompleteAjaxProps('onBehalfOf', formik)}
                engagementGuid={engagementGuid}
                label={t('filesUpload.modal.fields.onBehalfOf.label')}
                transformResponse={handleFetchUsersFilter}
              />
            </>
          </Collapse>
        ) : (
          <></>
        )}
        <Collapse
          collapsed={fileSelectCollapsed}
          isComplete={fileUploadComplete}
          title={t('filesUpload.sections.selectAFile.label')}
        >
          <div className="dss-file-upload-container">
            {fileSelectError && (
              <Message type="error">{fileSelectError}</Message>
            )}
            <DSSFileUpload
              border
              dssManager={dss}
              multiple
              title={t('Select Files')}
            />
          </div>
        </Collapse>
        <ButtonGroup>
          <Button
            disabled={selectFolderError !== null}
            loading={
              dss.getUploadingPromise() ||
              createNewFolderLoading ||
              uploadFilesLoading
            }
            text={t('filesUpload.modal.button.upload.label')}
            type="submit"
            variant={EButtonVariant.PRIMARY}
          />
          <Button
            disabled={
              (dss.getUploadingPromise() ||
                createNewFolderLoading ||
                uploadFilesLoading) !== null
            }
            onClick={onCancel}
            text={t('filesUpload.modal.button.cancel.label')}
          />
        </ButtonGroup>
      </form>
    </>
  );
};

interface UploadedFilesDetails {
  folderName: string;
  fileName: string;
  numFiles: number;
}

const uploadFiles = async ({
  engagementGuid,
  fileDetails,
  getTextToDisplay,
  onCancel,
  onClose,
  refreshUpdateDate,
  request,
  setUploadFilesLoading
}: {
  engagementGuid: string;
  fileDetails: UploadedFilesDetails;
  getTextToDisplay: TFunction;
  onCancel: () => void;
  onClose: () => void;
  refreshUpdateDate: (() => void) | undefined;
  request: IFileUploadModified;
  setUploadFilesLoading: Dispatch<SetStateAction<any>>;
}) => {
  const { fileName, folderName, numFiles } = fileDetails;
  const { onBehalfOfUserFullName, ...restOfRequest } = request;

  let toastContent: IPushToastProps = {};

  try {
    const uploadFilesPromise = ApiService.uploadFiles(
      engagementGuid,
      restOfRequest
    );
    setUploadFilesLoading(uploadFilesPromise);

    await uploadFilesPromise;

    const i18nData = {
      ...(onBehalfOfUserFullName && { behalfOfName: onBehalfOfUserFullName }),
      ...(numFiles === 1 && { fileName }),
      ...(numFiles > 1 && { fileCount: numFiles }),
      folderName
    };
    const pathToDisplayText = `filesUpload.modal.completeModal.success.by-you${
      onBehalfOfUserFullName ? '-behalf-x' : ''
    }${numFiles > 1 ? '_plural' : ''}`;

    toastContent = {
      content: (
        <Trans i18nKey={pathToDisplayText}>
          <b>
            {i18nData}
            {getTextToDisplay(pathToDisplayText)}
          </b>
        </Trans>
      ),
      title: getTextToDisplay('filesUpload.modal.completeModal.success.title'),
      type: EMessageTypes.SUCCESS
    };

    if (refreshUpdateDate) {
      refreshUpdateDate();
    }

    onClose();
  } catch (error: any) {
    toastContent = {
      content: (
        <Trans>
          <b>{getTextToDisplay('serverError.content')}</b>
        </Trans>
      ),
      title: getTextToDisplay('serverError.title'),
      type: EMessageTypes.ERROR
    };

    onCancel();
  } finally {
    pushToast(toastContent);
  }
};

const config: WithFormikConfig<FileUploadFormProps, FileRequestFormValues> = {
  handleSubmit: async (values: FileRequestFormValues, { props }) => {},
  mapPropsToValues: props => ({
    ...defaultFormValues,
    defaultFolder: props.defaultFolder
  }),
  validationSchema: ({ t }: FileUploadFormProps) =>
    yup.object({
      comment: yup.mixed().validateCommentLength(
        maxLengthComment,
        t('filesUpload.modal.fields.comment.maxLength', {
          length: maxLengthComment
        })
      ),
      description: yup.string().max(
        maxLengthDescription,
        t('dataRequest.modal.fields.description.maxLength', {
          length: maxLengthDescription
        })
      ),
      defaultFolder: yup.object().nullable(),
      engagement: yup.object(),
      linkTo: yup.string().required(),
      onBehalfOf: yup.object().nullable()
    })
};

export default withTranslation()(
  withFormik<FileUploadFormProps, FileRequestFormValues>(config)(
    withUser(FileRequestForm)
  )
);
