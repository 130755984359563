import camelCase from 'lodash/camelCase';

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Tooltip } from '@ryan/components';

import { useAppReadOnly, useUser } from '../../../../hooks';
import { Permission } from '../../../../interfaces';
import { formatDate } from '../../../../utils/formatDate';
import { isPastDue } from '../../../../utils/isPastDue';
import StatusIcon from '../../../StatusIcon/StatusIcon';
import * as ColumnsUtils from '../columns.utils';
import * as CommonColumnsEnums from './CommonColumns.enums';
import * as CommonColumnsInterfaces from './CommonColumns.interfaces';
import DataRequestActions from './DataRequestActions/DataRequestActions';

import './CommonColumns.scss';

const CommonColumns = (props: CommonColumnsInterfaces.TComponentProps) => {
  const ROOT_TO_TEXT = 'table.columns.common';

  const isAppReadOnly = useAppReadOnly();
  const { t: getTextToDisplay } = useTranslation();
  const {
    permissionService,
    user: { profile: activeUserProfile }
  } = useUser();

  const renderActionsForDataRequest: CommonColumnsInterfaces.IRenderActionsForDataRequest =
    dataRequestActionProps => (
      <div className="column-action-button">
        <DataRequestActions {...dataRequestActionProps} />
      </div>
    );

  const renderAssignedTo: CommonColumnsInterfaces.IRenderAssignedTo = ({
    assignedTo
  }) => (
    <span className="column-assigned-to">
      {assignedTo.userGuid === activeUserProfile.memberGuid
        ? getTextToDisplay(`${ROOT_TO_TEXT}.assignedToYou`)
        : assignedTo.name}
    </span>
  );

  const renderComment: CommonColumnsInterfaces.IRenderComment = ({
    commentCount,
    isEngagementReadOnly,
    onClick
  }) => {
    const isReadOnlyByState = isAppReadOnly || isEngagementReadOnly;

    const { buttonProps, icon, path, tooltipProps } =
      ColumnsUtils.getCommentProps({
        commentCount,
        isReadOnlyByState,
        isReadOnlyByPermissions: !permissionService.hasPermission(
          Permission.DataRequestsContribute
        )
      });

    return (
      <Tooltip
        {...tooltipProps}
        content={getTextToDisplay(`${ROOT_TO_TEXT}.${path}`, {
          count: commentCount
        })}
        renderTarget={({ open, ref, ...props }) => (
          <Button
            {...props}
            {...buttonProps}
            aria-expanded={open}
            icon={icon}
            innerRef={ref}
            onClick={onClick}
          />
        )}
      />
    );
  };

  const renderDataType: CommonColumnsInterfaces.IRenderDataType = ({
    dataType
  }) => (
    <span className="column-data-type">
      {getTextToDisplay(`dataRequest.dataTypes.${camelCase(dataType ?? '')}`)}
    </span>
  );

  const renderDueDate: CommonColumnsInterfaces.IRenderDueDate = ({
    dueDate,
    isOverridePastDue = false
  }) => {
    const hasPassedDueDate = !isOverridePastDue && isPastDue(new Date(dueDate));
    return (
      <span
        className={classNames('column-due-date', {
          'past-due': hasPassedDueDate
        })}
      >
        {formatDate(dueDate)}
      </span>
    );
  };

  const renderStatus: CommonColumnsInterfaces.IRenderStatus = ({
    statusId,
    subStatusId
  }) => <StatusIcon status={statusId} subStatus={subStatusId} />;

  const renderTitleWithLink: CommonColumnsInterfaces.IRenderTitleWithLink = ({
    href,
    title
  }) => (
    <Link className="column-title table-link" to={href}>
      {title}
    </Link>
  );

  const columnsToRenderMap = {
    [CommonColumnsEnums.EColumnType.ACTIONS_FOR_DATA_REQUEST]:
      renderActionsForDataRequest,
    [CommonColumnsEnums.EColumnType.ASSIGNED_TO]: renderAssignedTo,
    [CommonColumnsEnums.EColumnType.COMMENT]: renderComment,
    [CommonColumnsEnums.EColumnType.DATA_TYPE]: renderDataType,
    [CommonColumnsEnums.EColumnType.DUE_DATE]: renderDueDate,
    [CommonColumnsEnums.EColumnType.STATUS]: renderStatus,
    [CommonColumnsEnums.EColumnType.TITLE_WITH_LINK]: renderTitleWithLink
  };

  const { columnType } = props;
  const renderColumnType = columnsToRenderMap[columnType];

  const columnProps = props[columnType];

  return (
    <div className="common-column">{renderColumnType(columnProps as any)}</div>
  );
};

export default CommonColumns;
