import { isFolderNameExist } from '../isFolderNameExist';
import * as FolderValidationInterfaces from './folderValidation.interfaces';

const validateFolder: FolderValidationInterfaces.IValidateFolder =
  // NOTE: Validation order matters
  selectedFolder =>
    selectedFolder === null ||
    Boolean(selectedFolder.folderGuid) ||
    Boolean((selectedFolder.folderName || '').trim());

const validateUniqueFolder: FolderValidationInterfaces.IValidateUniqueFolder =
  ({ folders, selectedFolder }) => {
    const validations = {
      hasExistingArchivedFolder: false,
      isFolderLengthValid: true,
      isFolderUnique: true,
      isFolderUserVisibilityRestricted: false
    };

    if (selectedFolder === null || selectedFolder.folderGuid) {
      return validations;
    }

    if (selectedFolder.folderName.length > 255) {
      validations.isFolderLengthValid = false;
    }

    const {
      hasExistingName,
      isArchived: isExistingFolderArchived,
      isFolderUserVisibilityRestricted
    } = isFolderNameExist(
      selectedFolder.folderName,
      folders,
      selectedFolder.folderGuid,
      selectedFolder.parentFolderGuid
    );

    if (!hasExistingName) {
      return validations;
    }

    validations.isFolderUnique = false;
    validations.hasExistingArchivedFolder = isExistingFolderArchived;
    validations.isFolderUserVisibilityRestricted =
      isFolderUserVisibilityRestricted;

    return validations;
  };

export const validateFolderInput: FolderValidationInterfaces.IValidateFolderInput =
  (selectedFolder, folders, createError) => {
    let errorPath = '';

    if (!validateFolder(selectedFolder)) {
      errorPath = 'modal.dataRequestModal.fields.transferDestination.required';
    }

    if (!Boolean(errorPath)) {
      const {
        hasExistingArchivedFolder,
        isFolderLengthValid,
        isFolderUnique,
        isFolderUserVisibilityRestricted
      } = validateUniqueFolder({ folders, selectedFolder });

      // HACK: Re-using keys from another component.
      if (!isFolderLengthValid)
        errorPath = 'folder.renameModal.folderName.maxLength';
      else if (!isFolderUnique) {
        if (hasExistingArchivedFolder) {
          errorPath =
            'modal.dataRequestModal.fields.transferDestination.archived';
        } else if (isFolderUserVisibilityRestricted) {
          errorPath =
            'modal.dataRequestModal.fields.transferDestination.restricted';
        } else {
          errorPath =
            'modal.dataRequestModal.fields.transferDestination.exists';
        }
      }
    }

    return createError ? createError(errorPath) : errorPath;
  };
