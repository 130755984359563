import { UserAutocomplete } from 'components/AutocompleteAjax';
import Modal from 'components/Modal';
import { IFolder, IUser, IUserSummary } from 'interfaces';
import ApiService from 'services/ApiService';
import getAvatarUrl from 'utils/getAvatarUrl';

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Button,
  ButtonGroup,
  Checkbox,
  EButtonSizes,
  EButtonVariant,
  Icon,
  Radio,
  RadioGroup
} from '@ryan/components';

import { useAmplitude } from '../../../contexts/AmplitudeContext/AmplitudeConsumer';
import {
  AmplitudeActionType,
  amplitudeEventDetail
} from '../../../utils/amplitudeUtils/amplitudeUtils';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';

import './FolderClientVisibilityModal.scss';

const radioButtonGroupProps = {};

export interface IFolderToSetClientVisibility extends IFolder {
  isParentFolderRestricted?: boolean;
}

export interface IFolderSetClientVisibilityModalProps {
  engagementGuid: string;
  folder: IFolderToSetClientVisibility;
  loading: Promise<any> | null;
  onSubmit: (users?: IUserSummary[]) => any;
  onCancel: () => void;
}

const FolderSetClientVisibilityModal: FunctionComponent<
  IFolderSetClientVisibilityModalProps
> = ({ engagementGuid, folder, loading, onSubmit, onCancel }) => {
  const { t: getTextToDisplay } = useTranslation();
  const [currentFolderVisibilityUsers, setCurrentFolderVisibilityUsers] =
    useState<IUserSummary[]>([]);
  const [childFolderVisibilityUsers, setChildFolderVisibilityUsers] = useState<
    IUserSummary[]
  >([]);

  const [usersWithVisibility, setUsersWithVisibility] = useState<
    IUserSummary[]
  >([]);
  const [isRestrictedAccess, setIsRestrictedAccess] = useState<boolean>(false);
  const [isParentRestricted, setIsParentRestricted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentSearchValue, setCurrentSearchValue] =
    useState<IUserSummary | null>(null);
  const [potentialFolderVisibilityUsers, setPotentialFolderVisibilityUsers] =
    useState<IUserSummary[]>([]);
  const [childVisibilitySet, setChildVisibilitySet] = useState<Set<string>>();
  const [parentVisibilitySet, setParentVisibilitySet] = useState<Set<string>>();

  const mapUsersToIUserSummaries = (users: IUser[]): IUserSummary[] => {
    return users?.map(user => {
      return {
        memberGuid: user.memberGuid,
        userGuid: user.userGuid,
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.fullName,
        title: user.title,
        department: user.department,
        roleName: user.roleName,
        avatarUrl: user.avatarUrl,
        userAvatarDocumentGuid: user.userAvatarDocumentGuid,
        email: user.email,
        userTypeId: user.userTypeId,
        canBeMentionedInDRComment: false
      };
    });
  };
  const { triggerAmplitudeEvent } = useAmplitude();

  useEffect(() => {
    if (!folder) return;

    const loadFolderVisibilityData = async () => {
      try {
        setIsLoading(true);
        const { data } = await ApiService.getFolderUserVisibility(
          engagementGuid,
          folder.folderGuid
        );

        if (folder.parentFolderGuid) {
          const { data: parentData } = await ApiService.getFolderUserVisibility(
            engagementGuid,
            folder.parentFolderGuid
          );
          setIsParentRestricted(parentData.isFolderUserVisibilityRestricted);
          setIsRestrictedAccess(
            parentData.isFolderUserVisibilityRestricted ||
              data.isFolderUserVisibilityRestricted
          );
        } else {
          setIsRestrictedAccess(data.isFolderUserVisibilityRestricted);
        }

        setPotentialFolderVisibilityUsers(
          mapUsersToIUserSummaries(data.potentialFolderVisibilityUsers)
        );
        setCurrentFolderVisibilityUsers(
          mapUsersToIUserSummaries(data.currentFolderVisibilityUsers)
        );
        setChildFolderVisibilityUsers(
          mapUsersToIUserSummaries(data.childFolderVisibilityUsers)
        );
        setChildVisibilitySet(
          new Set(data.childFolderVisibilityUsers.map(u => u.userGuid))
        );

        setUsersWithVisibility(
          mapUsersToIUserSummaries(
            Array.from(
              new Map(
                [
                  ...data.currentFolderVisibilityUsers,
                  ...data.childFolderVisibilityUsers
                ].map(user => [user.userGuid, user])
              ).values()
            )
          )
        );
      } catch (e) {
        console.error('Failed to load visibility data: ', e);
        pushServerErrorToast();
      } finally {
        setIsLoading(false);
      }
    };

    loadFolderVisibilityData();
  }, [folder, engagementGuid]);

  const handleFetchFolderVisibilityData = async (query: string) => {
    return potentialFolderVisibilityUsers.filter(
      u =>
        (u.fullName?.toLowerCase().includes(query.toLowerCase()) ||
          u.email?.toLowerCase().includes(query.toLowerCase())) &&
        !usersWithVisibility.some(
          user =>
            user.userGuid === u.userGuid || user.memberGuid === u.memberGuid
        )
    );
  };

  const handleRemoveUser = (user: IUserSummary) => {
    const updated = usersWithVisibility.filter(u => u !== user);
    setUsersWithVisibility(updated);

    setPotentialFolderVisibilityUsers([
      ...potentialFolderVisibilityUsers,
      user
    ]);
  };

  function handleSubmit() {
    triggerAmplitudeEvent(
      {
        amplitudeEventAction: AmplitudeActionType.CLICK,
        amplitudeEventName: amplitudeEventDetail.global.saveManageVisibility,
        amplitudeEventProperty: {
          'Client team members': isRestrictedAccess ? 'Restrict Access' : 'All'
        }
      },
      false
    );

    if (isRestrictedAccess) {
      onSubmit(usersWithVisibility);
    } else {
      onSubmit([]);
    }
  }

  const handleValue = () => {
    return currentSearchValue ? currentSearchValue : null;
  };

  if (folder) {
    return (
      <Modal
        className={'followers-modal'}
        onClose={onCancel}
        open
        title={getTextToDisplay('folder.setFolderVisibilityModal.title')}
      >
        <form autoComplete="off">
          <label className="ry-label">
            {getTextToDisplay(
              'folder.setFolderVisibilityModal.ryanTeamMembers'
            )}
          </label>
          <Checkbox
            checked={true}
            disabled={true}
            label={getTextToDisplay('folder.setFolderVisibilityModal.all')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {}}
            value={'isConfirmedThirdPartyUser'}
          />
          {(isLoading && (
            <RadioGroup
              {...radioButtonGroupProps}
              className={'ry-radio-group'}
              name="shouldAttachFiles"
              onChange={e => {
                setIsRestrictedAccess(e.target.value === 'RESTRICTED');
              }}
              value={isRestrictedAccess ? 'RESTRICTED' : 'ALL'}
            >
              <div className="ry-radio-group__header">
                <label className="ry-radio-group__label">
                  {getTextToDisplay(
                    'folder.setFolderVisibilityModal.clientTeamMembers'
                  )}
                </label>
              </div>
              <Radio
                className={'ry-radio-skeleton'}
                label={getTextToDisplay('folder.setFolderVisibilityModal.all')}
                value={`ALL`}
              />
              <Radio
                className={'ry-radio-skeleton'}
                label={getTextToDisplay(
                  'folder.setFolderVisibilityModal.restrictAccess'
                )}
                value={`RESTRICTED`}
              />
            </RadioGroup>
          )) || (
            <RadioGroup
              {...radioButtonGroupProps}
              className={'ry-radio-group'}
              name="shouldAttachFiles"
              onChange={e => {
                setIsRestrictedAccess(e.target.value === 'RESTRICTED');
              }}
              value={isRestrictedAccess ? 'RESTRICTED' : 'ALL'}
            >
              <div className="ry-radio-group__header">
                <label className="ry-radio-group__label">
                  {getTextToDisplay(
                    'folder.setFolderVisibilityModal.clientTeamMembers'
                  )}
                </label>
              </div>
              <Radio
                disabled={isParentRestricted}
                label={getTextToDisplay('folder.setFolderVisibilityModal.all')}
                value={`ALL`}
              />
              <Radio
                label={getTextToDisplay(
                  'folder.setFolderVisibilityModal.restrictAccess'
                )}
                value={`RESTRICTED`}
              />
            </RadioGroup>
          )}
          {isRestrictedAccess && (
            <div className="visibility-management__container">
              <UserAutocomplete
                disabled={false}
                engagementGuid={engagementGuid}
                id={'users-search'}
                label={getTextToDisplay(
                  'folder.setFolderVisibilityModal.searchClientTeamMembers'
                )}
                loading={isLoading}
                onChange={(user: IUserSummary | null) => {
                  if (user) {
                    setUsersWithVisibility([user, ...usersWithVisibility]);
                    setCurrentSearchValue({} as IUserSummary);
                    setPotentialFolderVisibilityUsers(
                      potentialFolderVisibilityUsers.filter(
                        u => u.userGuid !== user.userGuid
                      )
                    );
                  }
                }}
                onFetchOptions={handleFetchFolderVisibilityData}
                value={handleValue()}
              />
              <h3>
                {`${getTextToDisplay(
                  'folder.setFolderVisibilityModal.visibilityGranted'
                )} (${usersWithVisibility.length})`}
              </h3>
              <div className="user-list"></div>
              {isLoading ? (
                <div className="center-icon">
                  <Icon className="loading-spin" name="loading" />
                </div>
              ) : (
                <div className="user-list">
                  {(usersWithVisibility as IUserSummary[]).map(
                    (user: IUserSummary) => (
                      <div className="user-list-row" key={user.userGuid}>
                        <div className="user-list-row__avatar">
                          <Avatar
                            firstName={user.firstName || ''}
                            lastName={user.lastName || ''}
                            profileImageSrc={getAvatarUrl({
                              avatarUrl: user.avatarUrl,
                              userAvatarDocumentGuid:
                                user.userAvatarDocumentGuid
                            })}
                          />
                        </div>
                        <div className="user-list-row__name">
                          <span className="user-list-row__user-name">
                            {`${user.firstName} ${user.lastName}`}
                          </span>
                        </div>
                        <div className="user-list-row__action">
                          <Button
                            ariaLabel={getTextToDisplay(
                              'followersModal.remove'
                            )}
                            className={
                              childVisibilitySet?.has(user.userGuid)
                                ? 'disabled'
                                : 'button-margins'
                            }
                            disabled={
                              childVisibilitySet?.has(user.userGuid) ||
                              (isParentRestricted &&
                                usersWithVisibility.length === 1)
                            }
                            icon="trash"
                            onClick={() => handleRemoveUser(user)}
                            size={EButtonSizes.SMALL}
                            variant={EButtonVariant.TEXT}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          )}
          <ButtonGroup>
            <Button
              disabled={isLoading}
              loading={loading}
              onClick={handleSubmit}
              text={getTextToDisplay('Save')}
              type="submit"
              variant={EButtonVariant.PRIMARY}
            />
            <Button
              disabled={loading !== null}
              onClick={onCancel}
              text={getTextToDisplay('Cancel')}
              variant={EButtonVariant.SECONDARY}
            />
          </ButtonGroup>
        </form>
      </Modal>
    );
  }

  return null;
};

export default FolderSetClientVisibilityModal;
