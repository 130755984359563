import { IPropertyTaxDocumentType } from 'interfaces/IPropertyTaxSiteClass';
import camelCase from 'lodash/camelCase';

import { withFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  InfoWell,
  Multiselect,
  TextInput,
  Textarea
} from '@ryan/components';

import { withUser } from '../../../../../contexts/UserContext';
import { FolderSelection, IJurisdictions } from '../../../../../interfaces';
import ApiService from '../../../../../services/ApiService';
import * as FolderValidationUtils from '../../../../../utils/folderValidation/folderValidation.utils';
import { formikFieldProps, yup } from '../../../../../utils/forms';
import pushServerErrorToast from '../../../../../utils/pushServerErrorToast';
import SelectFolder from '../../../../SelectFolder/SelectFolder';
import {
  IDataRequestFormProps,
  IDataRequestFormValues,
  IDataRequestInnerFormProps,
  TDropdownOption
} from './utils';
import { IJurisdiction } from './utils';

const FirstStepFormPropertyTax: React.FC<
  IDataRequestInnerFormProps
> = props => {
  const formRef = useRef<HTMLFormElement>(null);
  const [jurisdictionsFetching, setJurisdictionsFetching] = useState(false);
  const [preSelectedDocumentTypes, setPreSelectedDocumentTypes] = useState<{
    [key: string]: IPropertyTaxDocumentType[];
  }>({});
  const [propertyTaxDocumentTypesFetcing, setPropertyTaxDocumentTypesFetching] =
    useState(false);
  const [propertyTaxSiteClassesFetching, setPropertyTaxSiteClassesFetching] =
    useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const {
    dataRequestTypeName,
    dataSpecs,
    description,
    engagement,
    errors,
    folders,
    foldersFetching,
    handleSubmit,
    isSubmitting,
    jurisdictions,
    jurisdictionsAll,
    onNextStepClick,
    propertyTaxDocumentType,
    propertyTaxSiteClass,
    setDataSpecs,
    setJurisdictions,
    setJurisdictionsAll,
    status,
    setDescription,
    setFieldValue,
    setPropertyTaxDocumentType,
    setPropertyTaxSiteClass,
    setStatus,
    setTitle,
    setTouched,
    setTransferDestination,
    t: getTextToDisplay,
    title,
    transferDestination,
    values
  } = props;

  const isValid =
    !isSubmitting &&
    !foldersFetching &&
    !propertyTaxSiteClassesFetching &&
    !propertyTaxDocumentTypesFetcing &&
    !jurisdictionsFetching &&
    !Boolean(status.transferDestinationError);

  const fetchJurisdictions = useCallback(
    async (engagementGuid: string) => {
      setJurisdictionsFetching(true);
      if (!jurisdictionsAll.length) {
        try {
          const response = await ApiService.getJurisdictionsV2(engagementGuid);
          const jurisdictionsData =
            response.data as unknown as IJurisdictions[];

          const jurisdictions = jurisdictionsData.map(
            (type: IJurisdictions) => ({
              isPreselected: type.isPreselected,
              label: type.name,
              value: type.jurisdictionGuid
            })
          );

          setJurisdictionsAll(jurisdictions as unknown as IJurisdictions[]);

          setFieldValue('jurisdictions', jurisdictions);

          const preselectedJurisdictions = jurisdictions
            .filter(jurisdiction => jurisdiction.isPreselected)
            .map(jurisdiction => jurisdiction.value);

          if (preselectedJurisdictions.length) {
            setFieldValue('selectedJurisdictions', preselectedJurisdictions);
            setJurisdictions(preselectedJurisdictions);
          }
        } catch (error) {
          setFieldValue('jurisdictions', []);
          pushServerErrorToast();
        }
      } else {
        setFieldValue('jurisdictions', jurisdictionsAll);
      }
      setJurisdictionsFetching(false);
    },
    [
      jurisdictionsAll,
      setFieldValue,
      setJurisdictions,
      setJurisdictionsAll,
      setJurisdictionsFetching
    ]
  );

  const fetchPropertyTaxDocumentTypes = useCallback(async () => {
    setPropertyTaxDocumentTypesFetching(true);

    try {
      const { data: propertyTaxDocumentTypesResponse } =
        await ApiService.getPropertyTaxDocumentTypes();

      setFieldValue(
        'propertyTaxDocumentTypes',
        propertyTaxDocumentTypesResponse
      );
    } catch (error) {
      setFieldValue('propertyTaxDocumentTypes', []);
    }

    setPropertyTaxDocumentTypesFetching(false);
  }, [setFieldValue, setPropertyTaxDocumentTypesFetching]);

  const fetchPropertyTaxSiteClasses = useCallback(async () => {
    setPropertyTaxSiteClassesFetching(true);

    try {
      const { data: propertyTaxSiteClassesResponse } =
        await ApiService.getPropertyTaxSiteClasses();

      const mappedPropertyTaxDocumentTypes: {
        [key: string]: IPropertyTaxDocumentType[];
      } = {};
      const mappedPropertyTaxSiteClasses: TDropdownOption[] = [];

      propertyTaxSiteClassesResponse.forEach(propertyTaxSiteClass => {
        mappedPropertyTaxSiteClasses.push({
          label: propertyTaxSiteClass.siteClassName,
          value: propertyTaxSiteClass.propertyTaxSiteClassGuid
        });

        mappedPropertyTaxDocumentTypes[
          propertyTaxSiteClass.propertyTaxSiteClassGuid
        ] = propertyTaxSiteClass.propertyTaxDocumentTypes || [];
      });

      setFieldValue('propertyTaxSiteClass', mappedPropertyTaxSiteClasses);
      setPreSelectedDocumentTypes(mappedPropertyTaxDocumentTypes);
    } catch (error) {
      setFieldValue('propertyTaxSiteClass', []);
      setPreSelectedDocumentTypes({});
      pushServerErrorToast();
    }

    setPropertyTaxSiteClassesFetching(false);
  }, [
    setFieldValue,
    setPreSelectedDocumentTypes,
    setPropertyTaxSiteClassesFetching
  ]);

  useEffect(() => {
    fetchPropertyTaxDocumentTypes();
    fetchPropertyTaxSiteClasses();

    if (engagement && engagement.engagementGuid) {
      fetchJurisdictions(engagement.engagementGuid);
    }
  }, [
    engagement,
    fetchJurisdictions,
    fetchPropertyTaxDocumentTypes,
    fetchPropertyTaxSiteClasses
  ]);

  useEffect(() => {
    if (dataSpecs) {
      setFieldValue('dataSpecs', dataSpecs);
    }
    if (description) {
      setFieldValue('descriptionOptional', description);
    }
    if (jurisdictions) {
      setFieldValue('selectedJurisdictions', jurisdictions);
    }
    if (propertyTaxDocumentType) {
      setFieldValue('selectedPropertyTaxDocumentType', propertyTaxDocumentType);
    }
    if (propertyTaxSiteClass) {
      setFieldValue('selectedSiteClasses', propertyTaxSiteClass);
    }
    if (title) {
      setFieldValue('dataRequestTitle', title);
    }
    if (transferDestination) {
      setFieldValue('defaultFolder', transferDestination);
    }
  }, [
    dataSpecs,
    description,
    jurisdictions,
    propertyTaxDocumentType,
    propertyTaxSiteClass,
    setFieldValue,
    title,
    transferDestination
  ]);

  const handleDataSpecsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const selected = e.target.value;
    setFieldValue('dataSpecs', selected);
    setDataSpecs(selected);
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const selected = e.target.value;
    setFieldValue('descriptionOptional', selected);
    setDescription(selected);
  };

  const handleDropdownChange = (data: string[]) => {
    setFieldValue('selectedPropertyTaxDocumentType', data);
    setPropertyTaxDocumentType(data);
  };

  const handleDropdownChangeForSiteClasses = (
    selectedSiteClassesGuids: string[]
  ) => {
    setFieldValue('selectedSiteClasses', selectedSiteClassesGuids);
    setPropertyTaxSiteClass(selectedSiteClassesGuids);

    const dataForPropertyTaxDocumentType = selectedSiteClassesGuids
      .flatMap(selectedSiteClassGuid =>
        (preSelectedDocumentTypes[selectedSiteClassGuid] || []).map(
          ({ propertyTaxDocumentTypeGuid }) => propertyTaxDocumentTypeGuid
        )
      )
      .filter(
        (propertyTaxDocumentTypeGuid, index, self) =>
          self.indexOf(propertyTaxDocumentTypeGuid) === index
      );

    setFieldValue(
      'selectedPropertyTaxDocumentType',
      dataForPropertyTaxDocumentType
    );
    setPropertyTaxDocumentType(dataForPropertyTaxDocumentType);
  };

  const handleFormSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setSubmitClicked(true);

    const transferDestinationError =
      transferDestination === null
        ? null
        : transferDestination?.folderName.trim().length === 0
          ? getTextToDisplay(
            'modal.dataRequestModal.fields.transferDestination.required'
          )
          : null;

    setStatus({
      ...status,
      transferDestinationError
    });

    setTouched({
      dataRequestTitle: true,
      dataSpecs: true,
      descriptionOptional: true,
      selectedJurisdictions: true,
      selectedPropertyTaxDocumentType: true,
      selectedSiteClasses: true
    });
    handleSubmit();
  };

  const handleJurisdictionsChange = (data: string[]) => {
    setFieldValue('selectedJurisdictions', data);
    setJurisdictions(data);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = e.target.value;
    setFieldValue('dataRequestTitle', selected);
    setTitle(selected);
  };

  return (
    <>
      {status.error}
      <h2 className="step-title">
        {getTextToDisplay('modal.dataRequestModal.steps.step-2.title')}
      </h2>

      <InfoWell
        accountName={engagement?.accountName || ''}
        dataRequestType={getTextToDisplay(
          `dataRequest.dataTypes.${camelCase(
            dataRequestTypeName.replaceAll(' ', '')
          )}`
        )}
        projectName={engagement?.engagementDisplayNameLong || ''}
      />

      <form autoComplete="off" ref={formRef}>
        <TextInput
          {...formikFieldProps('dataRequestTitle', props)}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.requestTitle.label'
          )}
          onChange={e => handleTitleChange(e)}
        />

        <Multiselect
          {...formikFieldProps('selectedSiteClasses', props)}
          disabled={propertyTaxSiteClassesFetching}
          feedback={
            !values.selectedSiteClasses.length && submitClicked
              ? getTextToDisplay(
                'modal.dataRequestModal.fields.siteClasses.required'
              )
              : ''
          }
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.siteClasses.label'
          )}
          noOptionText={getTextToDisplay('dataRequest.noOptionsLabel')}
          notFoundLabel={getTextToDisplay('dataRequest.notFoundLabel')}
          onChange={(data: any) => {
            handleDropdownChangeForSiteClasses(data);
          }}
          options={
            values.propertyTaxSiteClass.map(option => ({
              ...option,
              label: option.label
            })) as TDropdownOption[]
          }
          placeholder=""
          searchBoxPlaceholder={getTextToDisplay(
            'modal.dataRequestModal.search'
          )}
          selectAllLabel={getTextToDisplay(
            'modal.dataRequestModal.selectAllLabel'
          )}
          selectionLabel={getTextToDisplay('dataRequest.selectionLabel')}
          showAdditionalButtons={false}
          value={propertyTaxSiteClass || values.selectedSiteClasses}
        />

        <Multiselect
          {...formikFieldProps('selectedPropertyTaxDocumentType', props)}
          disabled={
            propertyTaxDocumentTypesFetcing ||
            !values.selectedSiteClasses.length
          }
          feedback={
            !values.selectedPropertyTaxDocumentType.length && submitClicked
              ? getTextToDisplay(
                'modal.dataRequestModal.fields.propertyTaxDocumentTypes.required'
              )
              : ''
          }
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.propertyTaxDocumentTypes.label'
          )}
          noOptionText={getTextToDisplay('dataRequest.noOptionsLabel')}
          notFoundLabel={getTextToDisplay('dataRequest.notFoundLabel')}
          onChange={(data: any) => handleDropdownChange(data)}
          options={values.propertyTaxDocumentTypes.map(
            propertyTaxDocumentType => ({
              label: propertyTaxDocumentType.documentTypeName,
              value: propertyTaxDocumentType.propertyTaxDocumentTypeGuid
            })
          )}
          placeholder=""
          searchBoxPlaceholder={getTextToDisplay(
            'modal.dataRequestModal.search'
          )}
          selectAllLabel={getTextToDisplay(
            'modal.dataRequestModal.selectAllLabel'
          )}
          selectionLabel={getTextToDisplay('dataRequest.selectionLabel')}
          showAdditionalButtons={false}
          value={
            propertyTaxDocumentType || values.selectedPropertyTaxDocumentType
          }
        />
        <div
          style={{
            position: 'relative',
            top: '-30px',
            color: '#636575',
            fontSize: '0.875rem',
            lineHeight: '1.5rem'
          }}
        >
          {getTextToDisplay(
            'modal.dataRequestModal.fields.propertyTaxDocumentTypes.helperText'
          )}
        </div>
        <Multiselect
          {...formikFieldProps('selectedJurisdictions', props)}
          disabled={jurisdictionsFetching}
          feedback={
            !values.selectedJurisdictions.length && submitClicked
              ? getTextToDisplay(
                'modal.dataRequestModal.fields.jurisdictions.required'
              )
              : ''
          }
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.jurisdictions.label'
          )}
          noOptionText={getTextToDisplay('dataRequest.noOptionsLabel')}
          notFoundLabel={getTextToDisplay('dataRequest.notFoundLabel')}
          onChange={(data: any) => handleJurisdictionsChange(data)}
          options={values.jurisdictions as IJurisdiction[]}
          placeholder=""
          searchBoxPlaceholder={getTextToDisplay(
            'modal.dataRequestModal.search'
          )}
          selectAllLabel={getTextToDisplay(
            'modal.dataRequestModal.selectAllLabel'
          )}
          selectionLabel={getTextToDisplay('dataRequest.selectionLabel')}
          showAdditionalButtons={false}
          value={jurisdictions || values.selectedJurisdictions}
        />

        <Textarea
          {...formikFieldProps('dataSpecs', props)}
          helperText={getTextToDisplay(
            'modal.dataRequestModal.fields.dataSpecs.helperText'
          )}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.dataSpecs.optional'
          )}
          maxLength={600}
          onChange={e => handleDataSpecsChange(e)}
          value={dataSpecs}
        />

        <Textarea
          {...formikFieldProps('descriptionOptional', props)}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.descriptionOptional.label'
          )}
          maxLength={600}
          onChange={e => handleDescriptionChange(e)}
          value={description}
        />

        <SelectFolder
          disabled={folders === null}
          feedback={status.transferDestinationError || errors.defaultFolder}
          folders={folders || []}
          invalid={!!status.transferDestinationError || !!errors.defaultFolder}
          isHideRyanInternal={true}
          label={getTextToDisplay(
            'dataRequest.modal.fields.defaultFolder.label'
          )}
          moveFileCount={2}
          onChange={(folder: FolderSelection) => {
            if (status.transferDestinationError) {
              setStatus({
                ...status,
                transferDestinationError: null
              });
            }
            if (folder?.folderGuid) {
              setFieldValue('defaultFolder', folder);
              setTransferDestination(folder);
            } else {
              const transferDestinationError =
                FolderValidationUtils.validateFolderInput(
                  folder,
                  folders
                ) as string;

              if (Boolean(transferDestinationError)) {
                setStatus({
                  ...status,
                  transferDestinationError: getTextToDisplay(
                    transferDestinationError
                  )
                });
              }

              setFieldValue('defaultFolder', folder);
              setTransferDestination(folder);
            }
          }}
          rootName={engagement ? engagement.engagementDisplayNameShort : ''}
          value={values.defaultFolder}
        />

        <ButtonGroup>
          <Button
            loading={status.loading}
            onClick={() => onNextStepClick(1)}
            text={getTextToDisplay('modal.dataRequestModal.back')}
            type="submit"
            variant={EButtonVariant.SECONDARY}
          />
          <Button
            disabled={!isValid}
            loading={status.loading}
            onClick={e => handleFormSubmit(e)}
            text={getTextToDisplay('modal.dataRequestModal.next')}
            type="submit"
            variant={EButtonVariant.PRIMARY}
          />
        </ButtonGroup>
      </form>
    </>
  );
};

const defaultValues: IDataRequestFormValues = {
  dataRequestTitle: '',
  dataSpecs: '',
  defaultFolder: null,
  descriptionOptional: '',
  propertyTaxDocumentTypes: [],
  propertyTaxSiteClass: [],
  jurisdictions: [],
  selectedJurisdictions: [],
  selectedPropertyTaxDocumentType: [],
  selectedSiteClasses: []
};

export default withTranslation()(
  withFormik<IDataRequestFormProps, IDataRequestFormValues>({
    mapPropsToValues: ({ propertyTaxDocumentType }) => ({
      ...defaultValues,
      selectedPropertyTaxDocumentType: propertyTaxDocumentType.length
        ? propertyTaxDocumentType
        : defaultValues.selectedPropertyTaxDocumentType
    }),
    validateOnMount: false,

    mapPropsToStatus: () => ({
      loading: null
    }),

    validationSchema: ({
      t: getTextToDisplay
    }: IDataRequestFormProps & IDataRequestFormValues) =>
      yup.object({
        dataRequestTitle: yup
          .string()
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.requestTitle.required'
            )
          )
          .max(
            50,
            getTextToDisplay('modal.dataRequestModal.fields.requestTitle.max')
          ),
        dataSpecs: yup
          .string()
          .max(
            600,
            getTextToDisplay('modal.dataRequestModal.fields.dataSpecs.max')
          ),
        descriptionOptional: yup
          .string()
          .max(
            600,
            getTextToDisplay(
              'modal.dataRequestModal.fields.descriptionOptional.max'
            )
          ),
        propertyTaxDocumentTypes: yup
          .string()
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.propertyTaxDocumentTypes.required'
            )
          ),
        propertyTaxSiteClass: yup
          .string()
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.siteClasses.required'
            )
          ),
        selectedJurisdictions: yup
          .array()
          .min(
            1,
            getTextToDisplay(
              'modal.dataRequestModal.fields.jurisdictions.required'
            )
          ),
        selectedPropertyTaxDocumentType: yup.array().min(
          1,
          getTextToDisplay(
            'modal.dataRequestModal.fields.propertyTaxDocumentTypes.required' // TODO: There is min for documentTypes. I.E documentTypes.min. Is this used?
          )
        ),
        selectedSiteClasses: yup
          .string()
          .min(
            1,
            getTextToDisplay(
              'modal.dataRequestModal.fields.siteClasses.required'
            )
          )
      }),

    handleSubmit: async (values, formik) => {
      formik.props.onNextStepClick(3);
    }
  })(withUser(FirstStepFormPropertyTax))
);
