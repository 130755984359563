import {
  DirectoryItemType,
  FileSearchFilter,
  FolderSelection,
  IDirectoryFile,
  IDirectoryItemLink,
  IDirectoryResponse,
  IEngagementFileRequest,
  IFolder,
  IFolderRequest,
  IFolderTree,
  IPagedDataParams,
  IPagedDataResponse,
  IZipRequestParams
} from 'interfaces';
import { IFileFolderUserVisibility } from 'interfaces/IFileFolderUserVisibility';
import { IFileUploadLinkable } from 'interfaces/IFileUploadLinkable';
import { DirectoryItemStatus } from 'routes/DataAndFiles/Files/Files';
import findFolder from 'utils/findFolder';
import { transformResponse } from 'utils/transformResponse';
import { unwrapCommandResponse } from 'utils/unwrapCommandResponse';

import { CancelToken } from 'axios';

import ApiBase from './ApiBase';

//
//
// Directory
//
//

export async function getDirectoryTypes(this: ApiBase) {
  return this.server.get<FileSearchFilter[]>(`/api/directory/search/filters`);
}

function mapDirectoryItems(folders: IFolderTree[]) {
  return function (data: IDirectoryResponse) {
    return {
      ...data,
      results: Array.isArray(data.results)
        ? data.results.map(dirItem => {
            const parentFolderGuid =
              dirItem.type === DirectoryItemType.File
                ? (dirItem.item as IDirectoryFile).folderGuid
                : (dirItem.item as IFolder).parentFolderGuid;

            if (parentFolderGuid) {
              const parentFolder = findFolder(folders, parentFolderGuid);
              if (parentFolder) {
                return { ...dirItem, parentFolder };
              }
            }

            /**
             * @todo throw error?
             * @todo should API return more folder info?
             */
            return { ...dirItem, parentFolder: null };
          })
        : data.results
    };
  };
}

export async function getEngagementDirectoryByFolder(
  this: ApiBase,
  engagementGuid: string,
  params: {
    documentGuid?: string;
    folderGuid?: string;
    directoryItem?: string;
    queueItemGuid?: string;
    sort?: string;
    pageNumber?: number;
    itemsPerPage?: number;
    status?: DirectoryItemStatus;
    filterGuids?: string[];
    isTransferredFiles?: boolean;
    queueItemGuidToFilterExistingItems?: string;
  },
  folders: IFolderTree[],
  cancelToken?: CancelToken
) {
  return this.server.get<IDirectoryResponse>(
    `/api/engagements/${engagementGuid}/directory/folder/search`,
    {
      cancelToken,
      params,
      transformResponse: [...transformResponse, mapDirectoryItems(folders)]
    }
  );
}

export async function getDataRequestTransferredFiles(
  this: ApiBase,
  engagementGuid: string,
  queueItemGuid: string,
  params: {
    pageNumber?: number;
    itemsPerPage?: number;
  },
  cancelToken?: CancelToken
) {
  return this.server.get<IDirectoryResponse>(
    `/api/engagements/${engagementGuid}/dataRequests/${queueItemGuid}/transferredFiles`,
    {
      cancelToken,
      params,
      transformResponse
    }
  );
}

export async function getEngagementDirectoryBySearch(
  this: ApiBase,
  engagementGuid: string,
  params: {
    searchTerm: string;
    sort?: string;
    pageNumber?: number;
    itemsPerPage?: number;
    filterGuids?: string[];
    isTransferredFiles?: boolean;
    queueItemGuid?: string;
    queueItemGuidToFilterExistingItems?: string;
    status?: DirectoryItemStatus;
    documentGuid?: string;
  },
  folders: IFolderTree[],
  cancelToken?: CancelToken
) {
  return this.server.get<IDirectoryResponse>(
    `/api/engagements/${engagementGuid}/directory/text/search`,
    {
      cancelToken,
      params,
      transformResponse: [...transformResponse, mapDirectoryItems(folders)]
    }
  );
}

//
//
// Folders
//
//

export async function getEngagementFolders(
  this: ApiBase,
  engagementGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IFolderTree[]>(
    `/api/engagements/${engagementGuid}/folders/tree`,
    { cancelToken }
  );
}

export async function createEngagementFolder(
  this: ApiBase,
  engagementGuid: string,
  request: { parentFolderGuid: string | null; folderName: string }
) {
  return unwrapCommandResponse<IFolder>(
    this.server.post(`/api/engagements/${engagementGuid}/folders`, request)
  );
}

export async function updateEngagementFolder(
  this: ApiBase,
  engagementGuid: string,
  folderGuid: string,
  request: IFolderRequest
) {
  return this.server.put<IFolder>(
    `/api/engagements/${engagementGuid}/folders/${folderGuid}`,
    request
  );
}

export async function deleteEngagementFolder(
  this: ApiBase,
  engagementGuid: string,
  folderGuid: string
) {
  return this.server.delete(
    `/api/engagements/${engagementGuid}/folders/${folderGuid}`
  );
}

//
//
// Files
//
//

export async function updateEngagementFile(
  this: ApiBase,
  engagementGuid: string,
  documentGuid: string,
  request: IEngagementFileRequest
) {
  return this.server.put<IDirectoryFile>(
    `/api/engagements/${engagementGuid}/documents/${documentGuid}`,
    request,
    { transformResponse }
  );
}

export async function archiveEngagementFile(
  this: ApiBase,
  engagementGuid: string,
  documentGuids: string[],
  folderGuids: string[]
) {
  return this.server.post(
    `/api/engagements/${engagementGuid}/documents/archive`,
    {
      documentGuids,
      folderGuids
    },
    { transformResponse }
  );
}

export async function restoreEngagementFile(
  this: ApiBase,
  engagementGuid: string,
  documentGuids: string[],
  folderGuids: string[]
) {
  return this.server.post(
    `/api/engagements/${engagementGuid}/documents/restore`,
    {
      documentGuids,
      folderGuids
    },
    { transformResponse }
  );
}

export async function deleteEngagementFile(
  this: ApiBase,
  engagementGuid: string,
  status: number,
  documentGuids: string[],
  folderGuids: string[]
) {
  return this.server.post(
    `/api/engagements/${engagementGuid}/documents/delete`,
    {
      status,
      documentGuids,
      folderGuids
    },
    { transformResponse }
  );
}

export async function updateFileVisibility(
  this: ApiBase,
  documentGuids: string[],
  visibleToUserTypes: number,
  engagementGuid: string
) {
  return this.server.put<IDirectoryFile>(`/api/document/updatevisibility`, {
    documentGuids,
    visibleToUserTypes,
    engagementGuid
  });
}

//
//
// Batch Move, Link
//
//

export async function batchMoveFiles(
  this: ApiBase,
  engagementGuid: string,
  documentGuids: string[],
  folderGuids: string[],
  destinationFolder: FolderSelection
) {
  return this.server.post(`/api/engagements/${engagementGuid}/documents/move`, {
    documentGuids,
    folderGuids,
    folder: destinationFolder
  });
}

export async function batchLinkFiles(
  this: ApiBase,
  engagementGuid: string,
  documentGuids: string[],
  folderGuids: string[],
  destinationEngagementGuid: string,
  destinationFolder: FolderSelection
) {
  return this.server.post<{
    alreadyLinked: IDirectoryFile[] | null;
  }>(
    `/api/engagements/${engagementGuid}/documents/link`,
    {
      documentGuids,
      folderGuids,
      toengagementGuid: destinationEngagementGuid,
      folder: destinationFolder
    },
    { transformResponse }
  );
}

//
//
// Download
//
//

/**
 * POST create zip request
 */
export async function createZipRequest(
  this: ApiBase,
  params: IZipRequestParams
) {
  return this.server.post('/api/ziprequest', params);
}

//
//
// Link
//
//

export async function getAnyDocumentLinks(
  this: ApiBase,
  engagementGuid: string,
  folderAndEngagementDocumentGuids: { [key: string]: string[] }
) {
  return this.server.post(
    `/api/engagements/${engagementGuid}/check/links`,
    folderAndEngagementDocumentGuids
  );
}

export async function getFileLinks(
  this: ApiBase,
  params: IPagedDataParams & {
    engagementGuid: string;
    documentGuid: string;
  }
) {
  return this.server.get<IPagedDataResponse<IDirectoryItemLink>>(
    `/api/engagements/documents/${params.documentGuid}/links`,
    { params, transformResponse }
  );
}

export async function removeFileLinks(
  this: ApiBase,
  documentGuid: string,
  engagementDocumentLinkGuids: string[]
) {
  return this.server.put<boolean>(
    `/api/engagements/documents/${documentGuid}/removeLinks`,
    engagementDocumentLinkGuids,
    { transformResponse }
  );
}

//
// Fetch Data Requests & Tasks for Link To in Ad Hoc File Upload
//

export async function getDataRequestsAndTasks(
  this: ApiBase,
  engagementGuid: string
) {
  return this.server.get<IFileUploadLinkable[]>(
    `/api/file/${engagementGuid}/dataRequestsTask/search`,
    { transformResponse }
  );
}

//
// Fetch Folder Visibility info:
//

export async function getFolderUserVisibility(
  this: ApiBase,
  engagementGuid: string,
  folderGuid: string
) {
  return this.server.get<IFileFolderUserVisibility>(
    `/api/engagements/${engagementGuid}/folders/${folderGuid}/visibility`,
    { transformResponse }
  );
}

export async function updateFolderUserVisibility(
  this: ApiBase,
  engagementGuid: string,
  folderGuid: string,
  userGuids: string[]
) {
  return this.server.put(
    `/api/engagements/${engagementGuid}/folders/${folderGuid}/visibility`,
    userGuids
  );
}
