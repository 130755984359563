import React from 'react';
import { TFunction, WithTranslation, withTranslation } from 'react-i18next';

import { Icon, Tooltip } from '@ryan/components';

import { WithUser, withUser } from '../../../contexts/UserContext';
import { UserType } from '../../../interfaces';
import { IFileFolderUserVisibilityDirectoryItem } from '../../../interfaces/IFileFolderUserVisibility';
import { formatThirdPartyVisibility } from '../../../utils/formatThirdPartyVisibility';

interface FileFolderVisibilityTooltipProps extends WithUser, WithTranslation {
  iconName: string;
  isAtRoot: boolean;
  isRyanInternalFolder: boolean;
  visibilityDirectoryItem: IFileFolderUserVisibilityDirectoryItem;
  visibleToUserTypes: number;
}

// TODO: Refactor FileDirectory.tsx and SelectFolder.tsx to use this component
const FileFolderVisibilityTooltip: React.FC<
  FileFolderVisibilityTooltipProps
> = ({
  iconName,
  isAtRoot,
  isRyanInternalFolder,
  permissionService,
  t: getTextToDisplay,
  visibilityDirectoryItem,
  visibleToUserTypes
}) => {
  let clientUsers = '';
  if (visibilityDirectoryItem && visibilityDirectoryItem.users) {
    visibilityDirectoryItem.users
      .sort((a, b) => a.fullName.localeCompare(b.fullName))
      .forEach(({ fullName, userType }) => {
        if (userType === UserType.Client) {
          clientUsers = clientUsers ? `${clientUsers}, ${fullName}` : fullName;
        }
      });
  }

  let visibleToUserTypesString = '';
  if (
    !permissionService.isThirdParty() &&
    (visibleToUserTypes & UserType.ThirdParty) > 0
  ) {
    visibleToUserTypesString = formatThirdPartyVisibility(
      getTextToDisplay,
      visibleToUserTypes
    );
  }

  const hasRestrictedVisibility =
    visibilityDirectoryItem?.isFolderVisibilityRestricted ||
    visibleToUserTypesString.length > 0 ||
    isRyanInternalFolder;

  const clientAltText = getTextToDisplay(
    (isAtRoot && !isRyanInternalFolder) || !isRyanInternalFolder
      ? 'All'
      : 'None'
  );

  return (
    <>
      {hasRestrictedVisibility && (
        <Tooltip
          content={
            <>
              <label className="visibility-tooltip__header">
                {getTextToDisplay('folder.visibility')}
              </label>
              <br />
              <label className="visibility-tooltip__label">
                {getTextToDisplay(
                  'folder.setFolderVisibilityModal.ryanTeamMembers'
                )}
                :{' '}
              </label>
              {getTextToDisplay('All')}
              <br />
              <label className="visibility-tooltip__label">
                {getTextToDisplay(
                  'folder.setFolderVisibilityModal.clientTeamMembers'
                )}
                :{' '}
              </label>
              {clientUsers?.length > 0 && !isRyanInternalFolder
                ? clientUsers
                : clientAltText}
              <br />
              <label className="visibility-tooltip__label">
                {getTextToDisplay('Third Party')}:{' '}
              </label>
              {visibleToUserTypesString.length > 0 && !isRyanInternalFolder
                ? visibleToUserTypesString
                : getTextToDisplay('None')}
              <br />
            </>
          }
          renderTarget={({ open, ...props }) => (
            <button
              aria-expanded={open}
              aria-haspopup="true"
              className="file-directory__actions-visibility"
              {...props}
            >
              <Icon name={iconName} />
            </button>
          )}
        />
      )}
    </>
  );
};

export default withTranslation()(withUser(FileFolderVisibilityTooltip));
