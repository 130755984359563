export enum IconEnums {
  APPROVE = 'approve',
  CLIPBOARD_CHECK = 'clipboard-check',
  COMMENT = 'comment',
  COMMENT_ALERT = 'comment-alert',
  FILE_REFRESH = 'file-refresh',
  FOLDER_OPEN = 'folder-open',
  FOLDER_VISIBILITY = 'folder-visibility',
  LINK = 'link',
  PAPERCLIP = 'paperclip',
  PENCIL = 'pencil',
  PLUS = 'plus',
  SHOW = 'show',
  TIME = 'time',
  TRASH = 'trash',
  USER_CIRCLE = 'user-circle',
  USER_REASSIGN = 'user-reassign'
}
