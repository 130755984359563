import { makeTableCheckboxFilter } from '@ryan/components';

import { Status } from '../../../../interfaces';
import { StateActionEnums } from '../../../../routes/Worklist/WorklistMine/WorklistMine.utils';
import { ApiFailSafeEnums } from '../../../../utils/enums/ApiFailSafeEnums';
import * as CommonColumnEnums from '../CommonColumns/CommonColumns.enums';
import * as CommonColumnUtils from '../CommonColumns/CommonColumns.utils';
import * as ColumnsInterfaces from '../columns.interfaces';
import * as UseSingleProjectDataRequestEnums from './useSingleProjectDataRequestColumns.enums';
import * as UseSingleProjectDataRequestInterfaces from './useSingleProjectDataRequestColumns.interfaces';

const columnTemplate = [
  {
    ...CommonColumnUtils.commonColumnProps[CommonColumnEnums.EColumnId.TITLE],
    isCommonColumn: true
  },
  {
    ...CommonColumnUtils.commonColumnProps[
      CommonColumnEnums.EColumnId.DATA_TYPE
    ],
    filterActive: CommonColumnUtils.statusColumnFilterProps.filterActive,
    isCommonColumn: true
  },
  {
    ...CommonColumnUtils.commonColumnProps[CommonColumnEnums.EColumnId.STATUS],
    filterActive: CommonColumnUtils.statusColumnFilterProps.filterActive,
    isCommonColumn: true
  },
  {
    ...CommonColumnUtils.commonColumnProps[
      CommonColumnEnums.EColumnId.ASSIGNED_TO
    ],
    isCommonColumn: true
  },
  {
    id: UseSingleProjectDataRequestEnums.EColumnId.TRANSFERRED_FILES,
    label: UseSingleProjectDataRequestEnums.EColumnId.TRANSFERRED_FILES,
    sortable: true
  },
  {
    ...CommonColumnUtils.commonColumnProps[
      CommonColumnEnums.EColumnId.DUE_DATE
    ],
    isCommonColumn: true
  },
  {
    ...CommonColumnUtils.commonColumnProps[CommonColumnEnums.EColumnId.COMMENT],
    isCommonColumn: true
  },
  {
    ...CommonColumnUtils.commonColumnProps[CommonColumnEnums.EColumnId.ACTIONS],
    isCommonColumn: true
  }
] as ColumnsInterfaces.TColumnTemplateMapperProps['columnTemplate'];

export const getColumnTemplate: UseSingleProjectDataRequestInterfaces.IGetColumnTemplate =
  getTextToDisplayCallback => {
    const ROOT_TO_COMMON = 'table.columns.common';

    const formattedColumnTemplate = [...columnTemplate];

    const dataTypeColumnIndex = formattedColumnTemplate.findIndex(
      ({ id }) => id === UseSingleProjectDataRequestEnums.EColumnId.DATA_TYPE
    );
    const statusColumnIndex = formattedColumnTemplate.findIndex(
      ({ id }) => id === UseSingleProjectDataRequestEnums.EColumnId.STATUS
    );

    formattedColumnTemplate[dataTypeColumnIndex] = {
      ...formattedColumnTemplate[dataTypeColumnIndex],
      filter: makeTableCheckboxFilter(
        CommonColumnUtils.dataTypeColumnFilterProps.filterOptions.map(
          option => ({
            ...option,
            label: getTextToDisplayCallback(
              `${ROOT_TO_COMMON}.filter.${option.label}`
            )
          })
        )
      )
    };

    formattedColumnTemplate[statusColumnIndex] = {
      ...formattedColumnTemplate[statusColumnIndex],
      filter: makeTableCheckboxFilter(
        CommonColumnUtils.statusColumnFilterProps.filterOptions.map(option => ({
          ...option,
          label: getTextToDisplayCallback(
            `${ROOT_TO_COMMON}.filter.${option.label}`
          )
        }))
      )
    };

    return formattedColumnTemplate;
  };

export const getDataForCommonColumn: UseSingleProjectDataRequestInterfaces.IGetDataForCommonColumn =
  ({ callbacks = {}, columnType, item }) => {
    const dataForColumn = {
      columnType
    } as UseSingleProjectDataRequestInterfaces.TCommonColumnProps;

    switch (columnType) {
      case CommonColumnEnums.EColumnType.ACTIONS_FOR_DATA_REQUEST:
        dataForColumn[columnType] = {
          codeAssistance: item.codeAssistance,
          isEngagementReadOnly: item.isEngagementReadOnly,
          isMarkedAsDataDelivered: item.isMarkedAsDataDelivered,
          onMarkDataDelivered: callbacks.onMarkDataDelivered as () => void,
          onSetWorklistItemToState: stateAction => {
            (
              callbacks.onSetItemToState as (
                stateAction: StateActionEnums
              ) => void
            )(stateAction);
          },
          queueItemGuid: item.queueItemGuid,
          statusId: item.statusId,
          typeGuid: item.typeGuid
        };
        break;
      case CommonColumnEnums.EColumnType.ASSIGNED_TO:
        dataForColumn[columnType] = { assignedTo: item.assignedTo };
        break;
      case CommonColumnEnums.EColumnType.COMMENT:
        dataForColumn[columnType] = {
          commentCount: item.commentCount,
          isEngagementReadOnly: item.isEngagementReadOnly,
          onClick: callbacks.onDataRequestCommentClick as () => void
        };
        break;
      case CommonColumnEnums.EColumnType.DATA_TYPE:
        dataForColumn[columnType] = { dataType: item.dataType };
        break;
      case CommonColumnEnums.EColumnType.DUE_DATE:
        dataForColumn[columnType] = {
          dueDate: item.dueDate,
          isOverridePastDue: item.statusId === Status.Complete
        };
        break;
      case CommonColumnEnums.EColumnType.STATUS:
        dataForColumn[columnType] = {
          statusId: item.statusId,
          subStatusId: item.subStatusId
        };
        break;
      case CommonColumnEnums.EColumnType.TITLE_WITH_LINK:
        dataForColumn[columnType] = {
          href: `/app/data-request/${item.queueItemGuid}`,
          title: item.title
        };
        break;
      default:
    }

    return dataForColumn;
  };

export const getDataForDataRequestCommentClick: UseSingleProjectDataRequestInterfaces.IGetDataForDataRequestCommentClick =
  item => ({
    engagementDisplayNameShort: item.engagementDisplayNameShort,
    engagementGuid: item.engagementGuid,
    isEngagementReadOnly: item.isEngagementReadOnly,
    queueItemGuid: item.queueItemGuid,
    title: item.title,
    dataRequestType: { name: item.dataType }
  });

export const mapItemToSingleProjectDataRequest: UseSingleProjectDataRequestInterfaces.IMapItemToSingleProjectDataRequest =
  item => ({
    accountName: item.accountName || ApiFailSafeEnums.NAME,
    assignedTo: {
      name: item.assignedToName || ApiFailSafeEnums.NAME,
      userGuid: item.assignedToUserGuid || ApiFailSafeEnums.GUID
    },
    attachments: item.attachments || JSON.parse(ApiFailSafeEnums.LIST),
    codeAssistance: item.codeAssistance || ApiFailSafeEnums.NAME,
    commentCount: item.commentsCount || ApiFailSafeEnums.NUM,
    completedDate: item.completedDate || ApiFailSafeEnums.DATE,
    createdBy: {
      name: item.createdByName || ApiFailSafeEnums.NAME,
      userGuid: item.createdBy || ApiFailSafeEnums.GUID
    },
    createdDate: item.createDate || ApiFailSafeEnums.DATE,
    dataSpecs: item.dataSpecs || ApiFailSafeEnums.NAME,
    dataType: item.dataRequestTypeName || ApiFailSafeEnums.NAME,
    dateRanges: item.dateRange || JSON.parse(ApiFailSafeEnums.LIST),
    description: item.description || ApiFailSafeEnums.NAME,
    dataRequestType: item.dataRequestType,
    documentTypes: (
      item.documentTypes || JSON.parse(ApiFailSafeEnums.LIST)
    ).map(
      ({
        name,
        documentTypeGuid
      }: {
        name: string;
        documentTypeGuid: string;
      }) => ({
        name,
        documentTypeGuid
      })
    ),
    dueDate: item.dueDate || ApiFailSafeEnums.DATE,
    engagementDisplayNameLong:
      item.engagementDisplayNameLong || ApiFailSafeEnums.NAME,
    engagementDisplayNameShort:
      item.engagementDisplayNameShort || ApiFailSafeEnums.NAME,
    engagementGuid: item.engagementGuid || ApiFailSafeEnums.GUID,
    entityTypeId: item.entityTypeId || ApiFailSafeEnums.NUM,
    erpSystem: {
      erpSystemGuid: item.erpSystem?.erpSystemGuid || ApiFailSafeEnums.GUID,
      name: item.erpSystem?.name || ApiFailSafeEnums.NAME
    },
    followerCount: item.watcherCount || ApiFailSafeEnums.NUM,
    isEngagementReadOnly: Boolean(item.isEngagementReadOnly),
    isFollowing: Boolean(item.isCurrentUserWatching),
    isMarkedAsDataDelivered: Boolean(item.markAsDataDelivered),
    jurisdictions: (
      item.jurisdictions || JSON.parse(ApiFailSafeEnums.LIST)
    ).map(
      ({
        name,
        jurisdictionGuid
      }: {
        name: string;
        jurisdictionGuid: string;
      }) => ({
        name,
        jurisdictionGuid
      })
    ),
    otherDocumentType: item.otherDocumentType || ApiFailSafeEnums.NAME,
    otherErpSystem: item.otherErpSystem || ApiFailSafeEnums.NAME,
    otherTaxEngine: item.otherTaxEngine || ApiFailSafeEnums.NAME,
    propertyTaxDocumentTypes:
      item.propertyTaxDocumentTypes || JSON.parse(ApiFailSafeEnums.LIST),
    propertyTaxSiteClasses:
      item.propertyTaxSiteClasses || JSON.parse(ApiFailSafeEnums.LIST),
    queueItemGuid: item.queueItemGuid || ApiFailSafeEnums.GUID,
    statusGroupId: item.statusGroupId || ApiFailSafeEnums.NUM,
    statusId: item.statusId || ApiFailSafeEnums.STATUS_ID,
    subStatusId: item.subStatusId || ApiFailSafeEnums.STATUS_ID,
    taxEngine: {
      taxEngineGuid: item.taxEngine?.taxEngineGuid || ApiFailSafeEnums.GUID,
      name: item.taxEngine?.name || ApiFailSafeEnums.NAME
    },
    title: item.title || ApiFailSafeEnums.NAME,
    transferredFileCount: item.transferredFilesCount || ApiFailSafeEnums.NUM,
    typeGuid: item.dataRequestTypeGuid || ApiFailSafeEnums.GUID
  });
