import type { Locale } from 'date-fns';
import * as DateFnsLocales from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';

const VERSION = '1.74.1';

type LocaleKeys = keyof typeof DateFnsLocales;

export type Locales = { [key in LocaleKeys]: Locale };

interface ConstructedLocales {
  [key: string]: Locale;
}

/**
 * Constructs the datepicker locales object to be registered to the React Datepicker
 */
export const constructLocalesObj = (locales: Locales) => {
  return (Object.keys(locales) as LocaleKeys[]).reduce<ConstructedLocales>(
    (acc, curr) => ({
      ...acc,
      [`${locales[curr].code}`]: locales[curr]
    }),
    {}
  );
};

/**
 * All `date-fns` locales
 */
export const locales: ConstructedLocales = constructLocalesObj(DateFnsLocales);

/**
 * Register all date-fns locales to react-datepicker
 */
Object.keys(locales).forEach(locale => registerLocale(locale, locales[locale]));

/**
 * Set the default locale
 */
setDefaultLocale('en-US');

/**
 * Gets a date-fns Locale object given a locale key
 * @param localeKey a locale key
 * @returns a date-fns Locale object
 */
export const getLocale = (localeKey: string): Locale => {
  return (
    locales[localeKey] ||
    locales[localeKey.replace('-', '')] ||
    locales[localeKey.split('-')[0]] ||
    getLocale('en-US')
  );
};

/**
 * Returns an array of strings representing the possible date formats for the
 * given locale key.
 * This method is used in conjunction with the datepicker to allow users to
 * type in a specific date in the format of their chosen locale
 *
 * @param localeCode the locale key
 * @returns an array of date formats as strings
 */
export const parseDateFormat = (localeCode: string): string[] => {
  const locale = getLocale(localeCode);
  const format: string =
    locale.formatLong?.date({ width: 'short' }) || 'MM/dd/yyyy';
  const formatWithFullYear = format.replace(/y{1,4}/gi, 'yyyy');

  // strip special characters to support input of numbers only
  const formatWOCharacters = formatWithFullYear.replace(/[^\w]/gi, '');
  return [formatWithFullYear, formatWOCharacters];
};

/**
 * Returns a localized date format with the given locale code
 *
 * @param localeCode the locale key
 * @param tFxn a t() function for localization
 * @returns the localized date format
 */
export const getLocalizedDateFormat = (
  localeCode: string,
  t = i18n.t
): string => {
  const mutableLanguagesArr = i18n.languages?.slice();
  const [formatWithFullYear] = parseDateFormat(localeCode);

  // localization of format
  const dayInitial = i18n.exists('dateUnits.day')
    ? t('dateUnits.day').charAt(0)
    : 'd';
  const monthInitial = i18n.exists('dateUnits.month')
    ? t('dateUnits.month').charAt(0)
    : 'm';
  const yearInitial = i18n.exists('dateUnits.year')
    ? t('dateUnits.year').charAt(0)
    : 'y';

  // iterate over format and replace
  const localizedFormat = formatWithFullYear.split('').reduce((acc, curr) => {
    let char = '';

    switch (curr) {
      case 'd':
      case 'D':
        char = dayInitial;
        break;
      case 'm':
      case 'M':
        char = monthInitial;
        break;
      case 'y':
      case 'Y':
        char = yearInitial;
        break;
      default:
        char = curr;
    }

    // convert localized character to same case as original character
    if (curr === curr.toUpperCase()) {
      char = char.toLocaleUpperCase(mutableLanguagesArr);
    } else {
      char = char.toLocaleLowerCase(mutableLanguagesArr);
    }

    return acc + char;
  }, '');

  return localizedFormat;
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: {
      fr: ['fr-CA', 'en'],
      default: ['en']
    },
    detection: {
      // don't cache the browser language detection
      caches: []
    },
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        {
          expirationTime:
            process.env.NODE_ENV === 'development' ? 0 : 24 * 60 * 60 * 1000,
          defaultVersion: VERSION
        },
        {
          loadPath: '/locales/{{lng}}.json',
          queryStringParams: {
            version: VERSION
          }
        }
      ]
    },
    interpolation: {
      // not needed for React as it escapes by default
      escapeValue: false
    }
  });

export default i18n;
