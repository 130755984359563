import { ActivityType, ILearningSummary } from 'interfaces';

import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import { AbstractActivity, ActivityColor } from '../AbstractActivity';
import { ActivityModified } from '../ActivityModified';
import { IActivityProps } from '../IActivityProps';
import { UnrecognizedActivity } from '../UnrecognizedActivity';
import { CommentActivity } from './CommentActivity';

export const LearningActivity: FunctionComponent<IActivityProps> = props => {
  const {
    t,
    activity,
    isNotification,
    user,
    showAccountName,
    showEngagementName,
    onCloseNotificationDrawer
  } = props;

  const {
    activityTypeId,
    entityGuid,
    engagementGuid,
    createdBy,
    createdByName,
    snapShot: { entitySummary }
  } = activity;

  const { title } = entitySummary as ILearningSummary;
  const by = `by-${user.userGuid === createdBy ? 'you' : 'x'}`;

  switch (activityTypeId) {
    /**
     * Created
     */
    case ActivityType.LearningCreated:
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.LightOrange}
          ctaText={t('activity.learning.viewLearnings')}
          icon="bulb-plus"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.learning.created.title')}
          to={`/app/project/${engagementGuid}/learnings/${entityGuid}`}
        >
          <Trans i18nKey={`activity.learning.created.${by}`}>
            <b />
            {{ title }}
            {{ createdByName }}
          </Trans>{' '}
        </AbstractActivity>
      );

    /**
     * Edited
     */
    case ActivityType.LearningModified:
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.LightOrange}
          ctaText={t('activity.learning.viewLearnings')}
          icon="bulb-pencil"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.learning.edited.title')}
          to={`/app/project/${engagementGuid}/learnings/${entityGuid}`}
        >
          <Trans i18nKey={`activity.learning.edited.${by}`}>
            <b />
            {{ title }}
            {{ createdByName }}
          </Trans>{' '}
          <ActivityModified
            activity={activity}
            cases={[
              {
                key: 'Title',
                label: t('Title'),
                type: 'string' as const
              },
              {
                key: 'Description',
                label: t('Description'),
                type: 'string' as const,
                clamp: true
              }
            ]}
          />
        </AbstractActivity>
      );

    /**
     * Deleted
     */
    case ActivityType.LearningDeleted:
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.LightOrange}
          icon="bulb-delete"
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.learning.deleted.title')}
        >
          <Trans i18nKey={`activity.learning.deleted.${by}`}>
            <b />
            {{ title }}
            {{ createdByName }}
          </Trans>
        </AbstractActivity>
      );

    /**
     * Commented
     */
    case ActivityType.LearningComment:
    case ActivityType.LearningCommentModified: {
      const editedComment =
        activityTypeId === ActivityType.LearningCommentModified;

      return (
        <CommentActivity
          {...props}
          editedComment={editedComment}
          link={`/app/project/${engagementGuid}/learnings/${entityGuid}#comments`}
        >
          {mentionedYou => (
            <Trans
              i18nKey={`activity.learning.${
                editedComment ? 'commentEdited' : 'commented'
              }.${by}${mentionedYou ? '-mentioned-you' : ''}`}
            >
              <b />
              {{ title }}
              {{ createdByName }}
            </Trans>
          )}
        </CommentActivity>
      );
    }

    default:
      return <UnrecognizedActivity {...props} />;
  }
};
