import { RYAN_INTERNAL } from 'components/FileDirectory/utils/FileDirectoryEnums';

import React, {
  ChangeEvent,
  MouseEvent,
  PureComponent,
  createRef
} from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  Icon,
  TextInput,
  Tooltip
} from '@ryan/components';

import { WithUser, withUser } from '../../contexts/UserContext';
import {
  Feature,
  FolderSelection,
  IFolder,
  IFolderTree,
  Permission,
  UserType
} from '../../interfaces';
import { IFileFolderUserVisibilityDirectoryItem } from '../../interfaces/IFileFolderUserVisibility';
import _findFolder from '../../utils/findFolder';
import { formatThirdPartyVisibility } from '../../utils/formatThirdPartyVisibility';
import { sortFolders } from './sortFolders';

import './SelectFolder.scss';

interface ISelectFolderProps extends WithTranslation, WithUser {
  autoOpen?: boolean;
  createMode?: 'toggle' | 'always' | 'never';
  disabled?: boolean;
  /**
   * A list of folder GUIDs that should not be selectable ie. prevent a folder
   * from being moved within itself.
   */
  disabledFolders?: string[];
  feedback?: string | null;
  folders: IFolderTree[];
  invalid?: boolean;
  isInternalOnly?: boolean;
  label?: string;
  moveFileCount?: number;
  onChange: (folder: FolderSelection) => void;
  rootName: string;
  value: FolderSelection;
  isHideRyanInternal?: boolean;
}

interface ISelectFolderState {
  open: boolean;
  filteredFolders: IFolderTree[];
}

export class SelectFolder extends PureComponent<
  ISelectFolderProps,
  ISelectFolderState
> {
  static defaultProps = { createMode: 'toggle' as const };

  private ref = createRef<HTMLDivElement>();

  private wasMouseDowned = false;

  filterArchivedAndInternalFolders = (
    folderTree: IFolderTree
  ): IFolderTree | null => {
    if (folderTree.archiveDate) {
      return null;
    }
    if (
      this.props.isHideRyanInternal &&
      folderTree.folderVisibleToUserTypes === UserType.Ryan
    ) {
      return null;
    }

    const filteredChildFolders = folderTree.childFolders
      ?.map(this.filterArchivedAndInternalFolders)
      .filter((folder): folder is IFolderTree => folder !== null);

    return {
      ...folderTree,
      childFolders: filteredChildFolders
    };
  };

  constructor(props: ISelectFolderProps) {
    super(props);

    const { folders, createMode, autoOpen } = props;
    const open = createMode === 'always' || autoOpen === true;

    const filteredFolders = folders
      .map(this.filterArchivedAndInternalFolders)
      .filter((folderTree): folderTree is IFolderTree => folderTree !== null);

    this.state = { filteredFolders, open };
  }

  /**
   * If we started to create a new folder, focus and select on the input.
   * @todo assert updated `value` is a new folder if `createMode` is true
   */
  componentDidUpdate({
    folders: prevFolders,
    value: prevValue
  }: ISelectFolderProps) {
    const { folders, value } = this.props;
    if (
      value &&
      value.folderGuid === null &&
      (!prevValue || prevValue.folderGuid !== null) &&
      this.ref.current
    ) {
      const input = this.ref.current.querySelector('input');
      if (input) {
        input.focus();
        input.setSelectionRange(0, input.value.length);
      }
    }

    if (folders !== prevFolders) {
      const filteredFolders = folders
        .map(this.filterArchivedAndInternalFolders)
        .filter((folderTree): folderTree is IFolderTree => folderTree !== null);
      this.setState({ filteredFolders });
    }
  }

  findFolder(folderGuid: string) {
    const { filteredFolders: folders } = this.state;
    const match = _findFolder(folders, folderGuid);
    if (match) {
      return match.element;
    }

    throw new Error(`Could not find folder ${folderGuid} in the tree.`);
  }

  handleFocus = () => {
    this.wasMouseDowned = false;
    this.setState({ open: true });
  };

  handleMouseDown = () => {
    this.wasMouseDowned = true;
  };

  /**
   * Called on input blur.
   * If the blur was caused by a click on the component, we want
   * to reinstate focus on the input. Otherwise, close the options.
   */
  handleBlur = () => {
    if (this.wasMouseDowned) {
      this.wasMouseDowned = false;
      setTimeout(() => {
        if (this.ref.current) {
          const input = this.ref.current.querySelector('input');
          if (input) {
            input.focus();
          }
        }
      }, 0);
    }
  };

  handleToggle = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  /**
   * Go back to parent folder.
   * If we were creating a new folder, cancel.
   */
  handleBack = (e: MouseEvent) => {
    e.preventDefault();

    const { value, onChange, createMode } = this.props;
    let folder = value;

    // if creating a new folder
    if (folder !== null && folder.folderGuid === null) {
      // if new folder is at root
      if (folder.parentFolderGuid === null) {
        // if in create mode, do nothing
        // we don't even show the back button in this case, so this should never happen
        if (createMode === 'always') {
          return;
        }

        // cancel create, return to root
        onChange(null);
        return;
      }

      // get parent folder
      const parentFolder = this.findFolder(folder.parentFolderGuid);

      // if we're in create mode, take the new folder back
      if (createMode === 'always') {
        onChange({
          parentFolderGuid: parentFolder.parentFolderGuid,
          folderGuid: folder.folderGuid,
          folderName: folder.folderName,
          folderVisibleToUserTypes: folder.folderVisibleToUserTypes,
          visibleToUserTypes: folder.visibleToUserTypes
        });
        return;
      }

      // cancel create, operate as usual
      // the current folder is the new folder's parent folder 0_0
      folder = parentFolder;
    }

    // already at root (not in a folder), cannot go higher
    if (folder === null) {
      return;
    }

    // go to root
    if (folder.parentFolderGuid === null) {
      onChange(null);
      return;
    }

    // go to parent folder
    const parentFolder = this.findFolder(folder.parentFolderGuid);
    onChange({
      parentFolderGuid: parentFolder.parentFolderGuid,
      folderGuid: parentFolder.folderGuid,
      folderName: parentFolder.folderName,
      folderVisibleToUserTypes: parentFolder.folderVisibleToUserTypes,
      folderUserVisibility: parentFolder.folderUserVisibility,
      visibleToUserTypes: parentFolder.visibleToUserTypes
    });
  };

  /**
   * Drill to selected folder.
   * If we were creating a new folder, cancel.
   */
  handleSelect = (folder: IFolder) => (e: MouseEvent) => {
    e.preventDefault();

    const { createMode, value, onChange } = this.props;

    if (
      createMode === 'always' &&
      value !== null &&
      value.folderGuid === null
    ) {
      onChange({
        ...value,
        parentFolderGuid: folder.folderGuid
      });
      return;
    }

    onChange({
      parentFolderGuid: folder.parentFolderGuid,
      folderGuid: folder.folderGuid,
      folderName: folder.folderName,
      folderVisibleToUserTypes: folder.folderVisibleToUserTypes,
      folderUserVisibility: folder.folderUserVisibility,
      visibleToUserTypes: folder.visibleToUserTypes
    });
  };

  /**
   * Create a new folder named something at the current level.
   */
  handleCreate = () => {
    const { t, value, onChange } = this.props;
    onChange({
      parentFolderGuid: value ? value.folderGuid : null,
      folderGuid: null,
      folderName: t('New Folder'),
      folderVisibleToUserTypes: value ? value.folderVisibleToUserTypes : null,
      visibleToUserTypes: value ? value.visibleToUserTypes : null
    });
  };

  handleCreateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, onChange } = this.props;
    if (value !== null && value.folderGuid === null) {
      onChange({
        parentFolderGuid: value.parentFolderGuid,
        folderGuid: null,
        folderName: e.target.value,
        folderVisibleToUserTypes: value.folderVisibleToUserTypes,
        visibleToUserTypes: value.visibleToUserTypes
      });
    }
  };

  handleCreateCancel = (e: MouseEvent) => {
    e.preventDefault();

    const { value, onChange } = this.props;
    if (value && value.folderGuid === null) {
      if (value.parentFolderGuid === null) {
        onChange(null);
      } else {
        const folder = this.findFolder(value.parentFolderGuid);
        onChange({
          parentFolderGuid: folder.parentFolderGuid,
          folderGuid: folder.folderGuid,
          folderName: folder.folderName,
          folderVisibleToUserTypes: folder.folderVisibleToUserTypes,
          visibleToUserTypes: folder.visibleToUserTypes
        });
      }
    }
  };

  getSubFolders() {
    const { value: folder } = this.props;
    const { filteredFolders: folders } = this.state;
    const isCreating = folder !== null && folder.folderGuid === null;

    if (
      // at root
      folder === null ||
      // creating a new folder at root
      (isCreating && folder.parentFolderGuid === null)
    ) {
      return sortFolders(folders);
    }

    const parentFolderGuid = isCreating
      ? folder.parentFolderGuid
      : folder.folderGuid;
    const folderTree = this.findFolder(parentFolderGuid!);
    return sortFolders(folderTree.childFolders);
  }

  getParentFolderName() {
    const { rootName, value } = this.props;
    const isCreating = value !== null && value.folderGuid === null;

    if (
      // at root
      value === null ||
      // creating a new folder at root
      (isCreating && value.parentFolderGuid === null)
    ) {
      // we are at root, no parent
      return;
    }

    if (isCreating) {
      const parentFolder = this.findFolder(value.parentFolderGuid!);

      // if creating new folder,
      // the new folder's parent is the folder we show,
      // so it's parent is the name we're looking to return

      if (parentFolder.parentFolderGuid === null) {
        return rootName;
      }

      const parentParentFolder = this.findFolder(parentFolder.parentFolderGuid);
      return parentParentFolder.folderName;
    }
    // if parent is null, return rootName
    if (value.parentFolderGuid === null) {
      return rootName;
    }

    const parentFolder = this.findFolder(value.parentFolderGuid);
    return parentFolder.folderName;
  }

  getNewFolderParentFolderName() {
    const { rootName, value } = this.props;
    const { filteredFolders: folders } = this.state;
    if (folders && value !== null && value.folderGuid === null) {
      if (value.parentFolderGuid === null) {
        return rootName;
      }

      const parentFolder = this.findFolder(value.parentFolderGuid);
      return parentFolder.folderName;
    }
  }

  renderFileFolderVisibilityTooltip(
    visibilityDirectoryItem: IFileFolderUserVisibilityDirectoryItem,
    visibleToUserTypes: number,
    isRyanInternalFolder: boolean,
    isAtRoot: boolean
  ) {
    const { t: getTextToDisplay, permissionService } = this.props;

    let clientUsers = '';
    visibilityDirectoryItem?.users
      .sort((a, b) => a.fullName.localeCompare(b.fullName))
      .forEach(({ fullName, userType }) => {
        if (userType === UserType.Client) {
          clientUsers = clientUsers ? `${clientUsers}, ${fullName}` : fullName;
        }
      });

    let visibleToUserTypesString = '';
    if (
      !permissionService.isThirdParty() &&
      (visibleToUserTypes & UserType.ThirdParty) > 0
    ) {
      visibleToUserTypesString = formatThirdPartyVisibility(
        getTextToDisplay,
        visibleToUserTypes
      );
    }

    const hasRestrictedVisibility =
      visibilityDirectoryItem?.isFolderVisibilityRestricted ||
      visibleToUserTypesString.length > 0 ||
      isRyanInternalFolder;

    const clientAltText = getTextToDisplay(
      isAtRoot && !isRyanInternalFolder ? 'All' : 'None'
    );
    return (
      <>
        {hasRestrictedVisibility && (
          <Tooltip
            content={
              <>
                <label className="visibility-tooltip__header">
                  {getTextToDisplay('folder.visibility')}
                </label>
                <br />
                <label className="visibility-tooltip__label">
                  {getTextToDisplay(
                    'folder.setFolderVisibilityModal.ryanTeamMembers'
                  )}
                  :{' '}
                </label>
                {getTextToDisplay('All')} <br />
                <label className="visibility-tooltip__label">
                  {getTextToDisplay(
                    'folder.setFolderVisibilityModal.clientTeamMembers'
                  )}
                  :{' '}
                </label>
                {clientUsers?.length > 0 && !isRyanInternalFolder
                  ? clientUsers
                  : clientAltText}
                <br />
                <label className="visibility-tooltip__label">
                  {getTextToDisplay('Third Party')}:{' '}
                </label>
                {visibleToUserTypesString.length > 0 && !isRyanInternalFolder
                  ? visibleToUserTypesString
                  : getTextToDisplay('None')}
                <br />
              </>
            }
            renderTarget={({ open, ...props }) => (
              <Icon
                aria-expanded={open}
                aria-haspopup="true"
                name="folder-visibility"
                {...props}
              />
            )}
          />
        )}
      </>
    );
  }

  render() {
    const {
      createMode,
      disabled,
      disabledFolders,
      feedback,
      invalid,
      isInternalOnly,
      isFeatureToggled,
      label,
      moveFileCount,
      rootName,
      t: getTextToDisplay,
      value
    } = this.props;
    const { open } = this.state;
    const isCreating = value !== null && value.folderGuid === null;
    const isAtRoot =
      value === null || (isCreating && value.parentFolderGuid === null);
    const folderName = value ? value.folderName : rootName;
    const isInternalFilesFunctionalityVisible = isFeatureToggled(
      Feature.InternalFiles
    );

    return (
      <div className="select-folder" ref={this.ref}>
        <div className="select-folder__selection">
          <TextInput
            disabled={disabled}
            feedback={feedback || undefined}
            icon={open ? 'chevron-up' : 'chevron-down'}
            invalid={invalid}
            label={
              label ||
              getTextToDisplay(
                isCreating
                  ? 'selectFolder.labelNewFolder'
                  : 'selectFolder.label'
              )
            }
            onBlur={this.handleBlur}
            onChange={this.handleCreateChange}
            onFocus={this.handleFocus}
            onIconClick={this.handleToggle}
            placeholder={getTextToDisplay('selectFolder.label')}
            value={folderName}
          />
          {open && !disabled && (
            <div
              className="select-folder__selection__folder-explorer"
              onMouseDown={this.handleMouseDown}
            >
              <ul>
                {!isAtRoot &&
                  !(folderName === RYAN_INTERNAL && isInternalOnly) && (
                    <li>
                      <button onClick={this.handleBack} type="button">
                        <Icon name="chevron-left" />
                        <Trans i18nKey="selectFolder.backToFolder">
                          <b />
                          {{ folderName: this.getParentFolderName() }}
                        </Trans>
                      </button>
                    </li>
                  )}
                {isCreating && (
                  <li className="new-folder">
                    <button type="button">
                      <Icon
                        name={
                          value!.folderVisibleToUserTypes === UserType.Ryan &&
                          isInternalFilesFunctionalityVisible
                            ? 'folder-visibility'
                            : 'folder-open'
                        }
                      />
                      {value!.folderName}
                    </button>
                  </li>
                )}
                {this.getSubFolders()
                  .filter(folder => !folder.archiveDate)
                  .map(folder => {
                    const isVisibilityRestricted =
                      !!folder.folderUserVisibility
                        ?.isFolderVisibilityRestricted;
                    const isRyanInternal =
                      folder.visibleToUserTypes === UserType.Ryan;
                    const isThirdPartyVisibilitySet =
                      ((folder.visibleToUserTypes || 0) &
                        UserType.ThirdParty) !==
                      0;
                    const isCurrentUserThirdParty =
                      this.props.permissionService.isThirdParty();

                    return (
                      <li key={folder.folderGuid}>
                        <button
                          disabled={
                            disabledFolders &&
                            disabledFolders.indexOf(folder.folderGuid) > -1
                          }
                          onClick={this.handleSelect(folder)}
                          type="button"
                        >
                          {(isVisibilityRestricted ||
                            isThirdPartyVisibilitySet ||
                            isRyanInternal) &&
                          !isCurrentUserThirdParty ? (
                            this.renderFileFolderVisibilityTooltip(
                              folder.folderUserVisibility as IFileFolderUserVisibilityDirectoryItem,
                              folder.visibleToUserTypes,
                              isRyanInternal,
                              folder.parentFolderGuid === null
                            )
                          ) : (
                            <Icon
                              name={
                                folder.folderVisibleToUserTypes ===
                                  UserType.Ryan &&
                                isInternalFilesFunctionalityVisible
                                  ? 'folder-visibility'
                                  : 'folder-open'
                              }
                            />
                          )}
                          {folder.folderName}
                          <Icon name="chevron-right" />
                        </button>
                      </li>
                    );
                  })}
              </ul>
              <div className="select-folder__selection__folder-explorer__preview">
                {isCreating ? (
                  <Trans i18nKey="selectFolder.newFolderPreview">
                    <b />
                    {{ parentFolderName: this.getNewFolderParentFolderName() }}
                  </Trans>
                ) : (
                  <Trans count={moveFileCount} i18nKey="selectFolder.preview">
                    <b />
                    {{ folderName }}
                  </Trans>
                )}
              </div>
            </div>
          )}
        </div>
        {createMode === 'toggle' && (
          <Button
            {...(!isCreating && { icon: 'folder-add' })}
            disabled={
              disabled ||
              (!isCreating && !!value?.archiveDate) ||
              !this.props.permissionService.hasPermission(
                Permission.FilesContribute
              )
            }
            onClick={isCreating ? this.handleCreateCancel : this.handleCreate}
            size={EButtonSizes.SMALL}
            text={getTextToDisplay(
              `selectFolder.${isCreating ? 'newFolderCancel' : 'newFolder'}`
            )}
            variant={EButtonVariant.TEXT}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(withUser(SelectFolder));
