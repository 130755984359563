import Modal from 'components/Modal';
import UploadModalWarning from 'components/UploadModalWarning/UploadModalWarning';
import { WithUser, withUser } from 'contexts/UserContext';
import { FolderSelection, IEngagement } from 'interfaces';
import { useDSSManager } from 'utils/DSS';

import React, { FunctionComponent, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Message } from '@ryan/components';

import ApiService from '../../services/ApiService';
import FileRequestForm, { LinkToFilterType } from './FileRequestForm';

import './FilesUploadModal.scss';

export interface FileUploadModalProps extends WithTranslation, WithUser {
  engagement: IEngagement;
  defaultFolder: FolderSelection;
  onClose: (file: boolean) => void;
  open: boolean;
}

const FilesUploadModal: FunctionComponent<FileUploadModalProps> = ({
  t,
  defaultFolder,
  open,
  permissionService: ps,
  engagement,
  onClose: onCloseProp,
  user: currentUser
}) => {
  const [visibilityWarning, setVisibilityWarning] =
    React.useState<React.ReactElement>();
  const [linkToFilter, setLinkToFilter] = useState<LinkToFilterType>(
    LinkToFilterType.None
  );

  const dss = useDSSManager();

  const onClose = () => {
    onCloseProp(true);
    dss.clearUploads();
  };

  const checkTransferDestinationVisibility = async () => {
    if (!defaultFolder || !defaultFolder?.folderGuid || ps.isRyan()) {
      setVisibilityWarning(<></>);
      return;
    }

    const { data } = await ApiService.getFolderUserVisibility(
      engagement.engagementGuid,
      defaultFolder.folderGuid
    );
    if (!data.isFolderUserVisibilityRestricted) {
      return;
    }

    const isVisible = data.currentFolderVisibilityUsers.some(
      user => user.userGuid === currentUser?.profile.userGuid
    );
    if (!isVisible && linkToFilter !== LinkToFilterType.None) {
      setVisibilityWarning(
        <Message type="warning">
          {t('dataRequest.ryanUploadModal.destinationFolderNotVisible.content')}
        </Message>
      );
    } else {
      setVisibilityWarning(<></>);
    }
  };

  const handleFolderChange = (folder: FolderSelection) => {
    defaultFolder = folder;
    checkTransferDestinationVisibility().catch(error => {
      console.error('Error checking transfer destination visibility', error);
    });
  };

  return (
    <UploadModalWarning dss={dss} onClose={onClose}>
      {({ dss, warning, onEscape, onCancel }) => (
        <Modal
          className="files-upload-modal"
          onClose={onEscape}
          open={open}
          title={t('Upload Files')}
        >
          {warning}
          {visibilityWarning}
          <FileRequestForm
            defaultFolder={defaultFolder}
            dss={dss}
            engagementGuid={engagement.engagementGuid}
            isRyanUser={ps.isRyan()}
            linkToFilter={linkToFilter}
            onCancel={onCancel}
            onClose={onClose}
            onDefaultFolderChange={handleFolderChange}
            setLinkToFilter={setLinkToFilter}
          />
        </Modal>
      )}
    </UploadModalWarning>
  );
};

export default withTranslation()(withUser(FilesUploadModal));
