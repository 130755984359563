import { IFolderTree, UserType } from '../interfaces';
import makeTreeSearch from './makeTreeSearch';

const folderTreeSearch = makeTreeSearch<IFolderTree>(f => f.childFolders);

export const isFolderNameExist = (
  folderName: string,
  folders: IFolderTree[] | null,
  currentFolderGuid?: string | null,
  parentFolderGuid?: string | null
): {
  hasExistingName: boolean;
  isArchived: boolean;
  isFolderUserVisibilityRestricted: boolean;
} => {
  if (!folders)
    return {
      hasExistingName: false,
      isArchived: false,
      isFolderUserVisibilityRestricted: false
    };

  const normalizedFolderName = folderName.trim().toLowerCase();

  const result = folderTreeSearch(folders, folder => {
    return (
      folder.parentFolderGuid === parentFolderGuid &&
      folder.folderGuid !== currentFolderGuid &&
      folder.folderName.trim().toLowerCase() === normalizedFolderName
    );
  });

  if (result) {
    const isThirdPartyVisibilitySet =
      (result.element.visibleToUserTypes & UserType.ThirdParty) !== 0;
    return {
      hasExistingName: true,
      isArchived: !!result.element.archiveDate,
      isFolderUserVisibilityRestricted:
        !!result.element.folderUserVisibility?.isFolderVisibilityRestricted ||
        isThirdPartyVisibilitySet
    };
  }

  return {
    hasExistingName: false,
    isArchived: false,
    isFolderUserVisibilityRestricted: false
  };
};
