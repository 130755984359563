import DocumentTitle from 'components/DocumentTitle';
import UserInfoCard from 'components/UserInfoCard/UserInfoCard';
import { UserType } from 'interfaces';

import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import ContactInformation from './ContactInformation/ContactInformation';
import NotificationPreferencesCard from './NotificationPreferencesCard/NotificationPreferencesCard';
import SecurityCard from './Security/SecurityCard';
import useNotificationPreferences from './useNotificationPreferences';
import useProfileUser from './useProfileUser';

const Profile: FunctionComponent = () => {
  const { t } = useTranslation();

  const { profileUser, profileUserIsLoggedInUser, updateProfileUser } =
    useProfileUser();

  const {
    prefs,
    savingPrefs,
    onGroupEmailChecked,
    onPrefEmailChecked,
    onSavePrefs
  } = useNotificationPreferences();

  const notificationPreferencesCard = (
    <NotificationPreferencesCard
      onGroupEmailChecked={onGroupEmailChecked}
      onPrefEmailChecked={onPrefEmailChecked}
      onSavePrefs={onSavePrefs}
      prefs={prefs}
      savingPrefs={savingPrefs}
    />
  );

  return (
    <div className="profile-page">
      <DocumentTitle title={t('Profile')} />
      <h1 className="ry-h1">{t('Profile')}</h1>

      {/**
       * For Ryan users (cannot update contact info)
       */}
      {profileUser && profileUser.userTypeId === UserType.Ryan ? (
        <div className="row">
          <div className="col-lg-4">
            <UserInfoCard user={profileUser} />
            <SecurityCard />
          </div>
          <div className="col-lg-8">{notificationPreferencesCard}</div>
        </div>
      ) : (
        <>
          {/**
           * For Client and ThirdParty users
           */}
          <ContactInformation
            onSubmit={updateProfileUser}
            selectedUser={profileUser}
          />
          {profileUserIsLoggedInUser && (
            <div className="row">
              <div className="col-lg-8">{notificationPreferencesCard}</div>
              <div className="col-lg-4">
                <SecurityCard />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Profile;
