import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  Icon,
  Tooltip
} from '@ryan/components';

import AccountHierarchy from '../../components/AccountHierarchy/AccountHierarchy';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import DataRequests from '../../components/DataRequests/DataRequests';
import DocumentTitle from '../../components/DocumentTitle';
import sortMilestones from '../../components/Milestones/sortMilestones';
import ProjectDetailsConfirmPublishModal from '../../components/ProjectDetailsModals/ProjectDetailsConfirmPublishModal';
import ProjectDetailsEditModal from '../../components/ProjectDetailsModals/ProjectDetailsEditModal';
import ProjectDetailsPublishModal from '../../components/ProjectDetailsModals/ProjectDetailsPublishModal';
import ProjectDetailsSetupModal from '../../components/ProjectDetailsModals/ProjectDetailsSetupModal';
import ActivityEvents from '../../components/RecentActivityCard/ActivityEvents';
import TabsAndRoutes from '../../components/Tabs/TabsAndRoutes';
import {
  WithAmplitude,
  withAmplitude
} from '../../contexts/AmplitudeContext/AmplitudeConsumer';
import EngagementContext from '../../contexts/EngagementContext';
import { WithUser, withUser } from '../../contexts/UserContext';
import { IEngagement, MilestoneType, Permission } from '../../interfaces';
import ApiService, { CancelTokenSource } from '../../services/ApiService';
import {
  AmplitudeActionType,
  amplitudeEventDetail
} from '../../utils/amplitudeUtils/amplitudeUtils';
import { ProjectEditTypeEnums } from '../../utils/enums/ProjectEditTypeEnums';
import { formatDate } from '../../utils/formatDate';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import switcherDidUpdate from '../../utils/switcherDidUpdate';
import { ICustomViewOutboundLocationState } from '../CustomView';
import Learnings from './Learnings/Learnings';
import Overview from './Overview/Overview';
import ProjectFiles from './ProjectFiles/ProjectFiles';
import SavingsHistory from './SavingsHistory/SavingsHistory';
import Scheduling from './Scheduling/Scheduling';
import Tasks from './Tasks/Tasks';
import ProjectTeamPage from './Team/ProjectTeamPage';

import './Project.scss';

interface IProjectProps
  extends WithAmplitude,
    WithUser,
    RouteComponentProps<
      { engagementGuid: string },
      StaticContext,
      ICustomViewOutboundLocationState | unknown
    >,
    WithTranslation {}

// HACK: Pending component refactor to align with code guidelines
type TEngagementWithRestrictions = IEngagement & {
  isKovelAgreement: boolean;
  restrictOffshoreData: boolean;
  restrictOffshoreResources: boolean;
};

interface IProjectState {
  engagement: IEngagement | null;
  canManageEngagement: boolean;
  firstMilestoneStartDate: Date | null;
  lastMilestoneEndDate: Date | undefined;
  openProjectDetailsConfirmPublishModal: boolean;
  openProjectDetailsEditModal: boolean;
  openProjectDetailsSetupModal: boolean;
  openProjectDetailsPublishModal: boolean;
  editType: ProjectEditTypeEnums;
}

export class Project extends Component<IProjectProps, IProjectState> {
  private _isMounted = false;

  private source?: CancelTokenSource;

  private updateCancelToken?: CancelTokenSource;

  readonly state: IProjectState = {
    engagement: null,
    canManageEngagement: false,
    firstMilestoneStartDate: null,
    lastMilestoneEndDate: undefined,
    openProjectDetailsConfirmPublishModal: false,
    openProjectDetailsEditModal: false,
    openProjectDetailsSetupModal: false,
    openProjectDetailsPublishModal: false,
    editType: ProjectEditTypeEnums.GENERAL
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchProject();
  }

  async componentDidUpdate(prevProps: IProjectProps) {
    const { match, isEngagementInView } = this.props;
    const { engagementGuid } = match.params;
    const prevEngagementGuid = prevProps.match.params.engagementGuid;

    if (switcherDidUpdate(prevProps, this.props)) {
      // refresh cancel token
      this.updateCancelToken?.cancel();
      this.updateCancelToken = ApiService.CancelToken.source();

      try {
        const isInView = await isEngagementInView(
          engagementGuid,
          this.updateCancelToken.token
        );

        if (!isInView) {
          this.handleNotInView();
          return;
        }
      } catch (error) {
        // redirect if can't verify engagement is in view
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
          this.handleNotInView();
          return;
        }
      }
    }

    if (engagementGuid !== prevEngagementGuid) {
      this.fetchProject();
    }
  }

  componentWillUnmount() {
    // cancel ongoing requests
    this.source?.cancel();
    this.updateCancelToken?.cancel();
    this._isMounted = false;
  }

  fetchProject() {
    const { history, location } = this.props;

    // extract and update location state so it doesn't affect future renders;
    // we do not want to update the account switcher if redirecting back to this
    // page after updating a custom view
    let customViewUpdated = false;

    if (
      (location.state as ICustomViewOutboundLocationState)?.customViewUpdated
    ) {
      customViewUpdated = true;
      history.replace({
        ...history.location,
        state: {
          ...(history.location.state as Record<string, unknown>),
          customViewUpdated: false
        }
      });
    }

    this.setState(
      {
        engagement: null,
        canManageEngagement: false,
        openProjectDetailsEditModal: false,
        openProjectDetailsPublishModal: false
      },
      async () => {
        const {
          match: {
            params: { engagementGuid }
          },
          permissionService,
          isEngagementInView,
          setActiveAccountForEngagement
        } = this.props;

        // refresh cancel token
        this.source?.cancel();
        this.source = ApiService.CancelToken.source();

        try {
          // If redirecting back to this page after creating/editing a custom
          // view, verify engagement is still included in the updated custom
          // view and redirect back to projects overview if not.
          if (customViewUpdated) {
            const isInView = await isEngagementInView(
              engagementGuid,
              this.source.token
            );

            if (!isInView) {
              this.handleNotInView(true);
              return;
            }
          }
        } catch (error) {
          // redirect if can't verify engagement is in view
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
            this.handleNotInView(true);
            return;
          }
        }

        try {
          // fetch engagement data
          const [{ data: engagement }, canManageEngagement] = await Promise.all(
            [
              ApiService.getEngagement(engagementGuid, this.source.token),
              permissionService.canManageEngagement(engagementGuid)
            ]
          );

          // If the engagement is not a member of the view, we  probably came
          // here from a notification link or moving through history after a
          // switcher update and should update the view to one that includes
          // this engagement.
          if (!customViewUpdated) {
            setActiveAccountForEngagement(engagement);
          }

          if (this._isMounted) {
            this.setState({
              engagement,
              canManageEngagement
            });
          }
        } catch (error: any) {
          if (!ApiService.isCancel(error)) {
            // do not push server error toast if the user just doesn't have access
            if (error.response.status !== 403) {
              pushServerErrorToast();
            }
          }
        }
      }
    );
  }

  /**
   * Redirect from page if the view no longer contains the current engagement.
   *
   * @param replace Replace the current entry on the history stack on redirect.
   */
  handleNotInView = (replace?: boolean): void => {
    const { history } = this.props;
    const redirectTo = '/app/projects';

    if (replace) {
      history.replace(redirectTo);
    } else {
      history.push(redirectTo);
    }
  };

  /**
   * Edit Modal
   */

  handleProjectDetailsEditModalOpen =
    (editType: ProjectEditTypeEnums) => () => {
      this.setState({
        openProjectDetailsEditModal: true,
        editType
      });

      if (editType === ProjectEditTypeEnums.GENERAL) {
        const { engagementGuid } = this.props.match.params;
        ApiService.getMilestones(engagementGuid)
          .then(response => {
            const milestones = sortMilestones(
              response.data.filter(
                milestone => milestone.milestoneTypeId === MilestoneType.Interim
              )
            );

            this.setState({
              firstMilestoneStartDate: milestones[0]?.startDate,
              lastMilestoneEndDate: milestones?.at(-1)?.endDate
            });
          })
          .catch(error => {
            if (!ApiService.isCancel(error)) {
              pushServerErrorToast();
            }
          });
      }
    };

  handleProjectDetailsEditModalClose = () => {
    this.setState({ openProjectDetailsEditModal: false });
  };

  handleProjectDetailsEditModalSave = (data: IEngagement) => {
    ActivityEvents.emit();
    this.setState({ engagement: data, openProjectDetailsEditModal: false });
  };

  /**
   * Publish Modal
   */

  handleProjectDetailsPublishModalOpen = () => {
    this.setState({ openProjectDetailsPublishModal: true });
  };

  handleProjectDetailsPublishModalClose = () => {
    this.setState({ openProjectDetailsPublishModal: false });
  };

  handleProjectDetailsPublishModalSave = (data: IEngagement) => {
    ActivityEvents.emit();
    this.setState({ engagement: data, openProjectDetailsPublishModal: false });
  };

  handleEngagementRefresh = () => {
    ActivityEvents.emit();
    this.fetchProject();
  };

  /** @see IEngagementContext['refreshUpdateDate'] */
  refreshUpdateDate = (engagementGuid?: string) => {
    this.setState(prevState => ({
      engagement:
        prevState.engagement &&
        (!engagementGuid ||
          engagementGuid === prevState.engagement.engagementGuid)
          ? {
              ...prevState.engagement,
              updateDate: new Date().toISOString()
            }
          : prevState.engagement
    }));
  };

  render() {
    const { t, isAppReadOnly, match, permissionService: ps } = this.props;
    const {
      canManageEngagement,
      engagement,
      editType,
      firstMilestoneStartDate,
      lastMilestoneEndDate
    } = this.state;
    const { engagementGuid } = match.params;

    const engagementRestrictions = [
      ...((engagement as TEngagementWithRestrictions)?.isKovelAgreement
        ? [t('projectDetails.restrictions.kovel')]
        : []),
      ...((engagement as TEngagementWithRestrictions)?.restrictOffshoreData
        ? [t('projectDetails.restrictions.offshoreData')]
        : []),
      ...((engagement as TEngagementWithRestrictions)?.restrictOffshoreResources
        ? [t('projectDetails.restrictions.offshoreResources')]
        : [])
    ];

    return engagement === null ? (
      <div
        aria-busy="true"
        aria-label="Loading"
        className="project__loader"
        role="status"
      >
        <Icon className="loading-spin" name="loading" />
      </div>
    ) : (
      <EngagementContext.Provider
        value={{
          engagement,
          refreshEngagement: this.handleEngagementRefresh,
          refreshUpdateDate: this.refreshUpdateDate
        }}
      >
        <div className="project">
          <DocumentTitle title={engagement.engagementDisplayNameShort} />
          <Breadcrumb label={t('projects.link')} to="/app/projects" />
          <Breadcrumb
            label={engagement.engagementDisplayNameShort}
            to={`/app/project/${engagementGuid}`}
          />

          <h4 className="ry-h4">{engagement.accountName}</h4>
          <h1 className="ry-h1">
            {engagement.engagementDisplayNameLong}{' '}
            <AccountHierarchy hierarchy={engagement.accountHierarchy} />
          </h1>
          {ps.hasPermission(Permission.TimelinesView) && (
            <ul className="well project__well" data-testid="project-details">
              <li className="project__well-item">
                {/* Active or Inactive Pill */}
                {engagement.isActive ? (
                  <div className="pill active">{t('Active')}</div>
                ) : (
                  <div className="pill inactive">{t('Inactive')}</div>
                )}
                {/* Ghost Pill for ghosted users and read-only mode */}
                {(engagement.isUserGhosted || isAppReadOnly) && (
                  <Tooltip
                    content={t(
                      'projectDetails.readonly',
                      isAppReadOnly ? { context: 'executiveView' } : {}
                    )}
                    placement="top-start"
                    renderTarget={({ open, ...props }) => (
                      <button className="pill ghosted" type="button" {...props}>
                        <Icon name="hide" /> {t('Read only')}
                      </button>
                    )}
                  />
                )}
              </li>
              {engagement.bankruptcyFilingDate && (
                <li className="project__well-item">
                  <label className="ry-label">
                    {t('projectDetails.bankruptcyFiling')}
                  </label>
                  {formatDate(engagement.bankruptcyFilingDate)}
                </li>
              )}
              {/* Kickoff */}
              {engagement.alternateBeginDate && (
                <li className="project__well-item">
                  <label className="ry-label">
                    {t('projectDetails.kickoff')}
                  </label>
                  {formatDate(engagement.alternateBeginDate)}
                </li>
              )}
              {/* Estimated End */}
              {engagement.projectedEndDate && (
                <li className="project__well-item">
                  <label className="ry-label">
                    {engagement.isActive
                      ? t('projectDetails.estimatedEnd')
                      : t('projectDetails.completed')}
                  </label>
                  {formatDate(engagement.projectedEndDate)}
                </li>
              )}
              {/* Last Updated */}
              {engagement.updateDate && (
                <li className="project__well-item">
                  <label className="ry-label">
                    {t('projectDetails.lastUpdated')}
                  </label>
                  {formatDate(engagement.updateDate)}
                </li>
              )}
              {ps.isRyan() && engagementRestrictions.length > 0 && (
                <li className="project__well-item project__well-item--wrap">
                  <label className="ry-label">
                    {t('projectDetails.restrictions.title')}
                  </label>
                  <div className="project__well-item__restrictions">
                    {engagementRestrictions.join(', ')}
                  </div>
                </li>
              )}
              {/* Statute of Limitations/Statute Waiver Expiration */}
              {engagement.statuteOfLimitations && (
                <li className="project__well-item">
                  <label className="ry-label">
                    {t('Statute Waiver Expiration')}
                  </label>
                  {formatDate(engagement.statuteOfLimitations)}
                </li>
              )}
              {/* Code - Ryan only */}
              {ps.isRyan() && (
                <li className="project__well-item">
                  <label className="ry-label">{t('Code')}</label>
                  {engagement.engagementId}
                </li>
              )}
              {ps.hasPermission(Permission.TimelinesEdit) &&
                this.renderEditButton()}
            </ul>
          )}

          <TabsAndRoutes
            onTabChangeHandler={destinationRoute => {
              if (
                destinationRoute === `/app/project/${engagementGuid}/learnings`
              ) {
                this.props.initializeEventToTrack({
                  eventAction: AmplitudeActionType.CLICK,
                  eventName: amplitudeEventDetail.ryanLearnings.eventName
                });
              }
            }}
            tabs={[
              {
                label: t('Overview'),
                to: `/app/project/${engagementGuid}/overview`,
                path: [
                  '/app/project/:engagementGuid/overview/milestones/:engagementMilestoneGuid',
                  '/app/project/:engagementGuid/overview'
                ],
                render: (props: RouteComponentProps) => (
                  <Overview
                    {...props}
                    engagement={engagement}
                    onEditProjectDetails={
                      this.handleProjectDetailsEditModalOpen
                    }
                    onEngagementUpdate={this.handleEngagementRefresh}
                  />
                )
              },
              {
                label: t('Data Requests'),
                to: `/app/project/${engagementGuid}/data-requests`,
                path: '/app/project/:engagementGuid/data-requests',
                enabled: ps.hasPermission(Permission.DataRequestsView),
                render: (props: RouteComponentProps) => (
                  <DataRequests {...props} engagement={engagement} />
                )
              },
              {
                label: t('Files'),
                to: `/app/project/${engagementGuid}/files`,
                path: '/app/project/:engagementGuid/files',
                enabled: ps.hasPermission(Permission.FilesRead),
                render: (props: RouteComponentProps) => (
                  <ProjectFiles {...props} engagement={engagement} />
                )
              },
              {
                label: t('Tasks'),
                to: `/app/project/${engagementGuid}/tasks`,
                path: '/app/project/:engagementGuid/tasks/:taskGuid?',
                enabled: ps.hasPermission(Permission.TasksView),
                render: (props: RouteComponentProps<any>) => (
                  <Tasks {...props} engagement={engagement} />
                )
              },
              {
                label: t('Scheduling'),
                to: `/app/project/${engagementGuid}/scheduling`,
                path: '/app/project/:engagementGuid/scheduling',
                enabled:
                  // display Scheduling tab if:
                  // 1. user has scheduling permission
                  // 2. user is Ryan employee
                  // 3. engagement parent practice area is supports the
                  //    scheduling tab (this will be determined in backend and
                  //    passed via `schedulingTabEnabled` field)
                  ps.isRyan() &&
                  ps.hasPermission(Permission.SchedulingBasic) &&
                  engagement.schedulingTabEnabled,
                render: (props: RouteComponentProps) => (
                  <Scheduling {...props} engagement={engagement} />
                )
              },
              {
                label: t('Savings'),
                to: `/app/project/${engagementGuid}/savings-history`,
                path: '/app/project/:engagementGuid/savings-history',
                enabled: ps.hasPermission(Permission.SavingsSummaryView),
                render: (props: RouteComponentProps<any>) => (
                  <SavingsHistory {...props} engagement={engagement} />
                )
              },
              {
                label: t('Learnings'),
                to: `/app/project/${engagementGuid}/learnings`,
                path: '/app/project/:engagementGuid/learnings/:learningGuid?',
                enabled: ps.hasPermission(Permission.LearningsView),
                render: (props: RouteComponentProps<any>) => (
                  <Learnings {...props} engagement={engagement} />
                )
              },
              {
                label: t('Team'),
                to: `/app/project/${engagementGuid}/team`,
                path: '/app/project/:engagementGuid/team',
                render: (props: RouteComponentProps<any>) => (
                  <ProjectTeamPage
                    {...props}
                    canManageEngagement={canManageEngagement}
                    engagement={engagement}
                  />
                )
              }
            ]}
          />

          {this.state.openProjectDetailsEditModal && (
            <ProjectDetailsEditModal
              editType={editType}
              engagement={engagement}
              firstMilestoneStartDate={firstMilestoneStartDate}
              lastMilestoneEndDate={lastMilestoneEndDate}
              onClose={this.handleProjectDetailsEditModalClose}
              onSave={this.handleProjectDetailsEditModalSave}
              open={this.state.openProjectDetailsEditModal}
            />
          )}

          {this.state.openProjectDetailsPublishModal && (
            <ProjectDetailsPublishModal
              engagement={engagement}
              onClose={this.handleProjectDetailsPublishModalClose}
              onSave={this.handleProjectDetailsPublishModalSave}
              open={this.state.openProjectDetailsPublishModal}
            />
          )}

          {this.state.openProjectDetailsSetupModal && (
            <ProjectDetailsSetupModal
              engagement={engagement}
              onClose={() => {
                this.setState({ openProjectDetailsSetupModal: false });
              }}
              onGoToEngagementContractsFolder={() => {
                const { history } = this.props;
                const { engagement } = this.state;

                history.push(
                  `/app/data-and-files/contracts#accountGuid=${
                    engagement!.accountGuid
                  }&engagementGuid=${engagement!.engagementGuid}`
                );
              }}
            />
          )}

          {this.state.openProjectDetailsConfirmPublishModal && (
            <ProjectDetailsConfirmPublishModal
              engagement={engagement}
              onClose={shouldPublish => {
                if (shouldPublish) {
                  this.setState({
                    openProjectDetailsConfirmPublishModal: false,
                    openProjectDetailsPublishModal: true
                  });
                } else {
                  this.setState({
                    openProjectDetailsConfirmPublishModal: false
                  });
                }
              }}
            />
          )}
        </div>
      </EngagementContext.Provider>
    );
  }

  renderEditButton() {
    const { t, isAppReadOnly } = this.props;
    const { engagement } = this.state;
    const isReadOnly = !!engagement?.isReadOnly || isAppReadOnly;

    return (
      <div className="project__well-item project__well-item__button">
        <Button
          disabled={isReadOnly}
          onClick={this.handleProjectDetailsEditModalOpen(
            ProjectEditTypeEnums.GENERAL
          )}
          size={EButtonSizes.SMALL}
          text={t('projectDetails.editInfo')}
          variant={EButtonVariant.SECONDARY}
        />
      </div>
    );
  }
}

export default withAmplitude(withUser(withTranslation()(Project)));
