import camelCase from 'lodash/camelCase';
import * as yup from 'yup';

import { withFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  TextInput
} from '@ryan/components';

import Dropdown from '../../../../components/Dropdown/Dropdown';
import { withUser } from '../../../../contexts/UserContext';
import { IAccount, IEngagementSummary } from '../../../../interfaces';
import ApiService from '../../../../services/ApiService';
import {
  formikAutocompleteAjaxProps,
  formikFieldProps
} from '../../../../utils/forms';
import pushServerErrorToast from '../../../../utils/pushServerErrorToast';
import {
  AccountAutocomplete,
  EngagementAutocomplete
} from '../../../AutocompleteAjax';
import {
  IDataRequestFormCombinedProps,
  IDataRequestFormProps,
  IDataRequestFormValues,
  IDataRequestType
} from './utils';

import './DataRequestForm.scss';

const DataRequestInnerForm: React.FC<IDataRequestFormCombinedProps> = props => {
  const formRef = useRef<HTMLFormElement>(null);

  const [dataRequestFetching, setDataRequestFetching] = useState(false);
  const [engagementFetching, setEngagementFetching] = useState(false);
  const hasFetchedRef = useRef(false);
  const [otherRequestTypeGuid, setOtherRequestTypeGuid] = useState<
    string | null
  >(null);

  const {
    account,
    dataRequestType,
    dataRequestTypes,
    engagement,
    engagementGuid,
    errors,
    handleSubmit,
    isAccountSelect,
    otherDrType,
    setAccount,
    setFieldValue,
    setDataRequestTypes,
    setDataRequestType,
    setEngagement,
    setOtherDrType,
    setValues,
    t: getTextToDisplay,
    touched,
    values
  } = props;

  const selectedRequestType = values.selectedRequestType;
  const isOtherRequestTypeSelected =
    selectedRequestType === otherRequestTypeGuid;

  const fetchEngagementValues = useCallback(
    async (engagementGuid: string) => {
      setEngagementFetching(true);
      try {
        const response = await ApiService.getEngagement(engagementGuid);
        const engagement = response.data;

        setFieldValue('engagement', engagement);
        setEngagement(engagement);
      } catch (error) {
        pushServerErrorToast();
      } finally {
        setEngagementFetching(false);
      }
    },
    [setFieldValue, setEngagement]
  );

  const fetchDataRequestTypes = useCallback(async () => {
    setDataRequestFetching(true);

    if (!dataRequestTypes.length) {
      try {
        const response = await ApiService.getDataRequestTypes();
        const requestTypesData = response.data as unknown as IDataRequestType[];

        const requestTypes = requestTypesData.map((type: IDataRequestType) => ({
          value: type.dataRequestTypeGuid,
          label: getTextToDisplay(
            `dataRequest.dataTypes.${camelCase(type.name.replaceAll(' ', ''))}`
          )
        }));

        const otherType = requestTypes.find(type => type.label === 'Other');
        setOtherRequestTypeGuid(otherType?.value ?? null);
        setFieldValue('otherRequestTypeGuid', otherType?.value ?? null);
        setDataRequestTypes(requestTypesData);

        setFieldValue('requestTypes', requestTypes, false);
      } catch (error) {
        setFieldValue('requestTypes', [], false);
        pushServerErrorToast();
      }
    } else {
      const requestTypes = dataRequestTypes.map(type => ({
        value: type.dataRequestTypeGuid,
        label: getTextToDisplay(
          `dataRequest.dataTypes.${camelCase(type.name.replaceAll(' ', ''))}`
        )
      }));
      const otherType = requestTypes.find(
        type => getTextToDisplay(`dataRequest.dataTypes.other`) === type.label
      );
      setOtherRequestTypeGuid(otherType?.value ?? null);
      setFieldValue('otherRequestTypeGuid', otherType?.value ?? null);
      setFieldValue('requestTypes', requestTypes, false);
    }
    setDataRequestFetching(false);
  }, [dataRequestTypes, getTextToDisplay, setDataRequestTypes, setFieldValue]);

  useEffect(() => {
    if (isAccountSelect && account) {
      setFieldValue('account', account);
    }

    if (engagement) {
      setFieldValue('engagement', engagement);
    }

    if (dataRequestType) {
      setFieldValue('selectedRequestType', dataRequestType);
    }
  }, [account, engagement, dataRequestType, isAccountSelect, setFieldValue]);

  useEffect(() => {
    if (otherDrType) {
      setFieldValue('otherRequestType', otherDrType);
    }
  }, [engagement, otherDrType, setFieldValue]);

  useEffect(() => {
    if (engagementGuid && !hasFetchedRef.current) {
      hasFetchedRef.current = true;
      fetchEngagementValues(engagementGuid);
    }
  }, [engagementGuid, fetchEngagementValues]);

  useEffect(() => {
    fetchDataRequestTypes();
  }, [fetchDataRequestTypes]);

  const handleAccountChange = (option: IAccount | null) => {
    const { touched, setTouched } = props;

    setValues({
      ...values,
      account: option,
      engagement: null
    });
    setAccount(option);

    const { engagement, ...otherInputsTouched } = touched;
    setTouched(otherInputsTouched);
  };

  const handleEngagementChange = (
    option: Omit<IEngagementSummary, 'clientName'> | null
  ) => {
    setValues({
      ...values,
      engagement: option
    });
    setEngagement(option);
  };

  const handleDropdownChange = (type?: string | null) => {
    setFieldValue('selectedRequestType', type, true);
    setDataRequestType(type || '');

    if (type !== otherRequestTypeGuid) {
      setFieldValue('otherRequestType', '', false);
    }
  };

  const handleOtherTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setOtherDrType(value);
    setFieldValue('otherRequestType', value);
  };

  const handleFormSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    handleSubmit();
  };

  const getRequestTypeLabel = (value: string): string | null => {
    const item = values.requestTypes.find(item => item.value === value);
    return item ? item.label : null;
  };

  const dropdownProps = {
    ...formikFieldProps('selectedRequestType', props),
    error: errors.selectedRequestType,
    id: 'dataRequestType',
    isDisabled: dataRequestFetching,
    label: getTextToDisplay('modal.dataRequestModal.fields.requestType.label'),
    dropdownOptions: [...values.requestTypes] || [],
    onChange: handleDropdownChange,
    touched: touched.selectedRequestType,
    value: {
      label: getRequestTypeLabel(selectedRequestType),
      value: selectedRequestType || ''
    }
  };

  return (
    <>
      {props.status.error}
      <h2 className="step-title">
        {isAccountSelect &&
          getTextToDisplay('modal.dataRequestModal.steps.step-1b.title')}
        {!isAccountSelect &&
          getTextToDisplay('modal.dataRequestModal.steps.step-1.title')}
      </h2>
      <form autoComplete="off" className="data-request-form" ref={formRef}>
        {isAccountSelect && (
          <AccountAutocomplete
            {...formikAutocompleteAjaxProps('account', props)}
            isOnlyActiveEngagements
            label={getTextToDisplay(
              'modal.dataRequestModal.fields.account.label'
            )}
            onChange={handleAccountChange}
            value={values.account}
          />
        )}

        <EngagementAutocomplete
          {...formikAutocompleteAjaxProps('engagement', props)}
          {...(isAccountSelect && {
            accountGuid: values.account?.accountGuid || null
          })}
          {...(!isAccountSelect && { customViewGuid: props.customViewGuid })}
          disabled={
            (isAccountSelect && values.account === null) ||
            engagementGuid !== undefined ||
            engagementFetching
          }
          isShowAccountName
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.engagement.label'
          )}
          onChange={handleEngagementChange}
        />
        <Dropdown {...dropdownProps} />
        {isOtherRequestTypeSelected && (
          <TextInput
            {...formikFieldProps('otherRequestType', props)}
            feedback={errors.otherRequestType}
            invalid={touched.otherRequestType && !!errors.otherRequestType}
            label={getTextToDisplay(
              'modal.dataRequestModal.fields.otherRequestType.label'
            )}
            onChange={e => handleOtherTypeChange(e)}
          />
        )}
        <ButtonGroup>
          <Button
            loading={props.status.loading}
            onClick={e => handleFormSubmit(e)}
            text={getTextToDisplay('modal.dataRequestModal.next')}
            type="submit"
            variant={EButtonVariant.PRIMARY}
          />
        </ButtonGroup>
      </form>
    </>
  );
};

const defaultValues: IDataRequestFormValues = {
  account: null,
  engagement: null,
  requestTypes: [],
  selectedRequestType: '',
  otherRequestType: '',
  otherRequestTypeGuid: ''
};

export default withTranslation()(
  withFormik<IDataRequestFormProps, IDataRequestFormValues>({
    mapPropsToValues: () => defaultValues,

    mapPropsToStatus: () => ({
      loading: null
    }),

    validationSchema: (
      props: IDataRequestFormProps & IDataRequestFormValues
    ) => {
      const { isAccountSelect, t: getTextToDisplay } = props;
      return yup.object().shape({
        account: yup.object().when([], {
          is: () => isAccountSelect,
          then: yup
            .object()
            .nullable()
            .required(
              getTextToDisplay('modal.dataRequestModal.fields.account.required')
            ),
          otherwise: yup.object().nullable()
        }),
        engagement: yup.object().when('account', {
          is: account => {
            return !isAccountSelect || (isAccountSelect && Boolean(account));
          },
          then: yup
            .object()
            .nullable()
            .required(
              getTextToDisplay(
                'modal.dataRequestModal.fields.engagement.required'
              )
            ),
          otherwise: yup.object().nullable()
        }),
        selectedRequestType: yup
          .string()
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.requestType.required'
            )
          ),
        otherRequestType: yup.string().when('selectedRequestType', {
          is: (selectedRequestType: string) =>
            selectedRequestType && selectedRequestType.length,
          then: yup
            .string()
            .test(
              'validate-other-request-type',
              getTextToDisplay(
                'modal.dataRequestModal.fields.otherRequestType.required'
              ),
              function (value) {
                const { selectedRequestType, otherRequestTypeGuid } =
                  this.parent;
                if (selectedRequestType === otherRequestTypeGuid) {
                  return !!value;
                }
                return true;
              }
            )
            .max(
              50,
              getTextToDisplay(
                'modal.dataRequestModal.fields.otherRequestType.maxLength',
                { count: 50 }
              )
            ),
          otherwise: yup.string().nullable()
        })
      });
    },

    handleSubmit: async (values, formik) => {
      formik.props.onNextStepClick(2);
    }
  })(withUser(DataRequestInnerForm))
);
