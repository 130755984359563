import { CancelToken } from 'axios';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { EMessageTypes, Table, pushToast } from '@ryan/components';

import Empty from '../../../components/Empty';
import FollowersModal from '../../../components/FollowersModal/FollowersModal';
import DataRequestModals from '../../../components/Modal/DataRequestModals/DataRequestModals';
import * as DataRequestModalsEnums from '../../../components/Modal/DataRequestModals/DataRequestModals.enums';
import useWorklistActions from '../../../components/Table/Actions/Worklist/useWorklistActions';
import useWorkListColumns from '../../../components/Table/Columns/Worklist/useWorkListColumns';
import * as UseWorkListColumnsEnums from '../../../components/Table/Columns/Worklist/useWorkListColumns.enums';
import * as UseWorkListColumnsInterfaces from '../../../components/Table/Columns/Worklist/useWorkListColumns.interfaces';
import {
  TStatusFilters,
  TTypeFilters
} from '../../../components/Table/Columns/Worklist/useWorkListColumns.interfaces';
import DataRequestExpandedRow, {
  mapToDataRequestExpandedRow
} from '../../../components/Table/ExpandedRow/Worklist/DataRequestExpandedRow/DataRequestExpandedRow';
import TaskExpandedRow, {
  mapToTaskExpandedRow
} from '../../../components/Table/ExpandedRow/Worklist/TaskExpandedRow/TaskExpandedRow';
import TableEmpty from '../../../components/TableEmpty/TableEmpty';
import { useAmplitude } from '../../../contexts/AmplitudeContext/AmplitudeConsumer';
import { useDataRequestDrawer } from '../../../contexts/DataRequestDrawerContext';
import { useTaskDrawer } from '../../../contexts/TaskDrawerContext';
import { useUser } from '../../../hooks';
import {
  EntityType,
  IDataRequestV2,
  ITableState,
  ITask,
  Status
} from '../../../interfaces';
import ApiService, { CancelTokenSource } from '../../../services/ApiService';
import {
  AmplitudeActionType,
  amplitudeEventDetail
} from '../../../utils/amplitudeUtils/amplitudeUtils';
import debouncedSearch from '../../../utils/debouncedSearch';
import { SubStatusEnums } from '../../../utils/enums/SubStatusEnums';
import getSortParam from '../../../utils/getSortParm';
import { isPastDue } from '../../../utils/isPastDue';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import TaskModals from '../TaskModals/TaskModals';
import * as TaskModalsEnums from '../TaskModals/TaskModals.enums';
import * as WorklistMineInterfaces from './WorklistMine.interfaces';
import * as WorklistMineUtils from './WorklistMine.utils';

import './WorklistMine.scss';

const WorklistMine = () => {
  const ROOT_TO_TEXT = 'worklistPage.openTab';
  const location = useLocation();
  const activeTab = useMemo(
    () => location.pathname.split('/').filter(Boolean).pop() || 'mine',
    [location.pathname]
  );
  const FILTERS_SESSION_STORAGE = useMemo(
    () => `WORKLIST_FILTERS_SET_${activeTab}`,
    [activeTab]
  );

  const { triggerAmplitudeEvent } = useAmplitude();

  const {
    dataRequestDrawerEvents,
    onDataRequestDrawerOpenComments,
    onDataRequestDrawerOpenHistory
  } = useDataRequestDrawer();
  const { onTaskDrawerOpen, taskDrawerEvents } = useTaskDrawer();
  const { t: getTextToDisplay } = useTranslation();
  const {
    user: { profile: activeUserProfile },
    permissionService
  } = useUser();

  const storedSelectedFiltersParsed = useMemo(() => {
    const storedSelectedFilters = JSON.parse(
      window.sessionStorage.getItem(FILTERS_SESSION_STORAGE) ?? '{}'
    );
    return Object.keys(storedSelectedFilters).length === 0
      ? { entityType: [], status: [] }
      : storedSelectedFilters;
  }, [FILTERS_SESSION_STORAGE]);

  const sourceRef = useRef<CancelTokenSource>();
  const [assignmentToOpenInModal, setAssignmentToOpenInModal] =
    useState<WorklistMineInterfaces.TWorklistItem | null>(null);
  const [assignmentToUpdateFollowers, setAssignmentToUpdateFollowers] =
    useState<WorklistMineInterfaces.TWorklistItem | null>(null);
  const [expandedRows, setExpandedRows] = useState<{
    [rowId: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingFollowStatus, setIsUpdatingFollowStatus] = useState(false);
  const [modalAction, setModalAction] = useState<
    DataRequestModalsEnums.EAction | TaskModalsEnums.EAction | null
  >(null);
  const [pageView, setPageView] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [searchQuery, setSearchQuery] = useState('');
  const [sorted, setSorted] = useState<ITableState['sorted']>({
    id: UseWorkListColumnsEnums.WorklistColumnIdEnums.DUE_DATE,
    desc: false
  });
  const [filters, setFilters] = useState<ITableState['filtered']>(
    storedSelectedFiltersParsed
  );
  const [typeFilters, setTypeFilters] = useState<
    UseWorkListColumnsInterfaces.TTypeFilters[]
  >([]);
  const [statusFilters, setStatusFilters] = useState<
    UseWorkListColumnsInterfaces.TStatusFilters[]
  >([]);
  const [totalResults, setTotalResults] = useState(0);
  const [worklist, setWorklist] = useState<
    WorklistMineInterfaces.TWorklistItem[]
  >([]);

  const getTabKey = useCallback(() => {
    const isRyan = permissionService.isRyan();
    switch (activeTab) {
      case 'my-team':
        return isRyan ? 'ryan' : 'client';
      default:
        return activeTab;
    }
  }, [permissionService, activeTab]);

  const getWorklistAssignments = useCallback(
    async (cancelToken: CancelToken) => {
      const { data } = await ApiService.getWorklistAssignments(cancelToken, {
        filterEntityTypes: JSON.stringify(filters?.entityType ?? []),
        filterStatusTypes: JSON.stringify(filters?.status ?? []),
        itemsPerPage: pageSize,
        pageNumber: pageView,
        searchTerm: searchQuery,
        sort: getSortParam(sorted),
        activeTabKey: getTabKey()
      });

      const mappedWorklist =
        WorklistMineUtils.mapGetWorklistAssignmentsResponseToWorklist(
          data.results
        );

      if (typeFilters.length === 0 && statusFilters.length === 0) {
        // Remove any filter entities/statuses from storage that don't exist in current worklist result set
        // in case if User's permissions are changed and loses access to DRs/Tasks but had them selected previously OR
        // had tasks/DRs they completed that leave their Worklist, but still filter selected in storage
        // also for user impersonation across sessions
        if (data.filterTypes?.length > 0) {
          for (
            let i = 0;
            i < storedSelectedFiltersParsed.entityType.length;
            i++
          ) {
            const storedEntityType = storedSelectedFiltersParsed.entityType[i];
            const existsInWorklistResults = data.filterTypes.some(
              (entityTypeFromAPI: TTypeFilters) => {
                return (
                  entityTypeFromAPI.dataRequestTypeGuid?.toString() ===
                    storedEntityType ||
                  entityTypeFromAPI.entityTypeId.toString() === storedEntityType
                );
              }
            );

            if (!existsInWorklistResults) {
              storedSelectedFiltersParsed.entityType.splice(i, 1);
              i--;
            }
          }
        } else {
          storedSelectedFiltersParsed.entityType = [];
        }

        if (data.filterStatuses?.length > 0) {
          for (let i = 0; i < storedSelectedFiltersParsed.status.length; i++) {
            const ss = storedSelectedFiltersParsed.status[i];

            const [storedStatusId, storedSubStatusId] = ss.split(';');
            const existsInWorklistResults = data.filterStatuses.some(
              (statusFilterFromAPI: TStatusFilters) => {
                const subStatusMatch =
                  (statusFilterFromAPI.subStatusId === null &&
                    storedSubStatusId === 'null') ||
                  (statusFilterFromAPI.subStatusId !== null &&
                    statusFilterFromAPI.subStatusId.toString() ===
                      storedSubStatusId);
                return (
                  statusFilterFromAPI.statusId.toString() === storedStatusId &&
                  subStatusMatch
                );
              }
            );

            if (!existsInWorklistResults) {
              storedSelectedFiltersParsed.status.splice(i, 1);
              i--;
            }
          }
        } else {
          storedSelectedFiltersParsed.status = [];
        }

        window.sessionStorage.setItem(
          FILTERS_SESSION_STORAGE,
          JSON.stringify(storedSelectedFiltersParsed)
        );
        setFilters(storedSelectedFiltersParsed);

        setTypeFilters(data.filterTypes ?? []);
        setStatusFilters(data.filterStatuses ?? []);
      }

      setTotalResults(
        data.totalResults - (data.results.length - mappedWorklist.length)
      );
      setWorklist(mappedWorklist);
      setIsUpdatingFollowStatus(false);
      setIsLoading(false);
    },
    [
      filters,
      FILTERS_SESSION_STORAGE,
      storedSelectedFiltersParsed,
      getTabKey,
      pageSize,
      pageView,
      searchQuery,
      setIsLoading,
      setWorklist,
      sorted,
      statusFilters.length,
      typeFilters.length
    ]
  );

  const onSearch = useMemo(
    () =>
      debouncedSearch(
        (searchQuery: string) => {
          setSearchQuery(searchQuery);
        },
        (searchQuery: string) => {
          setSearchQuery(searchQuery);
          setPageView(1);
          setIsLoading(true);
        }
      ),
    []
  );

  useEffect(() => {
    const refreshData = () => {
      setIsLoading(true);
    };

    dataRequestDrawerEvents.addListener('commentAdded', refreshData);
    dataRequestDrawerEvents.addListener('commentEdited', refreshData);
    dataRequestDrawerEvents.addListener('commentRemoved', refreshData);
    taskDrawerEvents.addListener('commentAdded', refreshData);
    taskDrawerEvents.addListener('commentEdited', refreshData);
    taskDrawerEvents.addListener('commentRemoved', refreshData);
  }, [dataRequestDrawerEvents, taskDrawerEvents]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    sourceRef.current = ApiService.CancelToken.source();
    getWorklistAssignments(sourceRef.current.token);

    return () => {
      sourceRef.current?.cancel();
    };
  }, [isLoading, getWorklistAssignments, sourceRef]);

  useEffect(() => {
    triggerAmplitudeEvent({
      amplitudeEventAction: AmplitudeActionType.CLICK,
      amplitudeEventName: amplitudeEventDetail.global.viewWorklistEventName
    });
  }, [location.pathname, getTabKey, triggerAmplitudeEvent]);

  const handleCloseAssignmentModal: WorklistMineInterfaces.IHandleCloseAssignmentModal =
    (isAssignmentEdited, setStateCallbacks) => {
      if (isAssignmentEdited) {
        setStateCallbacks.setIsLoading(true);
      }

      setStateCallbacks.setAssignmentToOpenInModal(null);
      setStateCallbacks.setModalAction(null);
    };

  const handleOpenDataRequestDrawer: WorklistMineInterfaces.IHandleOpenDataRequestDrawer =
    (
      {
        engagementGuid,
        engagementDisplayNameShort,
        isEngagementReadOnly,
        queueItemGuid,
        title,
        dataRequestType
      },
      onDataRequestDrawerOpenCallback
    ) => {
      onDataRequestDrawerOpenCallback({
        engagementGuid,
        engagementDisplayNameShort,
        isEngagementReadOnly,
        queueItemGuid,
        title,
        dataRequestType
      } as IDataRequestV2);
    };

  const handleOpenTaskDrawer: WorklistMineInterfaces.IHandleOpenTaskDrawer = (
    {
      engagementGuid,
      engagementDisplayNameShort,
      isEngagementReadOnly,
      queueItemGuid,
      title
    },
    onTaskDrawerOpenCallback
  ) => {
    onTaskDrawerOpenCallback({
      engagementGuid,
      engagementDisplayNameShort,
      isEngagementReadOnly,
      queueItemGuid,
      title
    } as ITask);
  };

  const handleToggleFollow: WorklistMineInterfaces.IHandleToggleFollow = async (
    { engagementGuid, isFollowing, queueItemGuid },
    callbacks
  ) => {
    try {
      callbacks.setIsUpdatingFollowStatus(true);

      await ApiService.addWatcherToQueue(
        engagementGuid,
        !isFollowing,
        queueItemGuid
      );

      callbacks.setIsLoading(true);
    } catch {
      pushServerErrorToast();
    }
  };

  const onMarkDataDelivered = async (
    worklistItem: WorklistMineInterfaces.TWorklistItem
  ) => {
    const { queueItemGuid, statusId } = worklistItem;

    try {
      const { data } = await ApiService.markDataRequestDataDelivered({
        markAsDataDelivered: true,
        queueItemGuid,
        status: statusId,
        subStatus: SubStatusEnums.DataDelivered
      });

      pushToast({
        content: getTextToDisplay(
          `${ROOT_TO_TEXT}.markDataDeliveredSuccessToastContent`,
          { title: data.title }
        ),
        type: EMessageTypes.SUCCESS
      });

      setIsLoading(true);
    } catch {
      pushServerErrorToast();
    }
  };

  const onMarkTaskInProgress = async (
    worklistItem: WorklistMineInterfaces.TWorklistItem
  ) => {
    try {
      const { data } = await ApiService.updateTask(
        WorklistMineUtils.mapAssignmentToUpdateTaskApi(worklistItem) as ITask,
        { statusId: Status.InProgress }
      );

      pushToast({
        content: getTextToDisplay(
          `${ROOT_TO_TEXT}.markInProgressSucessToastContent`,
          { title: data.title }
        ),
        title: getTextToDisplay(
          `${ROOT_TO_TEXT}.markInProgressSucessToastTitle`
        ),
        type: EMessageTypes.SUCCESS
      });

      setIsLoading(true);
    } catch {
      pushServerErrorToast();
    }
  };

  const stateActionsMap = {
    [WorklistMineUtils.StateActionEnums.TO_ASSIGN_DATA_UPLOAD]: () => {
      setModalAction(DataRequestModalsEnums.EAction.ASSIGN_DATA_UPLOAD);
    },
    [WorklistMineUtils.StateActionEnums.TO_COMPLETE]: () => {
      setModalAction('complete' as any);
    },
    [WorklistMineUtils.StateActionEnums.TO_DELETE]: () => {
      setModalAction('delete' as any);
    },
    [WorklistMineUtils.StateActionEnums.TO_EDIT]: () => {
      setModalAction('edit' as any);
    },
    [WorklistMineUtils.StateActionEnums.TO_REASSIGN]: () => {
      setModalAction(TaskModalsEnums.EAction.REASSIGN);
    }
  };
  return (
    <div className="worklist-open">
      <Table<WorklistMineInterfaces.TWorklistItem>
        columns={
          useWorkListColumns({
            onDataRequestCommentClick: worklistItem => {
              handleOpenDataRequestDrawer(
                worklistItem,
                onDataRequestDrawerOpenComments
              );
            },
            onMarkDataDelivered,
            onMarkTaskInProgress,
            onSetWorklistItemToState: (stateAction, worklistItem) => {
              setAssignmentToOpenInModal(worklistItem);
              stateActionsMap[stateAction as keyof typeof stateActionsMap]();
            },
            onTaskCommentClick: worklistItem => {
              handleOpenTaskDrawer(worklistItem, onTaskDrawerOpen);
            },
            typeFilters,
            statusFilters
          }) as any
        }
        data={worklist}
        expanded={expandedRows}
        filtered={filters}
        loading={isLoading}
        onFilterChange={(filtered: ITableState['filtered']) => {
          setFilters(filtered);
          window.sessionStorage.setItem(
            FILTERS_SESSION_STORAGE,
            JSON.stringify(filtered)
          );
          setPageView(1);
          setIsLoading(true);
        }}
        onPageChange={(page: number, pageSize: number) => {
          setPageView(page);
          setPageSize(pageSize);
          setIsLoading(true);
        }}
        onSearchChange={onSearch}
        onSortChange={(sorted: any) => {
          setSorted(sorted);
          setIsLoading(true);
        }}
        onToggleExpansion={(isRowExpanded, row, rowId) => {
          setExpandedRows({
            ...expandedRows,
            [rowId]: isRowExpanded
          });
        }}
        page={pageView}
        pageSize={pageSize}
        pageSizeOptions={[10, 25, 50, 100]}
        renderActionPlacement={1}
        renderActions={useWorklistActions({
          onCreateDataRequest: () => {
            triggerAmplitudeEvent({
              amplitudeEventAction:
                amplitudeEventDetail.ryanWorklist.createEventName,
              amplitudeEventLocation: '/app/worklist',
              amplitudeEventName:
                amplitudeEventDetail.ryanWorklist.dataRequestEventName,
              amplitudeEventProperty:
                amplitudeEventDetail.ryanWorklist.dataRequestEventNameOptions[
                  activeTab
                ]
            });

            setAssignmentToOpenInModal({
              entityTypeId: EntityType.DataRequest
            } as WorklistMineInterfaces.TWorklistItem);
            setModalAction(DataRequestModalsEnums.EAction.CREATE);
          },
          onCreateTask: () => {
            triggerAmplitudeEvent({
              amplitudeEventAction:
                amplitudeEventDetail.ryanWorklist.createEventName,
              amplitudeEventLocation: '/app/worklist',
              amplitudeEventName:
                amplitudeEventDetail.ryanWorklist.tasksEventName,
              amplitudeEventProperty:
                amplitudeEventDetail.ryanWorklist.taskEventNameOptions[
                  activeTab
                ]
            });

            setAssignmentToOpenInModal({
              entityTypeId: EntityType.Task
            } as WorklistMineInterfaces.TWorklistItem);
            setModalAction(TaskModalsEnums.EAction.CREATE);
          }
        })}
        renderEmpty={() => (
          <TableEmpty searchQuery={searchQuery}>
            <Empty icon="clipboard">
              {getTextToDisplay(`${ROOT_TO_TEXT}.emptyTable`)}
            </Empty>
          </TableEmpty>
        )}
        renderExpandedRow={row => {
          const { entityTypeId } = row;
          let ExpandedRowElement = null;

          switch (entityTypeId) {
            case EntityType.DataRequest:
              ExpandedRowElement = (
                <DataRequestExpandedRow
                  dataRequestDetails={mapToDataRequestExpandedRow(row)}
                  isDisabled={isUpdatingFollowStatus}
                  onOpenDataRequestCommentDrawer={() => {
                    handleOpenDataRequestDrawer(
                      row,
                      onDataRequestDrawerOpenComments
                    );
                  }}
                  onOpenDataRequestHistoryDrawer={() => {
                    handleOpenDataRequestDrawer(
                      row,
                      onDataRequestDrawerOpenHistory
                    );
                  }}
                  onOpenFollowersModal={() => {
                    setAssignmentToUpdateFollowers(row);
                  }}
                  setIsLoading={setIsLoading}
                  setIsUpdatingFollowStatus={setIsUpdatingFollowStatus}
                />
              );
              break;
            case EntityType.Task:
              ExpandedRowElement = (
                <TaskExpandedRow
                  isDisabled={isUpdatingFollowStatus}
                  onLinkToMilestone={() => {
                    setAssignmentToOpenInModal(row);
                    setModalAction(TaskModalsEnums.EAction.EDIT);
                  }}
                  onOpenFollowersModal={() => {
                    setAssignmentToUpdateFollowers(row);
                  }}
                  onOpenTaskCommentDrawer={() => {
                    handleOpenTaskDrawer(row, onTaskDrawerOpen);
                  }}
                  onToggleFollow={() => {
                    handleToggleFollow(row, {
                      setIsUpdatingFollowStatus,
                      setIsLoading
                    });
                  }}
                  taskDetails={mapToTaskExpandedRow(row)}
                />
              );
              break;
            default:
          }

          return ExpandedRowElement;
        }}
        rowClassName={row =>
          isPastDue(new Date(row.dueDate), new Date())
            ? 'past-due-assignment'
            : ''
        }
        rowId="queueItemGuid"
        searchQuery={searchQuery}
        sorted={sorted}
        totalCount={totalResults}
      />

      {assignmentToOpenInModal &&
        assignmentToOpenInModal.entityTypeId === EntityType.DataRequest &&
        modalAction && (
          <DataRequestModals
            assignment={assignmentToOpenInModal}
            modalAction={modalAction as DataRequestModalsEnums.EAction}
            onClose={(editedDataRequest: IDataRequestV2 | undefined) => {
              handleCloseAssignmentModal(Boolean(editedDataRequest), {
                setAssignmentToOpenInModal,
                setIsLoading,
                setModalAction
              });
            }}
          />
        )}
      {assignmentToOpenInModal &&
        assignmentToOpenInModal.entityTypeId === EntityType.Task &&
        modalAction && (
          <TaskModals
            assignment={assignmentToOpenInModal}
            modalAction={modalAction as TaskModalsEnums.EAction}
            onClose={(editedTask?: ITask) => {
              handleCloseAssignmentModal(Boolean(editedTask), {
                setAssignmentToOpenInModal,
                setIsLoading,
                setModalAction
              });
            }}
          />
        )}

      {assignmentToUpdateFollowers && (
        <FollowersModal
          engagementGuid={assignmentToUpdateFollowers.engagementGuid}
          instructions={getTextToDisplay('followersModal.instructions', {
            context:
              Object.values(EntityType)[
                assignmentToUpdateFollowers.entityTypeId - 1
              ]
          })}
          onClose={() => {
            setAssignmentToUpdateFollowers(null);
          }}
          onUpdate={() => {
            setAssignmentToUpdateFollowers(null);
            setIsLoading(true);
          }}
          open
          queueItemGuid={assignmentToUpdateFollowers.queueItemGuid}
          title={getTextToDisplay('followersModal.title', {
            context:
              Object.values(EntityType)[
                assignmentToUpdateFollowers.entityTypeId - 1
              ]
          })}
          userTypeId={activeUserProfile.userTypeId}
        />
      )}
    </div>
  );
};

export default WorklistMine;
