import Currency from 'components/Currency/Currency';
import DocumentTitle from 'components/DocumentTitle';
import InfoTooltip from 'components/InfoTooltip';
import Table from 'components/Table';
import { useUser } from 'contexts/UserContext';
import { useStateMounted } from 'hooks';
import {
  EngagementStatus,
  IAccountSavings,
  IEngagementSavingsSummary,
  IEngagementSavingsSummaryDetails,
  IHistoricalSavingsSummary,
  Permission
} from 'interfaces';
import { IAccount } from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import history from 'services/history';
import { formatDate } from 'utils/formatDate';
import getSortParam from 'utils/getSortParm';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import useTable, {
  TableHookInitialState,
  TableHookOnFetch
} from 'utils/useTable';

import classnames from 'classnames';
import ENV from 'env';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  ITableColumn,
  Icon,
  SplitButton,
  Tooltip,
  makeTableCheckboxFilter
} from '@ryan/components';

import SavingsSummaryButton from '../../../components/SavingsSummary/SavingsSummaryButton/SavingsSummaryButton';
import { TSavingsSummary } from '../../../components/SavingsSummary/SavingsSummaryButton/utils';
import { TGetSupportedISOCurrencyCodes } from '../../../services/ApiService/ApiSavings';
import { handleDefaultApiError } from '../../../utils/handleApiError/handleApiError';
import HistoricalSavingsDeleteModal from './HistoricalSavingsDeleteModal';
import HistoricalSavingsModal from './HistoricalSavingsModal';
import SavingsSummaryDetail from './SavingsSummaryDetail';
import SavingsTotals from './SavingsTotals';

import './SavingsSummaryOverview.scss';

const savingsTableHookInitialState: TableHookInitialState<IEngagementSavingsSummary> =
  {
    query: {
      filtered: {},
      page: 1,
      pageSize: 10,
      searchQuery: '',
      sorted: {
        desc: false,
        id: 'engagementDisplayNameLong'
      }
    }
  };

const historicalSavingsTableHookInitialState: TableHookInitialState<IHistoricalSavingsSummary> =
  {
    query: {
      filtered: {},
      page: 1,
      pageSize: 10,
      searchQuery: '',
      sorted: {
        desc: false,
        id: 'engagementName'
      }
    }
  };

const SavingsSummaryOverview: FunctionComponent = () => {
  const { t } = useTranslation();
  const { isAppReadOnly, permissionService: ps, activeView, user } = useUser();
  const [availableAccounts, setAvailableAccounts] = useState<IAccount[]>();
  const [isoCurrencyCodeOptions, setISOCurrencyCodeOptions] = useState<
    TGetSupportedISOCurrencyCodes[]
  >([]);
  const [isUserGhostedOnAllEngagements, setIsUserGhosted] = useState<boolean>();
  const customViewSavingsSummariesCancelToken = useRef<CancelTokenSource>();
  const engagementSavingsSummaryDetailsCancelToken =
    useRef<CancelTokenSource>();
  const historicalSavingsSummariesCancelToken = useRef<CancelTokenSource>();
  const customViewSavingsTotalsCancelToken = useRef<CancelTokenSource>();

  /**
   * Getting available accounts current user has in active view.
   *
   * if length is 0 - this means the user is not associated with
   *  any non-ghosted engagements within the active view.
   *
   * if length is 0 - we will disable 'add savings' and 'add historical savings' buttons
   * if length is greater than 0 - we will pass the list of accounts to the 'Add
   *  Project Savings' modal for the AccountAutocomplete component
   */
  const fetchRelevantAccounts = useCallback(async () => {
    const result = (
      await ApiService.getRelevantAccountsForUserWithEngagementsByGuid(
        activeView.customViewGuid
      )
    ).data;
    setIsUserGhosted(result.length <= 0);
    setAvailableAccounts(result);
  }, [activeView.customViewGuid]);

  /**
   * Project Savings (Managed Internally)
   */
  const savingsTableOnFetch: TableHookOnFetch<IEngagementSavingsSummary> =
    useCallback(
      query => {
        customViewSavingsSummariesCancelToken.current?.cancel();
        customViewSavingsSummariesCancelToken.current =
          ApiService.CancelToken.source();

        return ApiService.getCustomViewSavingsSummaries(
          activeView.customViewGuid,
          {
            searchTerm: query.searchQuery,
            isActive:
              query.filtered.isActive && query.filtered.isActive.length === 1
                ? query.filtered.isActive[0] === '1'
                : undefined,
            sort: getSortParam(query.sorted),
            pageNumber: query.page,
            itemsPerPage: query.pageSize
          },
          customViewSavingsSummariesCancelToken.current.token
        )
          .then(({ data }) => ({
            data: data.results,
            totalCount: data.totalResults
          }))
          .catch(error => {
            if (!ApiService.isCancel(error)) {
              pushServerErrorToast();
            }

            throw error;
          });
      },
      [activeView.customViewGuid]
    );
  const [savingsSummaries, fetchSavingsSummaries] =
    useTable<IEngagementSavingsSummary>(
      savingsTableHookInitialState,
      savingsTableOnFetch
    );

  const fetchTotals = useCallback(() => {
    customViewSavingsTotalsCancelToken.current?.cancel(
      'cancelling previous getCustomViewSavingsTotals request'
    );
    customViewSavingsTotalsCancelToken.current =
      ApiService.CancelToken.source();

    ApiService.getCustomViewSavingsTotals(
      activeView.customViewGuid,
      customViewSavingsTotalsCancelToken.current.token
    )
      .then(response => {
        setTotals(response.data);
      })
      .catch(error => {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      });
  }, [activeView.customViewGuid]);

  const savingsSummariesColumns: ITableColumn<TSavingsSummary>[] = useMemo(
    () => [
      {
        id: 'engagementDisplayNameLong',
        label: t('Project'),
        sortable: true,
        render: row => (
          <div className="bs">
            <b>{row.engagementDisplayNameLong}</b>
            <small>{row.accountName}</small>
          </div>
        )
      },
      {
        id: 'jurisdictionAbbreviation',
        label: t('Jurisdiction'),
        width: '8rem',
        sortable: true,
        render: 'jurisdictionAbbreviation'
      },
      {
        id: 'countryAbbreviation',
        label: t('Country'),
        width: '8rem',
        sortable: true,
        render: 'countryAbbreviation'
      },
      {
        id: 'isActive',
        label: t('Status'),
        width: '8rem',
        sortable: true,
        filter: makeTableCheckboxFilter([
          { value: EngagementStatus.Active.toString(), label: t('Active') },
          {
            value: EngagementStatus.InActive.toString(),
            label: t('Inactive')
          }
        ]),
        filterActive: (value?: string[]) => !!value && value.length > 0,
        render: (ss: IEngagementSavingsSummary) => {
          return ss.isActive ? (
            <span className="pill active">{t('Active')}</span>
          ) : (
            <span className="pill inactive">{t('Inactive')}</span>
          );
        }
      },
      {
        id: 'lastSavingsSummarySavingsTotal',
        label: t('Total Savings'),
        width: '8rem',
        sortable: true,
        align: 'right',
        render: (ss: IEngagementSavingsSummary) =>
          ss.isoCurrencyCode ? (
            <Currency
              currencyCode={ss.isoCurrencyCode}
              value={ss.lastSavingsSummarySavingsTotal || 0}
            />
          ) : (
            '–'
          )
      },
      {
        id: 'lastSavingsSummaryAsOfDate',
        label: t('As of'),
        width: '7rem',
        sortable: true,
        render: (ss: IEngagementSavingsSummary) => {
          const date = ss.lastSavingsSummaryAsOfDate;
          return date === null ? '–' : formatDate(date);
        }
      },
      {
        id: 'actions',
        label: '',
        width: '14rem',
        render: (ss: TSavingsSummary) => (
          <SavingsSummaryButton
            fetchSavingsSummaries={fetchSavingsSummaries}
            fetchTotals={fetchTotals}
            isoCurrencyCodeOptions={isoCurrencyCodeOptions}
            onCreateEntry={() => onCreateEntry(ss)}
            onEditLastEntry={() => onEditLastEntry(ss)}
            onViewHistory={() => onViewHistory(ss)}
            savingsSummary={ss}
          />
        )
      }
    ],
    [fetchSavingsSummaries, fetchTotals, isoCurrencyCodeOptions, t]
  );

  const [details, setDetails] = useState<{
    [key: string]: IEngagementSavingsSummaryDetails;
  }>({});

  const onToggleExpansion = useCallback(
    (
      expanded: boolean,
      ss: IEngagementSavingsSummary,
      engagementGuid: string
    ) => {
      // If details for this engagement have not been fetched...
      if (!details[engagementGuid]) {
        const ss = savingsSummaries.state.data.find(
          ss => ss.engagementGuid === engagementGuid
        );

        // If the engagement has records to fetch...
        if (ss && ss.lastSavingsSummaryAsOfDate !== null) {
          engagementSavingsSummaryDetailsCancelToken.current?.cancel(
            'cancelling previous getEngagementSavingsSummaryDetails request'
          );
          engagementSavingsSummaryDetailsCancelToken.current =
            ApiService.CancelToken.source();

          ApiService.getEngagementSavingsSummaryDetails(
            engagementGuid,
            engagementSavingsSummaryDetailsCancelToken.current.token
          )
            .then(response => {
              // expanded: Object.assign({}, expanded), // force Table re-render
              setDetails(details => ({
                ...details,
                [engagementGuid]: response.data
              }));
            })
            .catch(error => {
              if (!ApiService.isCancel(error)) {
                pushServerErrorToast();
              }
            });
        }
      }

      savingsSummaries.onToggleExpansion(expanded, ss, engagementGuid);
    },
    [details, savingsSummaries]
  );

  function onCreateEntry(ss: IEngagementSavingsSummary) {
    history.push(
      `/app/project/${ss.engagementGuid}/savings-summary-entry/create`
    );
  }

  function onEditLastEntry(ss: IEngagementSavingsSummary) {
    history.push(
      `/app/project/${ss.engagementGuid}/savings-summary-entry/update`
    );
  }

  function onViewHistory(ss: IEngagementSavingsSummary) {
    history.push(`/app/project/${ss.engagementGuid}/savings-history`);
  }

  /**
   * Historical Savings (Managed Externally)
   */
  const historicalSavingsTableOnFetch: TableHookOnFetch<IHistoricalSavingsSummary> =
    useCallback(
      query => {
        historicalSavingsSummariesCancelToken.current?.cancel();
        historicalSavingsSummariesCancelToken.current =
          ApiService.CancelToken.source();

        return ApiService.getHistoricalSavingsSummaries(
          activeView.customViewGuid,
          {
            searchTerm: query.searchQuery,
            sort: getSortParam(query.sorted),
            pageNumber: query.page,
            itemsPerPage: query.pageSize
          },
          historicalSavingsSummariesCancelToken.current.token
        )
          .then(({ data }) => ({
            data: data.results,
            totalCount: data.totalResults
          }))
          .catch(error => {
            if (!ApiService.isCancel(error)) {
              pushServerErrorToast();
            }

            throw error;
          });
      },
      [activeView.customViewGuid]
    );
  const [historicalSavingsSummaries, fetchHistoricalSavingsSummaries] =
    useTable<IHistoricalSavingsSummary>(
      historicalSavingsTableHookInitialState,
      historicalSavingsTableOnFetch
    );

  // When historical savings is empty, we show an area at the bottom of the page
  // where Ryan users can create the first historical savings.
  const [open, setOpen] = useState(false);
  const onToggleHistoricalEmpty = () => setOpen(open => !open);

  /**
   * Account Savings Totals
   */

  const [totals, setTotals] = useState<IAccountSavings[] | null>(null);

  /**
   * Fetch everything!
   */

  const [hasHistoricalSavings, setHasHistoricalSavings] = useState(false);

  const checkForHistoricalSavings = useCallback(() => {
    setHasHistoricalSavings(false);

    historicalSavingsSummariesCancelToken.current?.cancel(
      'cancelling previous getHistoricalSavingsSummaries request'
    );
    historicalSavingsSummariesCancelToken.current =
      ApiService.CancelToken.source();

    ApiService.getHistoricalSavingsSummaries(
      activeView.customViewGuid,
      {
        pageNumber: 1,
        itemsPerPage: 0
      },
      historicalSavingsSummariesCancelToken.current.token
    )
      .then(response => {
        if (response.data.totalResults > 0) {
          setHasHistoricalSavings(true);
          fetchHistoricalSavingsSummaries({ page: 1 });
        }
      })
      .catch(error => {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      });
  }, [activeView.customViewGuid, fetchHistoricalSavingsSummaries]);

  useEffect(() => {
    // Savings
    fetchSavingsSummaries({ page: 1 });

    // Historical Savings
    checkForHistoricalSavings();

    // Totals
    fetchTotals();

    // Fetch Relevent Accounts
    fetchRelevantAccounts();
  }, [
    activeView.customViewGuid,
    checkForHistoricalSavings,
    fetchSavingsSummaries,
    fetchTotals,
    fetchRelevantAccounts
  ]);

  const fetchISOCurrencyCodes = useCallback(async () => {
    try {
      const { data } = await ApiService.getSupportedISOCurrencyCodes();

      setISOCurrencyCodeOptions(data);
    } catch (error) {
      handleDefaultApiError(error);
    }
  }, []);

  useEffect(() => {
    const { data: savingsSummariesData } = savingsSummaries.state;

    if (
      savingsSummariesData.length === 0 ||
      isoCurrencyCodeOptions.length > 0
    ) {
      return;
    }

    if (
      savingsSummariesData.some(
        savingsSummary =>
          !savingsSummary.isoCurrencyCode ||
          !savingsSummary.lastSavingsSummaryAsOfDate
      )
    ) {
      fetchISOCurrencyCodes();
    }
  }, [
    fetchISOCurrencyCodes,
    isoCurrencyCodeOptions.length,
    savingsSummaries.state
  ]);

  /**
   * Creating or Editing Historical Savings
   *
   * • false - modal closed
   * • true  - modal open, create new
   * • {...} - modal open, edit existing
   */
  const [historicalModal, setHistoricalModal] = useState<
    boolean | IHistoricalSavingsSummary
  >(false);
  const [historicalSavingsToDelete, setHistoricalSavingsToDelete] =
    useStateMounted<IHistoricalSavingsSummary | null>(null);

  function onAddHistoricalSavings() {
    setHistoricalModal(true);
  }

  const onCloseHistoricalSavings = useCallback(
    (wasSubmitted = false) => {
      setHistoricalModal(false);

      if (wasSubmitted) {
        if (hasHistoricalSavings) {
          fetchHistoricalSavingsSummaries({});
        } else {
          checkForHistoricalSavings();
        }

        fetchTotals();
      }
    },
    [
      hasHistoricalSavings,
      checkForHistoricalSavings,
      fetchHistoricalSavingsSummaries,
      fetchTotals
    ]
  );

  /**
   * Updates historical savings summary table on deletion of entry.
   */
  const onCloseHistoricalSavingsDelete = useCallback(
    (wasDeleted = false) => {
      // re-check for historical savings in case all entries removed
      if (wasDeleted) {
        checkForHistoricalSavings();
        fetchTotals();
      }

      setHistoricalSavingsToDelete(null);
    },
    [checkForHistoricalSavings, fetchTotals, setHistoricalSavingsToDelete]
  );

  /**
   * Initializes editing of an existing historical savings entry via modal.
   */
  const onEditHistoricalSavings = useCallback(
    (ss: IHistoricalSavingsSummary) => {
      setHistoricalModal(ss);
    },
    []
  );

  /**
   * Initialize deletion of historical savings summary via modal.
   */
  const onDeleteHistoricalSavings = useCallback(
    (ss: IHistoricalSavingsSummary) => {
      setHistoricalSavingsToDelete(ss);
    },
    [setHistoricalSavingsToDelete]
  );

  const userIsGhostedOnSingleAccount = useCallback(
    accountGuid => {
      if (availableAccounts !== undefined) {
        const selectAccount = availableAccounts.filter(
          account => account.accountGuid === accountGuid
        );
        if (selectAccount.length === 0) {
          return true;
        }
      }
      return false;
    },
    [availableAccounts]
  );

  const historicalSavingsSummariesColumns: ITableColumn<IHistoricalSavingsSummary>[] =
    useMemo(
      () => [
        {
          id: 'engagementName',
          label: t('Project'),
          sortable: true,
          render: row => (
            <div className="bs">
              <b>{row.engagementName}</b>
              <small>
                {row.accountStatusId === 0
                  ? row.inactiveAccountName
                  : row.accountName}
              </small>
            </div>
          )
        },
        {
          id: 'updatedByName',
          label: t('Updated By'),
          width: '8rem',
          sortable: true,
          render: row => {
            return row.updatedBy === user.profile.userGuid
              ? t('You')
              : row.updatedByName;
          }
        },
        {
          id: 'jurisdictionAbbreviation',
          label: t('Jurisdiction'),
          width: '8rem',
          sortable: true,
          render: row => row.jurisdiction.abbreviation
        },
        {
          id: 'countryAbbreviation',
          label: t('Country'),
          width: '8rem',
          sortable: true,
          render: row => row.country.isoAlpha3Code
        },
        {
          id: 'alwaysInactive',
          label: t('Status'),
          width: '8rem',
          render: () => <span className="pill inactive">{t('Inactive')}</span>
        },
        {
          id: 'totalSavings',
          label: t('Total Savings'),
          width: '8rem',
          sortable: true,
          align: 'right',
          render: row => (
            <Currency
              currencyCode={row.isoCurrencyCode}
              value={row.totalSavings}
            />
          )
        },
        {
          id: 'asOfDate',
          label: t('As of'),
          width: '7rem',
          sortable: true,
          render: row => formatDate(row.asOfDate)
        },
        {
          id: 'additionalInfo',
          label: '',
          render: row => (
            <Tooltip
              className={classnames({
                'tooltip-list': true
              })}
              content={
                <ul>
                  <li>
                    <label>{t('Created Date')}</label>
                    {formatDate(row.createDate)}
                  </li>
                  <li>
                    <label>{t('Created By')}</label>
                    {row.createdBy === user.profile.userGuid
                      ? t('You')
                      : row.createdByName}
                  </li>
                </ul>
              }
              placement="bottom"
              renderTarget={({ open, ...props }) => (
                <div
                  aria-expanded={open}
                  aria-haspopup="true"
                  className="info-tooltip"
                  {...props}
                >
                  <Icon name="information" />
                </div>
              )}
            />
          )
        },
        {
          id: 'actions',
          label: '',
          width: '14rem',
          render: row =>
            ps.hasPermission(Permission.SavingsSummaryEdit) && (
              <div className="button-margins">
                <SplitButton
                  block
                  disabled={
                    isAppReadOnly ||
                    isUserGhostedOnAllEngagements ||
                    userIsGhostedOnSingleAccount(row.accountGuid)
                  }
                  onClick={() => onEditHistoricalSavings(row)}
                  options={[
                    {
                      disabled:
                        isAppReadOnly ||
                        isUserGhostedOnAllEngagements ||
                        userIsGhostedOnSingleAccount(row.accountGuid),
                      label: t('Delete'),
                      icon: 'trash',
                      negative: true,
                      onClick: () => onDeleteHistoricalSavings(row)
                    }
                  ]}
                  size={EButtonSizes.SMALL}
                  text={t('Edit')}
                />
              </div>
            )
        }
      ],
      [
        isAppReadOnly,
        ps,
        onDeleteHistoricalSavings,
        onEditHistoricalSavings,
        t,
        isUserGhostedOnAllEngagements,
        userIsGhostedOnSingleAccount
      ]
    );

  const handleResizeEvent = useCallback(() => {
    const savingsFooterElementHeight = document.querySelector<HTMLElement>(
      '.savings-summary__footer'
    )!.offsetHeight;

    document
      .querySelector<HTMLElement>('.savings-summary__spacer')!
      .style.setProperty(
        'height',
        `calc(4rem + ${savingsFooterElementHeight}px)`
      );
  }, []);

  useEffect(() => {
    const mainElement = document.querySelector('main');
    mainElement!.style.setProperty('position', 'relative');
    mainElement!.style.setProperty('padding-bottom', '0');

    handleResizeEvent();
    window.addEventListener('resize', handleResizeEvent);

    return () => {
      mainElement!.style.removeProperty('position');
      mainElement!.style.removeProperty('padding-bottom');

      window.removeEventListener('resize', handleResizeEvent);

      customViewSavingsSummariesCancelToken.current?.cancel();
      engagementSavingsSummaryDetailsCancelToken.current?.cancel();
      historicalSavingsSummariesCancelToken.current?.cancel();
      customViewSavingsTotalsCancelToken.current?.cancel();
    };
  }, [handleResizeEvent]);

  /**
   * Render
   */

  const title = t('savings.overview.title');

  return (
    <div className="savings-summary">
      <DocumentTitle title={title} />
      <Table<IEngagementSavingsSummary, string>
        columns={savingsSummariesColumns as any}
        data={savingsSummaries.state.data}
        expanded={savingsSummaries.state.expanded}
        filtered={savingsSummaries.state.query.filtered}
        loading={savingsSummaries.state.loading}
        onFilterChange={savingsSummaries.onFilterChange}
        // query
        onPageChange={savingsSummaries.onPageChange}
        onSearchChange={savingsSummaries.onSearchChange}
        onSortChange={savingsSummaries.onSortChange}
        onToggleExpansion={onToggleExpansion}
        page={savingsSummaries.state.query.page}
        // handlers
        pageSize={savingsSummaries.state.query.pageSize}
        renderExpandedRow={(ss: IEngagementSavingsSummary) => (
          <SavingsSummaryDetail
            isAppReadOnly={isAppReadOnly}
            onCreateEntry={() => onCreateEntry(ss)}
            onEditLastEntry={() => onEditLastEntry(ss)}
            onViewHistory={() => onViewHistory(ss)}
            permissionService={ps}
            savingsSummary={ss as TSavingsSummary}
            savingsSummaryDetails={details[ss.engagementGuid] || null}
          />
        )}
        rowId="engagementGuid"
        searchQuery={savingsSummaries.state.query.searchQuery}
        sorted={savingsSummaries.state.query.sorted}
        // expanded rows
        title={
          hasHistoricalSavings
            ? t('savings.overview.projectsManagedIn', {
                ryanPlatform: ENV.RYAN_PLATFORM
              })
            : t('savings.overview.projectSavings')
        }
        totalCount={savingsSummaries.state.totalCount}
      />

      {hasHistoricalSavings && (
        <Table<IHistoricalSavingsSummary>
          columns={historicalSavingsSummariesColumns}
          data={historicalSavingsSummaries.state.data}
          loading={historicalSavingsSummaries.state.loading}
          onPageChange={historicalSavingsSummaries.onPageChange}
          onSearchChange={historicalSavingsSummaries.onSearchChange}
          onSortChange={historicalSavingsSummaries.onSortChange}
          // query
          onToggleExpansion={historicalSavingsSummaries.onToggleExpansion}
          page={historicalSavingsSummaries.state.query.page}
          pageSize={historicalSavingsSummaries.state.query.pageSize}
          renderActionPlacement={1}
          // handlers
          renderActions={
            ps.hasPermission(Permission.SavingsSummaryEdit)
              ? () => (
                  <Button
                    disabled={isAppReadOnly || isUserGhostedOnAllEngagements}
                    icon="plus"
                    onClick={onAddHistoricalSavings}
                    size={EButtonSizes.SMALL}
                    text={t('Add Savings')}
                    variant={EButtonVariant.TEXT}
                  />
                )
              : undefined
          }
          rowId="historicalSavingsSummaryGuid"
          searchQuery={historicalSavingsSummaries.state.query.searchQuery}
          sorted={historicalSavingsSummaries.state.query.sorted}
          // actions
          title={
            <span>
              {t('savings.overview.projectsManagedOutside', {
                ryanPlatform: ENV.RYAN_PLATFORM
              })}{' '}
              <InfoTooltip>
                {t('savings.overview.projectsManagedOutsideTooltip', {
                  ryanPlatform: ENV.RYAN_PLATFORM
                })}
              </InfoTooltip>
            </span>
          }
          totalCount={historicalSavingsSummaries.state.totalCount}
        />
      )}

      {historicalModal !== false && (
        <HistoricalSavingsModal
          availableAccounts={availableAccounts}
          onClose={onCloseHistoricalSavings}
          savings={historicalModal === true ? undefined : historicalModal}
        />
      )}

      {historicalSavingsToDelete && (
        <HistoricalSavingsDeleteModal
          onClose={onCloseHistoricalSavingsDelete}
          open={Boolean(historicalSavingsToDelete)}
          savings={historicalSavingsToDelete}
        />
      )}

      {/**
       * Totals cards
       */}
      <SavingsTotals totals={totals} />

      {/**
       * Ryan users get an empty state for Historical Savings
       */}
      {ps.isRyan() &&
        ps.hasPermission(Permission.SavingsSummaryEdit) &&
        !hasHistoricalSavings && (
          <div className="savings-summary__historical-empty">
            <button
              className="savings-summary__historical-empty-toggle"
              onClick={onToggleHistoricalEmpty}
              type="button"
            >
              <Icon name={open ? 'chevron-up' : 'chevron-down'} />
              {t('savings.overview.projectsManagedOutside', {
                ryanPlatform: ENV.RYAN_PLATFORM
              })}
            </button>
            {open && (
              <div className="row savings-summary__historical-empty-content">
                <div className="col-12 col-md-8">
                  <p>
                    {t('savings.overview.projectsManagedOutsideEmpty', {
                      ryanPlatform: ENV.RYAN_PLATFORM
                    })}
                  </p>
                </div>
                <div className="col-12 col-md-4 savings-summary__historical-empty-button">
                  <Button
                    disabled={isAppReadOnly || isUserGhostedOnAllEngagements}
                    icon="plus"
                    onClick={onAddHistoricalSavings}
                    text={t('savings.overview.addHistoricalSavings')}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      <div className="savings-summary__spacer"></div>
      <p className="savings-summary__footer">*{t('savings.tooltips.total')}</p>
    </div>
  );
};

export default SavingsSummaryOverview;
