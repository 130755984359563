import { ActivityType, ITaskSummary, Status } from 'interfaces';

import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import { AbstractActivity, ActivityColor } from '../AbstractActivity';
import { ActivityModified } from '../ActivityModified';
import { IActivityProps } from '../IActivityProps';
import { UnrecognizedActivity } from '../UnrecognizedActivity';
import { findModifiedByKey } from '../findModifiedByKey';
import { CommentActivity } from './CommentActivity';

export const TaskActivity: FunctionComponent<IActivityProps> = props => {
  const {
    t,
    activity,
    user,
    isNotification,
    showAccountName,
    showEngagementName,
    onCloseNotificationDrawer
  } = props;

  const {
    engagementGuid,
    entityGuid,
    createdBy,
    createdByName,
    snapShot: { entitySummary },
    userInformation
  } = activity;

  const { title, userGuid } = entitySummary as ITaskSummary;

  // Context helper.
  function you(userGuid: string) {
    return user.userGuid === userGuid ? 'you' : 'x';
  }

  switch (activity.activityTypeId) {
    //

    /**
     * Created
     */
    case ActivityType.TaskCreated: {
      const tBy = you(createdBy);
      const tAssigned = you(userGuid);
      const tKey = `activity.task.created.by-${tBy}-assigned-${tAssigned}`;
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Orange}
          ctaText={t('activity.task.viewTask')}
          icon="clipboard-plus"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.task.created.title')}
          to={`/app/project/${engagementGuid}/tasks/${entityGuid}`}
        >
          <Trans i18nKey={tKey}>
            <b />
            {{ title }}
            {{ createdByName }}
            {{ assignedToName: userInformation.fullName }}
          </Trans>{' '}
        </AbstractActivity>
      );
    }

    /**
     * Reassigned
     */
    case ActivityType.TaskAssignedToUser: {
      const { newValue: newAssignedTo } = findModifiedByKey(
        activity,
        'AssignedToUserGuid'
      );

      const tBy = you(createdBy);
      const tAssigned = you(newAssignedTo.userGuid);
      const tKey = `activity.task.reassigned.by-${tBy}-assigned-${tAssigned}`;

      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Orange}
          ctaText={t('activity.task.viewTask')}
          icon="clipboard-user"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.task.reassigned.title')}
          to={`/app/project/${engagementGuid}/tasks/${entityGuid}`}
        >
          <Trans i18nKey={tKey}>
            <b />
            {{ title }}
            {{ assignedToName: newAssignedTo.fullName }}
            {{ createdByName }}
          </Trans>{' '}
          <ActivityModified
            activity={activity}
            cases={[
              {
                key: 'AssignedToUserGuid',
                label: t('Assigned To'),
                type: (value: { fullName: string }) => value.fullName
              }
            ]}
          />
        </AbstractActivity>
      );
    }

    /**
     * Edited
     */
    case ActivityType.TaskModified: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Orange}
          ctaText={t('activity.task.viewTask')}
          icon="clipboard-pencil"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.task.edited.title')}
          to={`/app/project/${engagementGuid}/tasks/${entityGuid}`}
        >
          <Trans i18nKey={`activity.task.edited.by-${you(createdBy)}`}>
            <b />
            {{ title }}
            {{ createdByName }}
          </Trans>{' '}
          <ActivityModified
            activity={activity}
            cases={[
              {
                key: 'Title',
                label: t('Title'),
                type: 'string' as const
              },
              {
                key: 'Status',
                label: t('Status'),
                type: (status: Status) => t(`status.${status}`)
              },
              {
                key: 'Description',
                label: t('Description'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'Milestone',
                label: t('Milestone'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'DueDate',
                label: t('Due Date'),
                type: 'date' as const
              }
            ]}
          />
        </AbstractActivity>
      );
    }

    /**
     * Completed
     */
    case ActivityType.TaskStatusComplete: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Green}
          ctaText={t('activity.task.viewTask')}
          icon="clipboard-check"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.task.completed.title')}
          to={`/app/project/${engagementGuid}/tasks/${entityGuid}`}
        >
          <Trans i18nKey={`activity.task.completed.by-${you(createdBy)}`}>
            <b />
            {{ title }}
            {{ createdByName }}
          </Trans>{' '}
          <ActivityModified
            activity={activity}
            cases={[
              {
                key: 'Status',
                label: t('Status'),
                type: (status: Status) => t(`status.${status}`)
              }
            ]}
          />
        </AbstractActivity>
      );
    }

    /**
     * Past Due
     */
    case ActivityType.TaskPastDue: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Red}
          ctaText={t('activity.task.viewTask')}
          icon="clipboard-warning"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.task.pastDue.title')}
          to={`/app/project/${engagementGuid}/tasks/${entityGuid}`}
        >
          <Trans i18nKey={`activity.task.pastDue.assigned-${you(userGuid)}`}>
            <b />
            {{ title }}
            {{ assignedToName: userInformation.fullName }}
          </Trans>{' '}
        </AbstractActivity>
      );
    }

    /**
     * Deleted
     */
    case ActivityType.TaskDeleted: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Orange}
          icon="clipboard-delete"
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.task.deleted.title')}
        >
          <Trans i18nKey={`activity.task.deleted.by-${you(createdBy)}`}>
            <b />
            {{ title }}
            {{ createdByName }}
          </Trans>
        </AbstractActivity>
      );
    }

    /**
     * New Comment
     */
    case ActivityType.TaskCommentedOn: {
      return (
        <CommentActivity
          {...props}
          editedComment={false}
          link={`/app/project/${engagementGuid}/tasks/${entityGuid}#comments`}
        >
          {mentionedYou => (
            <Trans
              i18nKey={`activity.task.commented.by-${you(createdBy)}${
                mentionedYou ? '-mentioned-you' : ''
              }`}
            >
              <b />
              {{ title }}
              {{ createdByName }}
            </Trans>
          )}
        </CommentActivity>
      );
    }

    /**
     * Edited Comment
     */
    case ActivityType.TaskCommentModified: {
      return (
        <CommentActivity
          {...props}
          editedComment
          link={`/app/project/${engagementGuid}/tasks/${entityGuid}#comments`}
        >
          {mentionedYou => (
            <Trans
              i18nKey={`activity.task.commentEdited.by-${you(createdBy)}${
                mentionedYou ? '-mentioned-you' : ''
              }`}
            >
              <b />
              {{ title }}
              {{ createdByName }}
            </Trans>
          )}
        </CommentActivity>
      );
    }

    default:
      return <UnrecognizedActivity {...props} />;
  }
};
