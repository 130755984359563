import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput } from '@ryan/components';

export type SelectOption = {
  disabled?: boolean;
  defaultFolderGuid: string | null;
  folderName: string | null;
  label: string;
  placeholder?: string;
  value: string;
  type: string;
  createdByName: string;
};

interface SelectProps {
  disabled?: boolean;
  label: string;
  onSelection: (option: SelectOption | null) => void;
  options: SelectOption[];
  selectedOption: SelectOption | null;
  resetSelectedOption: () => void;
  selectFeedback: string | undefined;
}

const Select: FunctionComponent<SelectProps> = ({
  disabled,
  label,
  onSelection,
  options,
  resetSelectedOption,
  selectedOption,
  selectFeedback
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState(false);
  const { t } = useTranslation();

  const showDropdownToggle = () => setShowDropdown(!showDropdown);

  const updateSelectedOption = (option: SelectOption) => {
    setShowDropdown(false);
    onSelection(option);
  };

  const reset = () => {
    setSearchTerm('');
    resetSelectedOption();
    setShowDropdown(false);
    onSelection(null);
  };

  const textInputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDropdown(true);
    setSearchTerm(e.target.value);
  };

  const handleBlur = () => {
    setShowDropdown(false);
  };

  return (
    <div
      className="file-upload-select-dropdown"
      data-testid="file-upload-select"
      onBlur={handleBlur}
      onClick={() => setShowDropdown(!showDropdown)}
    >
      <TextInput
        disabled={disabled}
        feedback={selectFeedback}
        icon={
          selectedOption
            ? 'delete'
            : showDropdown
            ? 'chevron-up'
            : 'chevron-down'
        }
        invalid={selectFeedback !== undefined}
        onChange={textInputOnChangeHandler}
        onIconClick={selectedOption ? reset : showDropdownToggle}
        value={selectedOption?.label || searchTerm}
      />
      <ul
        className={showDropdown ? 'show' : 'hide'}
        onMouseDown={e => e.preventDefault()}
      >
        {options
          .filter(option =>
            option.label.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((option, index) => (
            <li key={index} onFocus={e => e.preventDefault()}>
              <button
                onClick={e => {
                  e.preventDefault();
                  updateSelectedOption(option);
                }}
              >
                <div className="select-option">
                  <p>{option.label}</p>
                  <p id="option-createdBy">
                    {option.label !== '' &&
                      `${t('Created By')} ${option.createdByName}`}
                  </p>
                </div>
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Select;
