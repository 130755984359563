import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@ryan/components';

import { TDateRange } from '../../types';
import { formatDateRanges } from '../../utils/formatRecords';
import { IRecordsModalProps } from './utils';

import './RecordsModal.scss';

export const RecordsModal: React.FC<IRecordsModalProps> = ({
  data,
  dataRequestTitle,
  engagementDisplayName,
  modalTitle,
  onClose
}) => {
  const { t: getTextToDisplay } = useTranslation();

  const getData = (data: TDateRange[] | string[]) => {
    if (data.length > 0) {
      if (typeof data[0] === 'object') {
        return formatDateRanges(data as TDateRange[]).map((range, index) => (
          <p key={index}>{range}</p>
        ));
      } else {
        return data.map((record, index) => <p key={index}>{record}</p>);
      }
    }
  };
  return (
    <Modal
      className="records-modal"
      closeText={getTextToDisplay('Close')}
      onClose={onClose}
      open
      title={modalTitle}
    >
      <div className="records-modal__titles">
        <h3 className="ry-h4">{engagementDisplayName}</h3>
        <div className="records-modal__titles__dr-title">
          {dataRequestTitle.toUpperCase()}
        </div>
      </div>
      <div className="records-modal__data">{getData(data)}</div>
    </Modal>
  );
};

export default RecordsModal;
