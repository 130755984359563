import history from '../../services/history';

export enum AmplitudeActionType {
  CLICK = 'click',
  VIEW = 'view'
}

enum AmplitudePathValues {
  DATA_AND_Files = 'Data and Files',
  SINGLE_PROJECT_FILES = 'Single Project Files',
  WORKLIST_MINE = 'worklist-mine',
  WORKLIST_MYTEAM = 'worklist-my-team',
  WORKLIST_CLIENT = 'worklist-client',
  WORKLIST_RYAN = 'worklist-ryan',
  WORKLIST_ALL = 'worklist-all'
}

export const amplitudeEventDetail = {
  dashboard: {
    createDataRequestEventName: 'data-requests-create',
    viewLastUpdatedEngagementEventName: 'last-updated',
    viewDataRequestsEventName: 'data-requests-view-data-requests',
    viewProjectsEventName: 'projects-overview-view-projects'
  },
  global: {
    viewWorklistEventName: 'worklist',
    footerLearnLink: 'learn-link',
    saveManageVisibility: 'save-manage-visibility'
  },
  get ryanContracts() {
    const DOWNLOAD_CONTRACT_PATH = 'download-contract-path';
    const VIEW_CONTRACT_PATH = 'view-contract-path';

    return {
      downloadEventName: 'click download',
      downloadPropertyOptions: {
        dataAndFiles: {
          [DOWNLOAD_CONTRACT_PATH]: AmplitudePathValues.DATA_AND_Files
        },
        singleProjectFiles: {
          [DOWNLOAD_CONTRACT_PATH]: AmplitudePathValues.SINGLE_PROJECT_FILES
        }
      },
      eventName: 'contracts',
      viewPropertyOptions: {
        dataAndFiles: {
          [VIEW_CONTRACT_PATH]: AmplitudePathValues.DATA_AND_Files
        },
        singleProjectFiles: {
          [VIEW_CONTRACT_PATH]: AmplitudePathValues.SINGLE_PROJECT_FILES
        }
      }
    };
  },
  get ryanInvoices() {
    const DOWNLOAD_INVOICE_PATH = 'download-invoice-path';
    const VIEW_INVOICE_PATH = 'view-invoice-path';

    return {
      downloadEventName: 'click download',
      downloadPropertyOptions: {
        dataAndFiles: {
          [DOWNLOAD_INVOICE_PATH]: AmplitudePathValues.DATA_AND_Files
        },
        singleProjectFiles: {
          [DOWNLOAD_INVOICE_PATH]: AmplitudePathValues.SINGLE_PROJECT_FILES
        }
      },
      eventName: 'invoices',
      viewPropertyOptions: {
        dataAndFiles: {
          [VIEW_INVOICE_PATH]: AmplitudePathValues.DATA_AND_Files
        },
        singleProjectFiles: {
          [VIEW_INVOICE_PATH]: AmplitudePathValues.SINGLE_PROJECT_FILES
        }
      }
    };
  },
  get ryanLearnings() {
    return {
      eventName: 'learnings-tab'
    };
  },
  get ryanWorklist() {
    const CREATE_DATA_REQUEST_PATH = 'create-data-request-path';
    const CREATE_TASKS_PATH = 'create-tasks-path';

    const worklistTabAmplitudeValues = {
      mine: AmplitudePathValues.WORKLIST_MINE,
      'my-team': AmplitudePathValues.WORKLIST_MYTEAM,
      client: AmplitudePathValues.WORKLIST_CLIENT,
      ryan: AmplitudePathValues.WORKLIST_RYAN,
      all: AmplitudePathValues.WORKLIST_ALL
    };

    const generateEventNameOptions = (amplitudePathKey: string) => {
      return Object.fromEntries(
        Object.entries(worklistTabAmplitudeValues).map(([key, value]) => [
          key,
          { [amplitudePathKey]: value }
        ])
      );
    };

    return {
      createEventName: 'create',
      dataRequestEventName: 'data-request',
      dataRequestEventNameOptions: generateEventNameOptions(
        CREATE_DATA_REQUEST_PATH
      ),
      tasksEventName: 'tasks',
      taskEventNameOptions: generateEventNameOptions(CREATE_TASKS_PATH)
    };
  }
};

export const amplitudeLocationMapper = {
  '/app': 'dashboard',
  '/app/data-and-files/contracts': 'data & files',
  '/app/data-and-files/invoices': 'data & files',
  '/app/project/:guid/files': 'single project files',
  '/app/project/:guid/learnings': 'single project',
  '/app/worklist': 'worklist',
  '/app/worklist/all': 'all',
  '/app/worklist/my-team': 'my-team',
  '/app/worklist/client': 'client',
  '/app/worklist/ryan': 'ryan',
  '/app/worklist/mine': 'mine'
};

export const getAmplitudeLocation = () => {
  const guidRegExPattern =
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g;

  const modifiedPathname = history.location.pathname.replaceAll(
    guidRegExPattern,
    ':guid'
  );

  return amplitudeLocationMapper[
    modifiedPathname as keyof typeof amplitudeLocationMapper
  ];
};
