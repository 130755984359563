import { isSameDay } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonSizes, SplitButton } from '@ryan/components';

import { useUser } from '../../../contexts/UserContext';
import { Permission } from '../../../interfaces';
import EditCurrencyModal from '../../Modal/EditCurrencyModal/EditCurrencyModal';
import {
  IRenderButton,
  IRenderSplitButton,
  ISavingsSummaryButtonProps
} from './utils';

// TODO: Move component to correct location once folder structure has been refactored
const SavingsSummaryButton: React.FC<ISavingsSummaryButtonProps> = ({
  fetchSavingsSummaries,
  fetchTotals,
  isoCurrencyCodeOptions,
  onCreateEntry,
  onEditLastEntry,
  onViewHistory,
  savingsSummary
}) => {
  const { isAppReadOnly, permissionService } = useUser();

  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const { t: getTextToDisplay } = useTranslation();

  const {
    isoCurrencyCode,
    isReadOnly,
    lastSavingsSummaryAsOfDate,
    isGhosted,
    isUserGhosted
  } = savingsSummary;
  const isEngagementActiveOrRyanUser =
    savingsSummary.isActive || permissionService.isRyan();
  const hasEntries = lastSavingsSummaryAsOfDate != null;
  const hasEntryEditPermission = permissionService.hasPermission(
    Permission.SavingsSummaryEdit
  );
  const hasISOCurrency = isoCurrencyCode != null;
  const isLastEntryToday =
    hasEntries && isSameDay(lastSavingsSummaryAsOfDate as Date, new Date());
  const isSavingsSummaryReadOnly = isReadOnly || isAppReadOnly;
  const ROOT_TO_TEXT = 'savingsSummaryButton';
  const viewSavingsSummaryHistoryButtonProps = {
    // TODO: Variable naming convention requires is/has for booleans
    disabled: !hasEntries,
    onClick: onViewHistory,
    text: getTextToDisplay(`${ROOT_TO_TEXT}.savingsHistory`)
  };

  const renderButton: IRenderButton = renderButtonProps => (
    <Button {...renderButtonProps} block size={EButtonSizes.SMALL} />
  );

  const renderSplitButton: IRenderSplitButton = renderSplitButtonProps => (
    <SplitButton {...renderSplitButtonProps} block size={EButtonSizes.SMALL} />
  );

  return !hasEntryEditPermission || isGhosted || isUserGhosted ? (
    renderButton(viewSavingsSummaryHistoryButtonProps)
  ) : (
    <div className="button-margins">
      {renderButton({
        ...viewSavingsSummaryHistoryButtonProps,
        className: 'd-lg-none'
      })}

      <div className="d-none d-lg-block">
        {!hasISOCurrency &&
          renderButton({
            // TODO: Variable naming convention requires is/has for booleans
            disabled: isSavingsSummaryReadOnly || !isEngagementActiveOrRyanUser,
            onClick: () => setIsCurrencyModalOpen(true),
            text: getTextToDisplay(`${ROOT_TO_TEXT}.setCurrency`)
          })}

        {hasISOCurrency &&
          hasEntries &&
          renderSplitButton({
            disabled: isSavingsSummaryReadOnly || !isEngagementActiveOrRyanUser,
            onClick: isLastEntryToday ? onEditLastEntry : onCreateEntry,
            options: [
              {
                icon: 'time',
                label: getTextToDisplay(`${ROOT_TO_TEXT}.savingsHistory`),
                onClick: onViewHistory
              },
              {
                disabled:
                  isSavingsSummaryReadOnly ||
                  !savingsSummary.lastSavingsSummaryIsEditable ||
                  !isEngagementActiveOrRyanUser,
                icon: 'pencil',
                label: getTextToDisplay(`${ROOT_TO_TEXT}.editLastEntry`),
                onClick: onEditLastEntry
              }
            ],
            text: getTextToDisplay(
              `${ROOT_TO_TEXT}.${
                isLastEntryToday ? 'editTodaysEntry' : 'updateSavings'
              }`
            )
          })}

        {hasISOCurrency &&
          !hasEntries &&
          renderSplitButton({
            disabled: isSavingsSummaryReadOnly || !isEngagementActiveOrRyanUser,
            onClick: onCreateEntry,
            options: [
              {
                disabled:
                  isSavingsSummaryReadOnly || !isEngagementActiveOrRyanUser,
                icon: 'pencil',
                label: getTextToDisplay(`${ROOT_TO_TEXT}.editProjectCurrency`),
                onClick: () => setIsCurrencyModalOpen(true)
              }
            ],
            text: getTextToDisplay('Create')
          })}
      </div>

      {isCurrencyModalOpen && (
        <EditCurrencyModal
          engagementGuid={savingsSummary.engagementGuid}
          isoCurrencyCode={isoCurrencyCode || ''}
          isoCurrencyCodeOptions={isoCurrencyCodeOptions}
          onClose={() => {
            setIsCurrencyModalOpen(false);
          }}
          onSubmit={() => {
            setIsCurrencyModalOpen(false);
            fetchSavingsSummaries({});
            fetchTotals();
          }}
          projectName={savingsSummary.engagementDisplayNameShort}
        />
      )}
    </div>
  );
};

export default SavingsSummaryButton;
