import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, ButtonGroup, EButtonVariant } from '@ryan/components';

import { WithUser, withUser } from '../../../contexts/UserContext';
import {
  Feature,
  FolderSelection,
  IFile,
  IFolder,
  IFolderTree,
  UserType
} from '../../../interfaces';
import { IFileFolderUserVisibilityDirectoryItem } from '../../../interfaces/IFileFolderUserVisibility';
import * as FolderValidationUtils from '../../../utils/folderValidation/folderValidation.utils';
import { formatThirdPartyVisibility } from '../../../utils/formatThirdPartyVisibility';
import Modal from '../../Modal';
import ConfirmationModal from '../../Modal/ConfirmationModal/ConfirmationModal';
import SelectFolder from '../../SelectFolder/SelectFolder';
import { FileBatch } from '../utils/FileBatch';
import { RYAN_INTERNAL } from '../utils/FileDirectoryEnums';

import './FileMoveModal.scss';

interface IFileMoveModalProps extends WithUser {
  files: FileBatch | null;
  folderDirectory: IFolderTree[] | null; // null if loading
  folderPath: IFolder[];
  isFileFolderSearch?: boolean;
  rootName: string; // display name when selection is null
  loading: Promise<any> | null;
  onSubmit: (folder: FolderSelection) => any;
  onCancel: () => void;
}

interface IFileFolderVisibilityMinimal
  extends Omit<IFileFolderUserVisibilityDirectoryItem, 'users'> {
  visibleToUserTypes: number;
  users: Set<string>;
}
interface IFileFolderVisibilitySet {
  [key: string]: IFileFolderVisibilityMinimal;
}

export const FileMoveModal: FunctionComponent<IFileMoveModalProps> = ({
  files,
  folderDirectory,
  folderPath,
  isFeatureToggled,
  rootName,
  loading,
  onSubmit,
  onCancel,
  isFileFolderSearch
}) => {
  const { t: getTextToDisplay } = useTranslation();
  const [destinationFolder, setDestinationFolder] =
    useState<FolderSelection>(null);
  const [currentFolder, setCurrentFolder] = useState<FolderSelection>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(true);
  const [selectFolderError, setSelectFolderError] = useState<string | null>(
    null
  );
  const [fileVisibilityCollection, setFileVisibilityCollection] =
    useState<IFileFolderVisibilitySet>({});
  const [folderVisibilityCollection, setFolderVisibilityCollection] =
    useState<IFileFolderVisibilitySet>({});
  const [showVisibilityWarning, setShowVisibilityWarning] = useState(false);
  const isCreating =
    destinationFolder !== null && destinationFolder.folderGuid === null;

  const hasDifferentVisibility = (
    collection: IFileFolderVisibilitySet
  ): boolean => {
    let isDifferent = false;
    if (destinationFolder === null) {
      return isDifferent;
    }

    const destinationVisibility: IFileFolderVisibilityMinimal = {
      visibleToUserTypes: destinationFolder?.visibleToUserTypes || 0,
      isFolderVisibilityRestricted:
        destinationFolder?.folderUserVisibility?.isFolderVisibilityRestricted ||
        false,
      users: new Set(
        destinationFolder?.folderUserVisibility?.users.map(
          user => user.userGuid || user.memberGuid
        ) || []
      )
    };

    const destinationClientVisibilityString = formatThirdPartyVisibility(
      getTextToDisplay,
      destinationVisibility.visibleToUserTypes
    );

    Object.keys(collection).forEach(key => {
      const collectionClientVisibilityString = formatThirdPartyVisibility(
        getTextToDisplay,
        collection[key].visibleToUserTypes || 0
      );

      if (
        !isSetEquivalent(collection[key].users, destinationVisibility.users) ||
        collectionClientVisibilityString !== destinationClientVisibilityString
      ) {
        isDifferent = true;

        return;
      } else {
        isDifferent = false;

        return;
      }
    });

    return isDifferent;
  };

  const isSetEquivalent = (setA: Set<string>, setB: Set<string>): boolean => {
    if (setA.size !== setB.size) {
      return false;
    }

    const valuesA = Array.from(setA.values());

    for (const index in valuesA) {
      if (!setB.has(valuesA[index])) {
        return false;
      }
    }

    return true;
  };

  const handleSave = async () => {
    const hasRestrictedFolders =
      Object.keys(folderVisibilityCollection).length > 0;
    const hasRestrictedFiles = Object.keys(fileVisibilityCollection).length > 0;
    const isSourceRestricted = hasRestrictedFolders || hasRestrictedFiles;
    const isDestinationRestricted =
      !!destinationFolder?.folderUserVisibility?.isFolderVisibilityRestricted ||
      ((destinationFolder?.visibleToUserTypes || 0) & UserType.ThirdParty) !==
        0;
    const isDestinationRyanInternal =
      (destinationFolder?.visibleToUserTypes || 0) === UserType.Ryan;

    // Case 1: Restricted source to Ryan Internal destination - show visibility warning
    if (isSourceRestricted && isDestinationRyanInternal) {
      setShowVisibilityWarning(true);
      setIsMoveModalOpen(false);
      return;
    }

    // Case 2: Both source and destination are restricted - check if visibility differs
    if (isSourceRestricted && isDestinationRestricted) {
      const isDifferent =
        hasDifferentVisibility(folderVisibilityCollection) ||
        hasDifferentVisibility(fileVisibilityCollection);

      if (isDifferent) {
        setShowVisibilityWarning(true);
        setIsMoveModalOpen(false);
        return;
      }
    }

    // Case 3: Moving restricted files to unrestricted destination - show warning
    else if (hasRestrictedFiles && !isDestinationRestricted) {
      setShowVisibilityWarning(true);
      setIsMoveModalOpen(false);
      return;
    }

    // Case 4: Moving only restricted folders to unrestricted destination - no warning needed
    const folderNameError = await onSubmit(destinationFolder).then(() =>
      setIsMoveModalOpen(false)
    );

    if (folderNameError) {
      setSelectFolderError(folderNameError);
    }
  };

  const isInternalFilesFunctionalityVisible = isFeatureToggled(
    Feature.InternalFiles
  );

  const handleVisibilitySave = async () => {
    const folderNameError = await onSubmit(destinationFolder);

    if (folderNameError) {
      setSelectFolderError(folderNameError);
    }
  };

  // a list of selected folders to be moved; prevent selected folders from
  // being moved within themselves
  const disabledFolders: string[] = useMemo(
    () => files?.folders.map(folder => folder.folderGuid) || [],
    [files]
  );

  const foldersWithoutInternal = (folderDirectory || []).filter(
    folder => folder.folderName !== RYAN_INTERNAL
  );

  // check if moving internal files or folders to external folder
  const checkIfMovingInternalFiles = () =>
    (files!.folders.some(
      folder => folder.folderVisibleToUserTypes === UserType.Ryan
    ) ||
      files!.files.some(file => file.visibleToUserTypes === UserType.Ryan)) &&
    destinationFolder?.folderVisibleToUserTypes !== UserType.Ryan;

  const checkIfMovingLockedFiles = () =>
    files!.files.some(file => file.isLocked === true);

  useEffect(() => {
    if (files !== null) {
      if (folderPath.length > 0) {
        const {
          parentFolderGuid,
          folderGuid,
          folderName,
          folderVisibleToUserTypes,
          folderUserVisibility,
          visibleToUserTypes
        } = folderPath[folderPath.length - 1];
        const folder: FolderSelection = {
          parentFolderGuid,
          folderGuid,
          folderName,
          folderVisibleToUserTypes,
          folderUserVisibility,
          visibleToUserTypes: visibleToUserTypes
        };

        setCurrentFolder(folder);
        setDestinationFolder(folder);
      }

      setFileVisibilityCollection(
        files.files.reduce((acc, file: IFile) => {
          const thirdPartyVisibilityString = formatThirdPartyVisibility(
            getTextToDisplay,
            file.visibleToUserTypes || 0
          );
          if (
            file.folderUserVisibility &&
            (file.folderUserVisibility.users.length > 0 ||
              thirdPartyVisibilityString.length > 0)
          ) {
            return {
              ...acc,
              [file.documentGuid]: {
                visibleToUserTypes: file.visibleToUserTypes,
                users: new Set(
                  file.folderUserVisibility.users.map(
                    user => user.userGuid || user.memberGuid
                  )
                ),
                isFolderVisibilityRestricted:
                  file.folderUserVisibility.isFolderVisibilityRestricted
              } as IFileFolderVisibilityMinimal
            };
          } else {
            return { ...acc };
          }
        }, {})
      );

      setFolderVisibilityCollection(
        files.folders.reduce((acc, folder: IFolder) => {
          const thirdPartyVisibilityString = formatThirdPartyVisibility(
            getTextToDisplay,
            folder.visibleToUserTypes || 0
          );
          if (
            folder.folderUserVisibility &&
            (folder.folderUserVisibility.users.length > 0 ||
              thirdPartyVisibilityString.length > 0)
          ) {
            return {
              ...acc,
              [folder.folderGuid]: {
                visibleToUserTypes: folder.visibleToUserTypes,
                users: new Set(
                  folder.folderUserVisibility.users.map(
                    user => user.userGuid || user.memberGuid
                  )
                ),
                isFolderVisibilityRestricted:
                  folder.folderUserVisibility.isFolderVisibilityRestricted
              } as IFileFolderVisibilityMinimal
            };
          } else {
            return { ...acc };
          }
        }, {})
      );
    }
  }, [files, folderPath]);

  const onSelectFolderChange = (folder: FolderSelection) => {
    if (selectFolderError) {
      setSelectFolderError(null);
    }

    const errorPath = FolderValidationUtils.validateFolderInput(
      folder,
      folderDirectory
    ) as string;

    if (Boolean(errorPath)) {
      setSelectFolderError(getTextToDisplay(errorPath));
    }

    setDestinationFolder(folder);
  };

  const isDisableMoveButton = isFileFolderSearch
    ? false
    : (destinationFolder === null && currentFolder === null) ||
      destinationFolder?.folderGuid === currentFolder?.folderGuid;

  if (files) {
    return (
      <>
        <Modal
          className="file-move-modal"
          onClose={onCancel}
          open={isMoveModalOpen}
          title={getTextToDisplay('file.moveModal.title', {
            count: files.filesCount
          })}
        >
          {folderDirectory ? (
            <SelectFolder
              autoOpen
              disabled={loading !== null}
              disabledFolders={disabledFolders}
              feedback={selectFolderError}
              folders={
                checkIfMovingLockedFiles() ||
                !isInternalFilesFunctionalityVisible
                  ? foldersWithoutInternal
                  : folderDirectory
              }
              invalid={selectFolderError !== null}
              moveFileCount={files.filesCount}
              onChange={onSelectFolderChange}
              rootName={rootName}
              value={destinationFolder}
            />
          ) : (
            <div>
              <div className="ry-skeleton sk-field-label" />
              <div className="ry-skeleton sk-field" />
            </div>
          )}
          <ButtonGroup>
            <Button
              disabled={selectFolderError !== null || isDisableMoveButton}
              loading={loading}
              onClick={() => {
                if (checkIfMovingInternalFiles()) {
                  setIsConfirmationModalOpen(true);
                  setIsMoveModalOpen(false);
                } else {
                  handleSave();
                }
              }}
              text={getTextToDisplay(isCreating ? 'Done' : 'Move')}
              type="submit"
              variant={EButtonVariant.PRIMARY}
            />
            <Button
              disabled={loading !== null}
              onClick={onCancel}
              text={getTextToDisplay('Cancel')}
              variant={EButtonVariant.SECONDARY}
            />
          </ButtonGroup>
        </Modal>
        {isConfirmationModalOpen && (
          <ConfirmationModal
            cancelTextToDisplay={getTextToDisplay('Cancel')}
            confirmationMessage={getTextToDisplay(
              files.filesCount > 0
                ? files.filesCount > 1
                  ? 'file.moveModal.externalMovePluralDescription'
                  : 'file.moveModal.externalMoveSingleDescription'
                : files.folders.length > 1
                ? 'file.moveModal.externalMoveFolderPluralDescription'
                : 'file.moveModal.externalMoveFolderDescription'
            )}
            formattedTranslation={
              <Trans
                components={{ 1: <strong /> }}
                i18nKey={
                  files.filesCount > 0
                    ? files.filesCount > 1
                      ? 'file.moveModal.externalMovePluralDescription'
                      : 'file.moveModal.externalMoveSingleDescription'
                    : files.folders.length > 1
                    ? 'file.moveModal.externalMoveFolderPluralDescription'
                    : 'file.moveModal.externalMoveFolderDescription'
                }
              />
            }
            isPositive
            onClose={() => {
              setIsConfirmationModalOpen(false);
              setIsMoveModalOpen(true);
            }}
            onSubmit={() => handleSave().then(() => setIsMoveModalOpen(false))}
            submitTextToDisplay={getTextToDisplay('file.moveModal.move')}
            title={getTextToDisplay(
              files.filesCount > 0
                ? files.filesCount > 1
                  ? 'file.moveModal.externalMovePluralTitle'
                  : 'file.moveModal.externalMoveSingleTitle'
                : files.folders.length > 1
                ? 'file.moveModal.externalMoveFolderPluralTitle'
                : 'file.moveModal.externalMoveFolderTitle',
              {
                count: files.filesCount,
                destinationName: destinationFolder?.folderName ?? rootName,
                folderCount: files.folders.length
              }
            )}
          />
        )}
        {showVisibilityWarning && (
          <ConfirmationModal
            cancelTextToDisplay={getTextToDisplay('Cancel')}
            confirmationMessage={
              files.filesCount > 1 || files.filesCount === 0
                ? getTextToDisplay(
                    'file.moveModal.confirmationModal.contentPlural'
                  )
                : getTextToDisplay(
                    'file.moveModal.confirmationModal.contentSingular'
                  )
            }
            formattedTranslation={
              <Trans
                components={{ 1: <strong /> }}
                i18nKey={
                  files.filesCount > 1 || files.filesCount === 0
                    ? 'file.moveModal.confirmationModal.contentPlural'
                    : 'file.moveModal.confirmationModal.contentSingular'
                }
              />
            }
            isPositive
            onClose={() => {
              setShowVisibilityWarning(false);
              setIsMoveModalOpen(true);
            }}
            onSubmit={() => {
              handleVisibilitySave().then(() => {
                setShowVisibilityWarning(false);
              });
            }}
            submitTextToDisplay={
              files.filesCount > 1 || files.filesCount === 0
                ? getTextToDisplay(
                    'file.moveModal.confirmationModal.submitButtonPlural'
                  )
                : getTextToDisplay(
                    'file.moveModal.confirmationModal.submitButtonSingular'
                  )
            }
            title={
              files.filesCount > 1 || files.filesCount === 0
                ? getTextToDisplay(
                    'file.moveModal.confirmationModal.titlePlural',
                    {
                      fileCount: files.filesCount,
                      destinationFolderName:
                        destinationFolder?.folderName ?? rootName
                    }
                  )
                : getTextToDisplay(
                    'file.moveModal.confirmationModal.titleSingular',
                    {
                      destinationFolderName:
                        destinationFolder?.folderName ?? rootName
                    }
                  )
            }
          />
        )}
      </>
    );
  }

  return null;
};

export default withUser(FileMoveModal);
