import {
  NotificationPreferenceType,
  INotificationPreference as Pref,
  INotificationPreferenceGroup as PrefGroup
} from 'interfaces';

import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  EButtonSizes,
  EButtonVariant,
  Icon
} from '@ryan/components';

import { useUnsavedChanges } from '../../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import { NotificationPreferenceCardSkeleton } from './NotificationPreferenceCardSkeleton';

import './NotificationPreferencesCard.scss';

interface Props {
  prefs: PrefGroup[] | null;
  savingPrefs: Promise<void> | null;
  onGroupEmailChecked: (checked: boolean, pref: PrefGroup) => void;
  onPrefEmailChecked: (checked: boolean, pref: Pref) => void;
  onSavePrefs: (callback: () => void) => void;
}

const NotificationPreferencesCard: FunctionComponent<Props> = ({
  prefs,
  savingPrefs,
  onGroupEmailChecked,
  onPrefEmailChecked,
  onSavePrefs
}) => {
  const { t: getTextToDisplay } = useTranslation();
  const { setIsUnsavedChanges } = useUnsavedChanges();
  const [expandedGroups, setExpandedGroups] = useState<{
    [key: string]: boolean;
  }>({});

  const history = useHistory();

  function onExpandGroup(entityTypeId: NotificationPreferenceType) {
    const key = `${entityTypeId}`;
    setExpandedGroups({ ...expandedGroups, [key]: !expandedGroups[key] });
  }

  const onInputChange = () => {
    setIsUnsavedChanges(true);
  };

  const handleGoBack = () => {
    setIsUnsavedChanges(false);
    history.goBack();
  };

  return (
    <Card
      className="notification-preferences-card"
      role="region"
      title={getTextToDisplay('activity.notifications.title')}
    >
      <div className="notification-preferences-card__options">
        <div>
          <Icon name="mail" />
          <span>{getTextToDisplay('Email')}</span>
        </div>
      </div>
      <hr />
      {prefs === null ? (
        <NotificationPreferenceCardSkeleton />
      ) : (
        <>
          <table className="notification-preferences-card__table">
            {prefs.map(pg => {
              const pgp = pg.userPreferences;
              const allEmail = pgp.every(p => p.sendEmail);
              const someEmail = pgp.some(p => p.sendEmail);
              const expanded = expandedGroups[pg.entityTypeId];

              return (
                <tbody key={pg.entityTypeId}>
                  <tr>
                    <td>
                      <Button
                        icon={expanded ? 'chevron-up' : 'chevron-down'}
                        onClick={() => onExpandGroup(pg.entityTypeId)}
                        variant={EButtonVariant.TEXT}
                      />
                    </td>
                    <td
                      className="label"
                      onClick={() => onExpandGroup(pg.entityTypeId)}
                    >
                      {getTextToDisplay(
                        `profile.notificationPreferences.label.${pg.entityTypeId}`
                      )}
                    </td>
                    <td>
                      <Checkbox
                        checked={allEmail}
                        indeterminate={!allEmail && someEmail}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          onInputChange();
                          onGroupEmailChecked(e.target.checked, pg);
                        }}
                        value="email"
                      />
                    </td>
                  </tr>
                  {expanded &&
                    pgp.map(pref => (
                      <tr
                        className="preference-row"
                        key={pref.notificationPreferenceGuid}
                      >
                        <td>{/* empty, the expand column */}</td>
                        <td>
                          {getTextToDisplay(
                            `profile.notificationPreferences.description.${pref.notificationPreferenceGuid}`
                          )}
                        </td>
                        <td>
                          <Checkbox
                            checked={pref.sendEmail}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              onInputChange();
                              onPrefEmailChecked(e.target.checked, pref);
                            }}
                            value={`${pref.notificationPreferenceGuid}:email`}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              );
            })}
          </table>
          <div className="notification-preferences-card__update-button">
            <ButtonGroup>
              <Button
                loading={savingPrefs}
                onClick={() => {
                  onSavePrefs(() => setIsUnsavedChanges(false));
                }}
                size={EButtonSizes.LARGE}
                text={getTextToDisplay('Update')}
                type="submit"
                variant={EButtonVariant.PRIMARY}
              />
              <Button
                onClick={() => handleGoBack()}
                size={EButtonSizes.LARGE}
                text={getTextToDisplay('Cancel')}
                variant={EButtonVariant.SECONDARY}
              />
            </ButtonGroup>
          </div>
        </>
      )}
    </Card>
  );
};

export default NotificationPreferencesCard;
