import { mapItemToSingleProjectDataRequest } from 'components/Table/Columns/SingleProjectDataRequests/useSingleProjectDataRequestColumns.utils';
import camelCase from 'lodash/camelCase';

import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  EMessageTypes,
  Pagination,
  SplitButton,
  Toggle,
  Toggles,
  Tooltip,
  makeTableCheckboxFilter,
  pushToast
} from '@ryan/components';

import {
  WithDataRequestDrawer,
  withDataRequestDrawer
} from '../../contexts/DataRequestDrawerContext';
import EngagementContext from '../../contexts/EngagementContext';
import { WithUser, withUser } from '../../contexts/UserContext';
import {
  DataRequestStatusGroup,
  IDataRequest,
  IDataRequestV2,
  IEngagement,
  ITableState,
  Permission,
  Status
} from '../../interfaces';
import ApiService, { CancelTokenSource } from '../../services/ApiService';
import debouncedSearch from '../../utils/debouncedSearch';
import { DataRequestTypeGuidEnum } from '../../utils/enums/DataRequestsEnums';
import { StatusAndSubstatus } from '../../utils/enums/StatusAndSubstatus';
import { SubStatusEnums } from '../../utils/enums/SubStatusEnums';
import { formatDate } from '../../utils/formatDate';
import getCommentButtonProps from '../../utils/getCommentButtonProps';
import getSortParam from '../../utils/getSortParm';
import { isDataRequestPastDue } from '../../utils/isPastDue';
import isTableFilterActive from '../../utils/isTableFilterActive';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import switcherDidUpdate from '../../utils/switcherDidUpdate';
import DataRequestActions from '../DataRequestActions/DataRequestActions';
import DataRequestCard, { DataRequestCardSkeleton } from '../DataRequestCard';
import Empty from '../Empty';
import FollowersModal from '../FollowersModal/FollowersModal';
import DataRequestModal from '../Modal/DataRequestModal/DataRequestModal';
import SearchInput from '../SearchInput';
import StatusIcon from '../StatusIcon/StatusIcon';
import Table from '../Table';
import { TDataRequest } from '../Table/Columns/SingleProjectDataRequests/useSingleProjectDataRequestColumns.interfaces';
import DataRequestExpandedRow, {
  mapToDataRequestExpandedRow
} from '../Table/ExpandedRow/Worklist/DataRequestExpandedRow/DataRequestExpandedRow';
import TableEmpty from '../TableEmpty/TableEmpty';
import SingleProjectDataRequests from './SingleProjectDataRequest';
import orderByArray from './utils/orderByArray';

import './DataRequests.scss';

enum Layout {
  List,
  Card
}

interface IDataRequestsProps
  extends WithTranslation,
    WithUser,
    WithDataRequestDrawer,
    RouteComponentProps {
  engagement?: IEngagement;
}

interface IDataRequestsState extends ITableState {
  layout: Layout;
  dataRequests: IDataRequest[];
  dataRequestToUpdateFollowers: TDataRequest | null;
  openDataRequestModal: boolean;
  createButtonDisabled: boolean;
}

// Assumes Permission.DataRequestsView
export class DataRequests extends PureComponent<
  IDataRequestsProps,
  IDataRequestsState
> {
  // axios cancel token source
  private source?: CancelTokenSource;

  private _isMounted = false;

  private columns: any[];

  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  readonly state: IDataRequestsState = {
    layout: Layout.List,
    openDataRequestModal: false,
    createButtonDisabled: true,
    loading: false,
    dataRequests: [],
    dataRequestToUpdateFollowers: null,

    searchQuery: '',
    filtered: {
      dataType: [],
      status: []
    },
    sorted: {
      id: 'dueDate',
      desc: false
    },
    expanded: {},

    page: 1,
    pageSize: 10,
    totalCount: 0
  };

  constructor(props: IDataRequestsProps) {
    super(props);

    const { engagement, t: getTextToDisplay } = this.props;

    const columnOrder = [
      'title',
      'engagementDisplayNameShort',
      'dataType',
      'status',
      'assignedToName',
      'createdByName',
      'transferredFilesCount',
      'dueDate',
      'comment',
      'actions'
    ];

    const columns: Record<string, any> = {
      actions: {
        align: 'center',
        id: 'actions',
        label: '',
        render: (row: IDataRequestV2) => {
          const { isAppReadOnly, permissionService: ps } = this.props;

          let button;
          let hasMarkDataDeliveredOption = false;
          if (ps.hasPermission(Permission.DataRequestsEdit)) {
            button = (
              <DataRequestActions
                dataRequest={row}
                onUpdate={this.handleRefresh}
                size={EButtonSizes.SMALL}
              />
            );
          } else {
            const statusId = row.statusId;
            const isContributor = ps.hasPermission(
              Permission.DataRequestsContribute
            );
            const isEditor = ps.hasPermission(Permission.DataRequestsEdit);
            const isRyanUser = ps.isRyan();
            const isInProgressDataDelivered =
              statusId === Status.InProgress &&
              row.subStatusId === SubStatusEnums.DataDelivered;

            let buttonText;
            switch (statusId) {
              case Status.Todo:
                buttonText = isContributor && !isEditor ? 'Review' : 'View';
                break;
              case Status.InProgress:
                hasMarkDataDeliveredOption =
                  !row.markAsDataDelivered &&
                  ps.hasPermission(Permission.DataRequestsContribute);
                buttonText = isContributor ? 'Transfer Data' : 'View';
                break;
              case Status.Complete:
                buttonText = 'View';
                break;
              default:
                buttonText = 'View';
                break;
            }

            if (
              isRyanUser &&
              statusId !== Status.Complete &&
              (isContributor || isEditor)
            ) {
              buttonText = 'Review';
            }

            if (!isRyanUser && isContributor) {
              if (!isInProgressDataDelivered && statusId !== Status.Complete) {
                buttonText = 'Transfer Data';
              }

              if (isInProgressDataDelivered) {
                buttonText = 'View';
              }
            }

            const disabled = row.isEngagementReadOnly || isAppReadOnly;
            if (
              statusId === Status.InProgress &&
              isContributor &&
              hasMarkDataDeliveredOption
            ) {
              const options = [
                {
                  disabled,
                  icon: 'clipboard-check',
                  label: getTextToDisplay(
                    'dataRequest.markDataDeliveredButtonName'
                  ),
                  onClick: () => this.handleMarkDataDelivered(row)
                }
              ];

              button = (
                <SplitButton
                  block
                  onClick={() => {
                    this.props.history.push(
                      `/app/data-request/${row.queueItemGuid}`
                    );
                  }}
                  options={options}
                  size={EButtonSizes.SMALL}
                  text={getTextToDisplay(buttonText)}
                />
              );
            } else {
              button = (
                <Button
                  block
                  onClick={() => {
                    this.props.history.push(
                      `/app/data-request/${row.queueItemGuid}`
                    );
                  }}
                  size={EButtonSizes.SMALL}
                  text={getTextToDisplay(buttonText)}
                />
              );
            }
          }

          return <div className="button-margins">{button}</div>;
        }
      },
      assignedToName: {
        id: 'assignedToName',
        label: getTextToDisplay('dataRequest.columns.assignedTo'),
        render: (row: IDataRequest) => {
          const {
            t,
            user: {
              profile: { userGuid }
            }
          } = this.props;
          return row.assignedToUserGuid === userGuid
            ? t('You')
            : row.assignedToName;
        },
        sortable: true
      },
      comment: {
        align: 'center',
        id: 'comment',
        label: '',
        render: (row: IDataRequestV2) => {
          const { t, isAppReadOnly, permissionService: ps } = this.props;
          const { text, icon } = getCommentButtonProps(
            t,
            ps.hasPermission(Permission.DataRequestsContribute),
            row,
            isAppReadOnly
          );

          return (
            <Tooltip
              content={text}
              placement="top"
              renderTarget={({ open, ref, ...props }) => (
                <Button
                  {...props}
                  aria-expanded={open}
                  aria-haspopup="true"
                  className="button-margins"
                  icon={icon}
                  innerRef={ref}
                  onClick={this.handleDrawerOpenComments(row)}
                  size={EButtonSizes.SMALL}
                  variant={EButtonVariant.TEXT}
                />
              )}
            />
          );
        }
      },
      dataType: {
        filter: makeTableCheckboxFilter([
          {
            label: getTextToDisplay('dataRequest.dataTypes.auditWorkpapers'),
            value: DataRequestTypeGuidEnum.AUDIT_WORKPAPERS
          },
          {
            label: getTextToDisplay('dataRequest.dataTypes.documentImages'),
            value: DataRequestTypeGuidEnum.DOCUMENT_IMAGES
          },
          {
            label: getTextToDisplay('dataRequest.dataTypes.erpData'),
            value: DataRequestTypeGuidEnum.ERP_DATA
          },
          {
            label: getTextToDisplay(
              'dataRequest.dataTypes.organizationalChart'
            ),
            value: DataRequestTypeGuidEnum.ORGANIZATIONAL_CHART
          },
          {
            label: getTextToDisplay('dataRequest.dataTypes.siteInformation'),
            value: DataRequestTypeGuidEnum.SITE_INFORMATION
          },
          {
            label: getTextToDisplay('dataRequest.dataTypes.taxReturns'),
            value: DataRequestTypeGuidEnum.TAX_RETURNS
          },
          {
            label: getTextToDisplay('dataRequest.dataTypes.other'),
            value: DataRequestTypeGuidEnum.OTHER
          }
        ]),
        filterActive: (value: string[]) => value.length > 0,
        id: 'dataType',
        label: getTextToDisplay('dataRequest.columns.dataType'),
        render: (row: IDataRequest) =>
          getTextToDisplay(
            `dataRequest.dataTypes.${camelCase(row.dataRequestTypeName ?? '')}`
          )
      },
      dueDate: {
        id: 'dueDate',
        label: getTextToDisplay('dataRequest.columns.dueDate'),
        render: (row: IDataRequest) => (
          <span className="duedate">{formatDate(row.dueDate)}</span>
        ),
        sortable: true
      },
      status: {
        filter: makeTableCheckboxFilter([
          {
            label: getTextToDisplay('To Do'),
            value: StatusAndSubstatus.ToDo
          },
          {
            label: getTextToDisplay('In Progress'),
            value: StatusAndSubstatus.InProgress
          },
          {
            label: `${getTextToDisplay('In Progress')} — ${getTextToDisplay(
              `dataRequest.subStatus.DataDelivered`
            )}`,
            value: StatusAndSubstatus.InProgressDataDelivered
          },
          {
            label: getTextToDisplay('Complete'),
            value: StatusAndSubstatus.Complete
          }
        ]),
        filterActive: (value: string[]) => value.length > 0,
        id: 'status',
        label: getTextToDisplay('dataRequest.columns.status'),
        render: (row: IDataRequest) =>
          Object.values(Status).includes(row.statusId) && (
            <StatusIcon status={row.statusId} subStatus={row.subStatusId} />
          )
      },
      title: {
        id: 'title',
        label: getTextToDisplay('dataRequest.columns.title'),
        render: (row: IDataRequest) => {
          const { engagement } = this.props;

          return (
            <Link className="bs" to={`/app/data-request/${row.queueItemGuid}`}>
              <b>{row.title}</b>
              {!engagement && <small>{row.accountName}</small>}
            </Link>
          );
        },
        sortable: true
      }
    };

    if (engagement) {
      // If Single Project Data Requests, add the following columns
      columns['transferredFilesCount'] = {
        id: 'transferredFilesCount',
        label: getTextToDisplay('dataRequest.columns.transferredFiles'),
        render: (row: IDataRequest) => {
          return row.transferredFilesCount;
        },
        sortable: true
      };
    } else {
      // If All Projects Data Requests, add the following columns
      columns['engagementDisplayNameShort'] = {
        id: 'engagementDisplayNameShort',
        label: getTextToDisplay(
          'dataRequest.columns.engagementDisplayNameShort'
        ),
        render: (row: IDataRequest) => {
          const { engagement, permissionService } = this.props;

          return (
            <Link
              className="table-link bs"
              to={`/app/project/${row.engagementGuid}`}
            >
              <span>{row.engagementDisplayNameShort}</span>
              {!engagement && permissionService.isRyan() && (
                <small>{row.engagementId}</small>
              )}
            </Link>
          );
        },
        sortable: true
      };
    }

    this.columns = Object.keys(columns)
      .sort(orderByArray(columnOrder))
      .map((column: string) => columns[column]);
  }

  handleToggleExpansion = (
    expanded: boolean,
    _row: IDataRequest,
    rowId: string
  ) => {
    this.setState(state => ({
      expanded: { ...state.expanded, [rowId]: expanded }
    }));
  };

  /**
   * Paginated Data Requests...
   */

  fetchDataRequests = (updates?: Partial<IDataRequestsState>) => {
    const {
      activeView: { customViewGuid },
      engagement,
      t: getTextToDisplay
    } = this.props;

    this.setState(
      { ...(updates as IDataRequestsState), loading: true },
      async () => {
        const { searchQuery, filtered, sorted, page, pageSize } = this.state;

        const params: any = {
          searchTerm: searchQuery,
          status: filtered!.status.join(','),
          sort: getSortParam(sorted),
          dataTypes: filtered!.dataType.join(','),
          pageNumber: page,
          itemsPerPage: pageSize
        };

        try {
          // cancel any previous requests and save new token for current request
          this.source?.cancel('cancelling previous data request fetch');
          this.source = ApiService.CancelToken.source();

          const request =
            engagement !== undefined
              ? ApiService.getEngagementDataRequests(
                  engagement.engagementGuid,
                  params,
                  this.source?.token
                )
              : ApiService.getCustomViewDataRequests(
                  customViewGuid,
                  params,
                  this.source?.token
                );

          const {
            data: { results, totalResults }
          } = await request;
          this.setState({
            dataRequests: results,
            totalCount: totalResults
          });
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushToast({
              content: getTextToDisplay('serverError.content'),
              title: getTextToDisplay('serverError.title'),
              type: EMessageTypes.ERROR
            });
          }
        } finally {
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        }
      }
    );
  };

  handleSearch = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      this.fetchDataRequests({ page: 1, searchQuery });
    }
  );

  handleSort = (sorted: any) => {
    this.fetchDataRequests({ sorted });
  };

  handleFilter = (filtered: any) => {
    this.fetchDataRequests({ filtered, page: 1 });
  };

  handlePage = (page: number, pageSize: number) => {
    this.fetchDataRequests({ page, pageSize });
  };

  /**
   * Data Request drawer with history and comments.
   */

  handleDrawerOpenHistory = (dataRequest: IDataRequestV2) => () => {
    const { onDataRequestDrawerOpenHistory } = this.props;
    onDataRequestDrawerOpenHistory(dataRequest);
  };

  handleDrawerOpenComments = (dataRequest: IDataRequestV2) => () => {
    const { onDataRequestDrawerOpenComments } = this.props;

    onDataRequestDrawerOpenComments(dataRequest);
  };

  handleMarkDataDelivered = async (dataRequest: IDataRequestV2) => {
    const { t: getTextToDisplay } = this.props;
    if (!dataRequest) return;

    try {
      const { data } = await ApiService.markDataRequestDataDelivered({
        markAsDataDelivered: true,
        queueItemGuid: dataRequest.queueItemGuid,
        status: dataRequest.statusId,
        subStatus: SubStatusEnums.DataDelivered
      });

      pushToast({
        type: EMessageTypes.SUCCESS,
        content: getTextToDisplay(
          'dataRequest.markDataDeliveredSuccessContent',
          {
            title: data.title
          }
        )
      });
      this.handleRefresh();
    } catch (error) {
      pushServerErrorToast();
    }
  };

  /**
   * Lifecycles.
   */

  componentDidMount() {
    this._isMounted = true;
    const { dataRequestDrawerEvents } = this.props;
    this.fetchDataRequests();
    this.checkIfUserIsReadOnly();
    dataRequestDrawerEvents.addListener(
      'commentAdded',
      this.handleCommentAdded
    );
    dataRequestDrawerEvents.addListener(
      'commentEdited',
      this.handleCommentEdited
    );
    dataRequestDrawerEvents.addListener(
      'commentRemoved',
      this.handleCommentRemoved
    );
  }

  checkIfUserIsReadOnly() {
    const { customViewGuid } = this.props.activeView;
    const { userGuid } = this.props.user.profile;
    const { t } = this.props;

    if (customViewGuid && userGuid) {
      ApiService.getEngagementsForCustomViewByUser(customViewGuid, userGuid, {})
        .then(({ data }) => {
          const results = data.results;
          const isUserGhosted =
            results.filter(item => !item.isUserGhosted).length === 0;
          this.setState({ createButtonDisabled: isUserGhosted });
        })
        .catch(error => {
          if (error?.response?.status === 410) {
            pushToast({
              type: 'error',
              title: t('serverError.title'),
              content: t('serverError.content')
            });
          }
        });
    }
  }

  componentDidUpdate(prevProps: IDataRequestsProps) {
    if (switcherDidUpdate(prevProps, this.props)) {
      this.fetchDataRequests({ page: 1 });
      this.checkIfUserIsReadOnly();
    }
  }

  componentWillUnmount() {
    const { dataRequestDrawerEvents } = this.props;

    // cancel any ongoing requests
    this.source?.cancel();

    dataRequestDrawerEvents.removeListener(
      'commentAdded',
      this.handleCommentAdded
    );
    dataRequestDrawerEvents.removeListener(
      'commentEdited',
      this.handleCommentEdited
    );
    dataRequestDrawerEvents.removeListener(
      'commentRemoved',
      this.handleCommentRemoved
    );

    this._isMounted = false;
  }

  /**
   * Handlers.
   */

  handleCommentAdded = (eventData: any) => {
    this.fetchDataRequests();
    this.context.refreshUpdateDate?.(eventData?.engagementGuid);
  };

  handleCommentEdited = (eventData: any) => {
    this.context.refreshUpdateDate?.(eventData?.engagementGuid);
  };

  handleCommentRemoved = (eventData: any) => {
    this.fetchDataRequests();
  };

  handleNewDataRequest = () => {
    const { permissionService } = this.props;
    if (permissionService.hasPermission(Permission.DataRequestsEdit)) {
      this.setState({ openDataRequestModal: true });
    }
  };

  handleDataRequestModalClose = (dataRequest?: IDataRequestV2) => {
    if (dataRequest) {
      this.fetchDataRequests();
    }

    this.setState({ openDataRequestModal: false });
  };

  handleRefresh = () => {
    this.fetchDataRequests();
  };

  getRowClassName = (row: Omit<IDataRequestV2, 'dateRange'>) =>
    isDataRequestPastDue(row) ? 'pastdue' : '';

  canCreateNewDataRequests() {
    const { engagement, isAppReadOnly } = this.props;
    return (
      (!engagement?.isReadOnly && !isAppReadOnly) || !engagement?.isUserGhosted
    );
  }

  renderExpandedRow = (row: Omit<IDataRequestV2, 'dateRange'>) => (
    <DataRequestCard
      dataRequest={row}
      hideActions
      onOpenComments={this.handleDrawerOpenComments(row)}
      onOpenHistory={this.handleDrawerOpenHistory(row)}
      onUpdate={this.handleRefresh}
    />
  );

  render() {
    const {
      activeView,
      engagement,
      permissionService,
      t: getTextToDisplay
    } = this.props;
    const canEdit = permissionService.hasPermission(
      Permission.DataRequestsEdit
    );

    const {
      layout,
      loading,
      dataRequests,
      searchQuery,
      filtered,
      sorted,
      expanded,
      page,
      pageSize,
      totalCount
    } = this.state;

    return (
      <div className="data-requests-page">
        <div className="data-requests-page__header">
          <div className="data-requests-page__header-view-toggle">
            <Toggles>
              <Toggle
                active={layout === Layout.List}
                icon="layout-list"
                onClick={() => this.setState({ layout: Layout.List })}
              />
              <Toggle
                active={layout === Layout.Card}
                icon="layout-card"
                onClick={() => this.setState({ layout: Layout.Card })}
              />
            </Toggles>
          </div>

          {canEdit && (
            <Button
              disabled={
                !this.canCreateNewDataRequests() ||
                this.state.createButtonDisabled ||
                activeView.isExecutiveView ||
                engagement
                  ? !engagement?.isActive
                  : false
              }
              icon="plus"
              onClick={() => {
                this.handleNewDataRequest();
              }}
              size={EButtonSizes.SMALL}
              text={getTextToDisplay('dataRequest.new')}
              variant={EButtonVariant.TEXT}
            />
          )}

          <SearchInput
            loading={loading}
            onChange={this.handleSearch}
            value={searchQuery}
          />
        </div>

        {/* // TODO: Table Refactor - Trial on Single Project Data Request tab */}
        {layout === Layout.List && engagement && (
          <SingleProjectDataRequests
            data={dataRequests.map(item =>
              mapItemToSingleProjectDataRequest(item)
            )}
            expanded={expanded}
            filtered={filtered}
            groupBy="statusGroupId"
            groupByClassName={(statusGroupId: number) =>
              DataRequestStatusGroup[statusGroupId]
            }
            loading={loading}
            onFilterChange={this.handleFilter}
            onHandleRefresh={this.handleRefresh}
            onPageChange={this.handlePage}
            onSortChange={this.handleSort}
            onToggleExpansion={this.handleToggleExpansion}
            page={page}
            pageSize={pageSize}
            renderEmpty={() => (
              <TableEmpty
                filterActive={isTableFilterActive(this.columns, filtered)}
                searchQuery={searchQuery}
              >
                <Empty icon="file">
                  {getTextToDisplay('dataRequest.empty', {
                    context: permissionService.isRyan() ? 'ryan' : 'client'
                  })}
                </Empty>
              </TableEmpty>
            )}
            renderExpandedRow={(
              row: any /* TODO: Table Refactor: Assign type after test */
            ) => (
              <DataRequestExpandedRow
                dataRequestDetails={mapToDataRequestExpandedRow(row)}
                isDisabled={loading}
                onOpenDataRequestCommentDrawer={() => {
                  this.handleDrawerOpenComments(row)();
                }}
                onOpenDataRequestHistoryDrawer={() => {
                  this.handleDrawerOpenHistory(row)();
                }}
                onOpenFollowersModal={() => {
                  this.setState({
                    dataRequestToUpdateFollowers: row
                  });
                }}
                setIsLoading={() => {
                  this.handleRefresh();
                }}
                setIsUpdatingFollowStatus={() => {}}
              />
            )}
            renderGroupHeader={(statusGroupId: number) =>
              getTextToDisplay(`dataRequest.status.${statusGroupId}`)
            }
            rowClassName={this.getRowClassName}
            rowId="queueItemGuid"
            sorted={sorted}
            totalCount={totalCount}
          />
        )}

        {layout === Layout.List && !engagement && (
          <Table<IDataRequest, string>
            columns={this.columns}
            data={dataRequests}
            expanded={expanded}
            filtered={filtered}
            groupBy="statusGroupId"
            groupByClassName={(statusGroupId: number) =>
              DataRequestStatusGroup[statusGroupId]
            }
            loading={loading}
            onFilterChange={this.handleFilter}
            onPageChange={this.handlePage}
            onSortChange={this.handleSort}
            onToggleExpansion={this.handleToggleExpansion}
            page={page}
            pageSize={pageSize}
            renderEmpty={() => (
              <TableEmpty
                filterActive={isTableFilterActive(this.columns, filtered)}
                searchQuery={searchQuery}
              >
                <Empty icon="file">
                  {getTextToDisplay('dataRequest.empty', {
                    context: permissionService.isRyan() ? 'ryan' : 'client'
                  })}
                </Empty>
              </TableEmpty>
            )}
            renderExpandedRow={this.renderExpandedRow}
            renderGroupHeader={(statusGroupId: number) =>
              getTextToDisplay(`dataRequest.status.${statusGroupId}`)
            }
            rowClassName={this.getRowClassName}
            rowId="queueItemGuid"
            sorted={sorted}
            totalCount={totalCount}
          />
        )}

        {layout === Layout.Card &&
          (loading ? (
            <DataRequestCardSkeleton />
          ) : dataRequests.length === 0 ? (
            <Empty icon="file">
              {getTextToDisplay('dataRequest.empty', {
                context: permissionService.isRyan() ? 'ryan' : 'client'
              })}
            </Empty>
          ) : (
            <>
              {dataRequests.map(
                (dataRequest: Omit<IDataRequestV2, 'dateRange'>) => (
                  <DataRequestCard
                    dataRequest={dataRequest}
                    key={dataRequest.queueItemGuid}
                    onOpenComments={this.handleDrawerOpenComments(dataRequest)}
                    onOpenHistory={this.handleDrawerOpenHistory(dataRequest)}
                    onUpdate={this.handleRefresh}
                  />
                )
              )}
              <Pagination
                onPageChange={this.handlePage}
                page={page}
                pageSize={pageSize}
                totalCount={totalCount}
              />
            </>
          ))}

        {this.state.openDataRequestModal && canEdit && (
          <DataRequestModal
            engagementGuid={engagement ? engagement.engagementGuid : undefined}
            onClose={this.handleDataRequestModalClose}
          />
        )}

        {this.state.dataRequestToUpdateFollowers && (
          <FollowersModal
            engagementGuid={
              this.state.dataRequestToUpdateFollowers.engagementGuid
            }
            instructions={getTextToDisplay('followersModal.instructions', {
              context: 'DataRequest'
            })}
            onClose={() => {
              this.setState({ dataRequestToUpdateFollowers: null });
            }}
            onUpdate={() => {
              this.setState({ dataRequestToUpdateFollowers: null });
              this.handleRefresh();
            }}
            open
            queueItemGuid={
              this.state.dataRequestToUpdateFollowers.queueItemGuid
            }
            title={getTextToDisplay('followersModal.title', {
              context: 'DataRequest'
            })}
            userTypeId={this.props.user.profile.userTypeId}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withUser(withDataRequestDrawer(DataRequests)))
);
