import { ISystemMessage } from 'interfaces';
import ApiService from 'services/ApiService';

import React, { FunctionComponent, useEffect, useState } from 'react';

import { Banner } from '@ryan/components';

const SystemBanners: FunctionComponent = () => {
  const [messages, setMessages] = useState<ISystemMessage[]>([]);

  useEffect(() => {
    ApiService.getSystemMessages().then(response => {
      setMessages(response.data);
    });
  }, []);

  function onDismiss(messageGuid: string) {
    // Optimistic dismiss!
    setMessages(messages =>
      messages.filter(message => message.systemMessageGuid !== messageGuid)
    );
    ApiService.dismissSystemMessage(messageGuid);
  }

  return (
    <>
      {messages.map(systemMessage => (
        <Banner
          dismissible={systemMessage.cancelable}
          key={systemMessage.systemMessageGuid}
          onClose={() => onDismiss(systemMessage.systemMessageGuid)}
        >
          <div
            dangerouslySetInnerHTML={{ __html: systemMessage.systemMessage }}
          />
        </Banner>
      ))}
    </>
  );
};

export default SystemBanners;
